export const routes = {
  HOME: "/",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/user/forgot-password",
  DASHBOARD: "/user/dashboard",
  TRACKING: "/user/tracking",
  DOCUMENT: "/user/document",
  CONSULTANT: "/user/consultant",
  PAYMENT: "/user/payments",
  USER_PROFILE: "/user/userprofile",
  CHAT: "/user/chat",
  HELP: "/user/help",
  NOTIFICATION:"/user/notification",
  FEEDBACKFORM: "/user/feedback",
  ELIGIBILITY: "/user/eligibility",
  THANKYOU: "/thank-you"
  
};
