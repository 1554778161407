import { createAsyncThunk } from "@reduxjs/toolkit";
import apiConfig from "../../config/api-config";
import { getUser } from "./users-actions";

export const login = createAsyncThunk("auth/login", async (userData) => {
  await apiConfig
    .post(`Authentication/Login`, userData)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((response) => {
      console.log("error", response);
    });
});

export const createUserWithoutLogin = createAsyncThunk(
  "auth/createUser",
  async ({ userData, dispatch }) => {
    try {
      const response = await apiConfig.post("Users/Create", userData);
      if (response?.data?.succeeded) {
        // onHide(); // Close the modal if the response indicates success
        dispatch(getUser(response?.data?.data));
        return response?.data;
      }
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error to let the caller handle it
    }
  }
);
