import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsultantDocumentLists,
  getConsultantLists,
} from "../../customer/redux/action/consultant-action";
import Consultancyimage from "../../Images/consultant1.webp";
import { Link } from "react-router-dom";
import VideoCall from "../../../src/video-call-svgrepo-com.svg";
import { getconsultantConnected } from "../../customer/redux/action/consultantConnected";

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <svg
          key={i}
          className="w-6 h-6 fill-current text-yellow-500"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 1L12.245 6.38H18.619L13.827 9.62L16.072 15L10 11.875L3.928 15L6.173 9.62L1.381 6.38H7.755L10 1Z" />
        </svg>
      );
    } else {
      stars.push(
        <svg
          key={i}
          className="w-6 h-6 fill-current text-gray-400"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 1L12.245 6.38H18.619L13.827 9.62L16.072 15L10 11.875L3.928 15L6.173 9.62L1.381 6.38H7.755L10 1Z" />
        </svg>
      );
    }
  }
  return stars;
};

function Consultant() {
  const circumference = ((2 * 22) / 7) * 90;
  const dispatch = useDispatch();
  const { consultants } = useSelector((state) => state?.consultants || {});
  console.log('consultants', consultants)
  const [searchQuery, setSearchQuery] = useState("");
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [sortBy, setSortBy] = useState("lowToHigh");
  const [currentPage, setCurrentPage] = useState(1);
  const consultantsPerPage = 6;
  const userData = useSelector((state) => state.userData.authData.data);
  const userId = userData?.userId;
  console.log('userData', userData)

  useEffect(() => {
    dispatch(getConsultantLists());
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (type) => {
    setSortBy(type);
    setIsSortOpen(false);
  };
  const toggleSortDropdown = () => {
    setIsSortOpen(!isSortOpen);
  };

  const indexOfLastConsultant = currentPage * consultantsPerPage;
  const indexOfFirstConsultant = indexOfLastConsultant - consultantsPerPage;

  const sortedAndFilteredConsultants = consultants
    .filter((consultant) =>
      consultant.consultantName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((consultant) => consultant.deleted !== true)
    .sort((a, b) => {
      if (sortBy === "lowToHigh") {
        return a.rating - b.rating;
      } else {
        return b.rating - a.rating;
      }
    });

  const currentConsultants = sortedAndFilteredConsultants.slice(
    indexOfFirstConsultant,
    indexOfLastConsultant
  );
  // console.log('jjghg', sortedAndFilteredConsultants)
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleConfirm = (consultantId) => {
    dispatch(getconsultantConnected({ userId, consultantId }))
      .unwrap()
      .then((response) => {
        console.log('Consultant connected successfully:', response);
        // You can add additional logic here, e.g., showing a success message
      })
      .catch((error) => {
        console.error('Failed to connect consultant:', error);
        // You can add error handling logic here, e.g., showing an error message
      });
  };
  return (
    <section>
      <div className="consultancy-section pt-16 pb-16">
        <div className="container">
          <div className="text-center">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-5xl mb-5">
              Consultant
            </h2>
          </div>
          <div className="row">
            <div className="col-md-8">
              {/* <div className="dropdown">
                <button
                  className="btn btn-secondary mr-4"
                  type="button"
                  id="sortDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ minHeight: "50px" }}
                >
                  Sort By
                </button>
                <button
                  className="btn btn-secondary mr-4"
                  onClick={() => handleSort("lowToHigh")}
                >
                  Lowest Rating
                </button>
                <button
                  className="btn btn-secondary "
                  onClick={() => handleSort("highToLow")}
                >
                  Highest
                </button>
              </div> */}
              <div className="relative">
                <button
                  onClick={toggleSortDropdown}
                  className="btn btn-secondary"
                >
                  Sort By <i className="fas fa-chevron-down ml-2"></i>
                </button>
                 {isSortOpen && (
                  <div className="absolute top-10 left-0 z-10 w-40 bg-white shadow-md rounded-lg mt-2">
                    <ul>
                      <li>
                        <button
                          onClick={() => handleSort("lowToHigh")}
                          className="block text-left px-4 py-2 text-gray-800 hover:bg-gray-200 w-full"
                        >
                          Lowest Rating
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleSort("highToLow")}
                          className="block text-left px-4 py-2 text-gray-800 hover:bg-gray-200 w-full"
                        >
                          Highest Rating
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="search-container mb-4">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="form-control form-controlaa"
                  style={{ minHeight: 50, background: "#2f3349" }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            {currentConsultants?.map((consultancy, index) => {
              // console.log('qqqqq', consultancy)
              return (
                <div className="col-lg-4 col-md-6 col-12" key={index}>
                  <div class="w-full con-box rounded-lg shadow px-0 mb-4">
                    <div class="flex flex-col items-center pt-6 px-3">
                      <img
                        class="w-24 h-24 mb-3 rounded-full shadow-lg"
                        src={Consultancyimage}
                        alt={consultancy?.image}
                      />
                      <h5 class="mb-1 text-2xl font-medium text-white">
                        {consultancy?.consultantName}
                      </h5>
                      <span class="text-md text-white font-medium mb-2">
                        {consultancy?.designation}
                      </span>
                      <span class="text-sm text-white">
                        {consultancy?.location}
                      </span>
                      <div className="flex items-center mt-2">
                        <span className="text-gray-100">Rating:</span>
                        <span className="ml-1" style={{ display: "flex" }}>
                          {renderStars(consultancy?.rating)}
                        </span>
                      </div>

                      <button
                        type="button"
                        onClick={() => handleConfirm(consultancy.id)}
                        class=" mt-4 text-blue-600 border-1 text-white bg-blue-500 border-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none font-medium rounded-lg px-4 py-2 inline-flex justify-center align-items-center text-center"
                      >
                        <svg
                          class="flex-shrink-0 w-4 h-4 me-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Confirm
                      </button>

                      <div class="flex space-x-3 mt-4">
                        <Link
                          to="/user/chat"
                          type="button"
                          class="p-3 text-white border-1 bg-blue-700 hover:border-blue-700 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                        >
                          <svg
                            class="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 18"
                          >
                            <path d="M18 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h3.546l3.2 3.659a1 1 0 0 0 1.506 0L13.454 14H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-8 10H5a1 1 0 0 1 0-2h5a1 1 0 1 1 0 2Zm5-4H5a1 1 0 0 1 0-2h10a1 1 0 1 1 0 2Z" />
                          </svg>
                        </Link>
                        <button
                          type="button"
                          class="p-3 text-white border-1 bg-green-700 hover:border-green-700 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                        >
                          <svg
                            class="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 32 32"
                            id="Phone"
                          >
                            <path
                              d="M13.216 8.064c-.382-4.394-4.966-6.55-5.16-6.638a.974.974 0 0 0-.582-.078c-5.292.878-6.088 3.958-6.12 4.086a.99.99 0 0 0 .02.54c6.312 19.584 19.43 23.214 23.742 24.408.332.092.606.166.814.234a.99.99 0 0 0 .722-.042c.132-.06 3.248-1.528 4.01-6.316a.997.997 0 0 0-.096-.612c-.068-.132-1.698-3.234-6.218-4.33a.977.977 0 0 0-.884.21c-1.426 1.218-3.396 2.516-4.246 2.65-5.698-2.786-8.88-8.132-9-9.146-.07-.57 1.236-2.572 2.738-4.2a.998.998 0 0 0 .26-.766z"
                              class="color000000 svgShape"
                            ></path>
                          </svg>
                        </button>
                        <button
                          type="button"
                          class="p-3 text-white border-1 bg-gray-700 hover:border-gray-700 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                        >
                          <svg
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                            />
                          </svg>
                        </button>
                        <Link
                          to="http://localhost:3030/"
                          target="_blank"
                          class="p-3 text-white border-1 bg-blue-400 hover:border-blue-500 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                        >
                          <img className="img-fluid w-6 h-6" src={VideoCall} />
                        </Link>
                      </div>
                      <dl class="grid max-w-screen-xl grid-cols-2 gap-1 px-4 mx-auto text-white sm:px-8 py-4">
                        <div class="flex flex-col items-center justify-center text-center">
                          <dt class="mb-2 text-3xl font-extrabold">
                            {consultancy?.connectedPeople}
                          </dt>
                          <dd class="text-white dark:text-gray-400">
                            Connected People
                          </dd>
                        </div>
                        <div class="flex flex-col items-center justify-center text-center">
                          <dt class="mb-2 text-3xl font-extrabold">
                            {consultancy?.completedFile}
                          </dt>
                          <dd class="text-white dark:text-gray-400">
                            Completed File
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* {consultantDocuments?.map((data, index) => (
              <dl className="grid max-w-screen-xl grid-cols-2 gap-1 px-4 mx-auto text-gray-900 sm:px-8 py-4" key={index}>
                <div className="flex flex-col items-center justify-center text-center">
                  <dt className="mb-2 text-3xl font-extrabold">
                    {data?.numberOfEmployees}
                  </dt>
                  <dd className="text-gray-500 dark:text-gray-400">no. of employees</dd>
                </div>

                <div className="flex flex-col items-center justify-center text-center">
                  <dt className="mb-2 text-3xl font-extrabold">
                    {data?.officeAddress}
                  </dt>
                  <dd className="text-gray-500 dark:text-gray-400">office address</dd>
                </div>

                <div className="flex flex-col items-center justify-center text-center">
                  <dt className="mb-2 text-3xl font-extrabold">
                    {data?.annualToneOver}
                  </dt>
                  <dd className="text-gray-500 dark:text-gray-400">annual turnover</dd>
                </div>
              </dl>
            ))} */}
          </div>

          <div className="flex justify-center mt-5">
            {sortedAndFilteredConsultants.length > consultantsPerPage && (
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    className="page-link"
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                {Array(
                  Math.ceil(
                    sortedAndFilteredConsultants.length / consultantsPerPage
                  )
                )
                  .fill()
                  .map((_, i) => (
                    <li
                      key={i}
                      className={`page-item ${currentPage === i + 1 ? "active" : ""
                        }`}
                    >
                      <button
                        onClick={() => paginate(i + 1)}
                        className="page-link"
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                <li
                  className={`page-item ${currentPage ===
                      Math.ceil(
                        sortedAndFilteredConsultants.length / consultantsPerPage
                      )
                      ? "disabled"
                      : ""
                    }`}
                >
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    className="page-link"
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Consultant;
