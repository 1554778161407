export const countryProgressLists = [
  {
    country: "US",
    progress: 50,
  },
  {
    country: "Canada",
    progress: 80,
  },
  {
    country: "Aus",
    progress: 30,
  },
  {
    country: "UK",
    progress: 20,
  },
];
export const roleOption = [
  {
    label: "User",
    value: "1",
  },
  {
    label: "Consultant/Lawyer/Agent",
    value: "2",
  },
];
export const consultancyLists = [
  {
    image: "srcImagesconsultannt.png",
    name: "Bonnie Green",
    designation: "Visual Designer",
    location: "South Dawn, UK",
    connectedPeople: 72,
    completedFile: 12,
    rating: 3,
  },
  {
    image: "https://flowbite.com/docs/images/people/profile-picture-2.jpg",
    name: "Maria Gabriela",
    designation: "Software Developer",
    location: "South Dawn, US",
    connectedPeople: 154,
    completedFile: 10,
    rating: 2,
  },
  {
    image: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
    name: "Naresh Kumar",
    designation: "Website Design",
    location: "Chandigarh, India",
    connectedPeople: 1000,
    completedFile: 50,
    rating: 5,
  },
  {
    image: "https://flowbite.com/docs/images/people/profile-picture-4.jpg",
    name: "Jasmine Davies",
    designation: "Graphic Designer",
    location: "Bristol, United Kingdom",
    connectedPeople: 652,
    completedFile: 32,
    rating: 4,
  },
  {
    image: "https://flowbite.com/docs/images/people/profile-picture-5.jpg",
    name: "Akshay Bhalodiya",
    designation: "Software Develope",
    location: "Ahmdabad",
    connectedPeople: 652,
    completedFile: 32,
    rating: 2,
  },
];
