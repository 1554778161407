// For user App
import { Routes } from "react-router-dom";
import UserApp from "../src/user";
import CustomerApp from "../src/customer";
import { Route } from "react-router";
import React from "react";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          exact
          name="Consultant Home"
          element={<CustomerApp />}
        />
        {/* <Route path="/user/*" name="User Home" element={<UserApp />} /> */}
        {/* <Route path="/" element={<Navigate to="/user/dashboard" />}></Route> */}
      </Routes>
    </>
  );
};

export default App;
