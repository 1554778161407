import { createAsyncThunk } from '@reduxjs/toolkit'
import apiConfig from '../../config/api-config';

export const createInquiry = createAsyncThunk("createInquiry", async (userData) => {
    try {
        const response = await apiConfig.post("Inquiry/Create", userData);
        if (response?.data?.succeeded) {
            return response?.data?.succeeded;
        }
    } catch (error) {
        console.error('error', error);
        throw error; 
    }
});


