import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBell } from '@fortawesome/free-solid-svg-icons';

const NotificationList = ({ socket }) => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    socket.on('notification', (data) => {
      setNotifications((prevNotifications) => [...prevNotifications, data]);
    });
    
   
  
    return () => {
      socket.off('notification');
    };
  }, [socket]);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className='text-white'>
      <h2 onClick={toggleNotifications} style={{cursor: 'pointer'}}>
        {/* <FontAwesomeIcon icon={faBell} />  */}
        <span className="badge">{notifications.length > 0 ? notifications.length : null}</span>
      </h2>
      {showNotifications && (
        <ul>
          {notifications.map((notification, index) => (
            <li key={index}>{notification}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NotificationList;
