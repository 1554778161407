// src/redux/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import assessmentsPersonalSlice from "./slice/assessments-personal-slice";
import userDetailsSlice from "./slice/users-slice";
import inquirySlice from "./slice/inquiry-slice";
import blogsSlice from "./slice/blogs-slice";
import faqSlice from "./slice/faq-slice";
import contactUsSlice from "./slice/contctUs-slice";
import educationSlice from "./slice/educationSlice";
import userSlice from "./slice/userSlice";
import consultantSlice from "./slice/consultantSlice";

import authSlice from "../../user/redux/slice/auth-slice";
import profileSlice from "../../user/redux/slice/profileSlice";
import testimonialsSlice from "./slice/testimonialSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import uploadDocSlice from "./slice/uploadDoc-slice";
import freeAssessmentsSlice from "./slice/freeAssessments-slice";
import personalInfoSlice from "./slice/personalInfoSlice";
import consultantDocumentsReducer from "./slice/consultantDocumentsSlice";
import testimonialSlice from "./slice/testimonialSlice";
import getConnectedUserSlice from "./slice/consultantDocument-slice";
import chartReducer from "./slice/chartSlice";
import consultantDocumentsSlice from "./slice/consultantDocumentsSlice";
import { getconsultantConnected } from "./action/consultantConnected";


const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  assessmentsUser: assessmentsPersonalSlice,
  userDetails: userDetailsSlice,
  inquires: inquirySlice,
  contactUs: contactUsSlice,
  uploadDoc: uploadDocSlice,
  blogs: blogsSlice,
  faqs: faqSlice,
  education: educationSlice,
  users: userSlice,
  personalInfo: personalInfoSlice,
  consultants: consultantSlice,
  freeAssessments: freeAssessmentsSlice,
  userData: authSlice,
  profileData: profileSlice,
  testimonials: testimonialSlice,
  chart: chartReducer,
  connectedUsers: getConnectedUserSlice,
  consultantDocuments: consultantDocumentsSlice,
  consultantConnect: getconsultantConnected,
});
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
