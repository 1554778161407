import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", lang: "English" },
  { code: "hi", lang: "Hindi" },
  { code: "gu", lang: "Gujarati" }
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (event) => {
    const selectedLanguageCode = event.target.value;
    i18n.changeLanguage(selectedLanguageCode);
  };

  return (
    <div className="hidden lg:ml-8 lg:flex pr-3">
      <div className="flex items-center text-gray-700 hover:text-gray-800">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCayyZI3ST2LfQyIA4LeLWjXa8BOHzYDysp1Mn0ncQZA&s"
          alt=""
          className="block h-auto w-5 flex-shrink-0"
        />
        <select
          id="language"
          name="language"
          className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-3 text-gray-500 sm:text-md font-semibold"
          onChange={changeLanguage}
          value={i18n.language}
        >
          {languages.map((lng) => (
            <option key={lng.code} value={lng.code}>
              {lng.lang}
            </option>
          ))}
        </select>
        {/* <span className="sr-only">, change language</span> */}
      </div>
    </div>
  );
};

export default LanguageSelector;