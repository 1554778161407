import { Fragment, useState, useEffect } from "react";
import {
  Dialog,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../../Images/logo-user.png";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const userNavigation = [
  { name: "Your Profile", href: "/user/userprofile" },
  { name: "Settings", href: "#" },
  { name: "Notifications", href: "/user/notification" },
  { name: "Sign out", href: "/", id: "signOut" },


];
const callsToAction = [
  {
    name: "Watch Video",
    href: "https://www.youtube.com/channel/UChCGye2p-SycvAa4kQ4a5Gw",
    traget: "_blank",
    icon: PlayCircleIcon,
  },
  { name: "Contact Admin", href: "#", icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem("persist:root");
    localStorage.clear();
    navigate("/");
  };

  return (
    <div
      className={`sticky ${scrollDirection === "down" ? "-top-24" : "top-0"
        }  container-fluid p-0 header-class`}
    >
      <header className="" style={{ position: "relative", background: '#2f3349' }}>
        <nav
          className="mx-auto flex max-w-9xl items-center  border-bottom-100 justify-between p-6 lg:px-10"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={Logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Popover.Group className="hidden lg:flex lg:gap-x-12">
              <Link
                to={"/user/dashboard"}
                className="text-lg font-semibold align-self-center text-white active"
              >
                Dashboard
              </Link>{" "}
              <Link
                to={"/user/document"}
                className="text-lg font-semibold align-self-center text-white active"
              >
                Documents
              </Link>{" "}
              <Link
                to={"/user/consultant"}
                className="text-lg font-semibold align-self-center text-white active"
              >
                Consultant
              </Link>{" "}
              <Link
                to={"/user/eligibility"}
                className="text-lg font-semibold align-self-center text-white active"
              >
                Eligibility
              </Link>{" "}
              <Link
                to="/user/tracking"
                className="text-lg font-semibold  align-self-center text-white"
              >
                Tracking
              </Link>
              <Link
                to="/user/chat"
                className="text-lg font-semibold  align-self-center text-white"
              >
                Chat
              </Link>
              <Link
                to="/user/payments"
                className="text-lg font-semibold  align-self-center text-white"
              >
                Payments
              </Link>
              <Link
                to="/user/help"
                className="text-lg font-semibold  align-self-center text-white"
              >
                Help
              </Link>
              {/* <Link to="/user/notification" className="text-lg font-semibold align-self-center text-white">
                <FaBell className="mr-2" /> 
              </Link> */}
              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" style={{ minWidth: 45, minHeight: 45 }}>
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>

                    <img
                      className="h-12 w-12 rounded-full"

                      src={user.imageUrl}
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item
                        key={item.name}
                      // onClick={item?.name === "Sign out" && onLogout()}
                      >
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </Popover.Group>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden asdasd"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          style={{ position: "relative", background: '#2f3349',zIndex:'1111' }}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10" style={{background: '#2f3349'}}>
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={Logo} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                 

                <Link
                to={"/user/dashboard"}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Dashboard
              </Link>{" "}
              <Link
                to={"/user/document"}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Documents
              </Link>{" "}
              <Link
                to={"/user/consultant"}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Consultant
              </Link>{" "}
              <Link
                to={"/user/eligibility"}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Eligibility
              </Link>{" "}
              <Link
                to="/user/tracking"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Tracking
              </Link>
              <Link
                to="/user/chat"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Chat
              </Link>
              <Link
                to="/user/payments"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Payments
              </Link>
              <Link
                to="/user/help"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
              >
                Help
              </Link>


                </div>
          
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
