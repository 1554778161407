import React, { useRef, useState } from "react";
import { PhotoIcon } from "@heroicons/react/20/solid";
import { assessmentsUploadCSV } from "../../redux/action/assessments-personal-actions";
import { useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

function UploadCV({ handleNext, userData }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const fileInput = useRef(null);
    const dispatch = useDispatch();

    const handleFile = (file) => {
        if (file) {
            // Check if the file size is within the limit (10 MB in this case)
            if (file.size <= 10 * 1024 * 1024) {
                setFile(file);
                setFileName(file.name);
            } else {
                alert('File size exceeds the limit of 10 MB. Please choose a smaller file.');
            }
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleFileInputChange = (e) => {
        handleFile(e.target.files[0]);
    };

    const handleUploadCSV = async (event) => {
        setIsSubmitting(true);
        event.preventDefault();
        if (!file) {
            window.alert("Please select a file");
            return false;
        } else {
            await dispatch(assessmentsUploadCSV({
                userData: {
                    UserId: userData?.id,
                    FileDetails: file,
                    DocumentType: "CSV",
                    FileType: "PDF",
                    Name: fileName
                },
                handleNext
            }
            ));
            setIsSubmitting(false)
        }
    }

    return (
        <div className="row">
            <div className="col-12 mb-4">
                <div className="wrapper">
                    <div
                        className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 drop_zone"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onClick={() => fileInput.current.click()}
                    >
                        <div className="text-center">
                            <PhotoIcon
                                className="mx-auto h-12 w-12 text-gray-300"
                                aria-hidden="true"
                            />
                            <p>Click to select or Drag and drop file here....</p>
                            <input
                                type="file"
                                ref={fileInput}
                                hidden
                                onChange={handleFileInputChange}
                            />
                        </div>

                    </div>
                    {fileName && <p>File Name: {fileName}</p>}
                </div>
            </div>
            <div className="col-12 text-end mb-2">
                <button
                    className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
                    type="submit"
                    onClick={(event) => handleUploadCSV(event)}
                    disabled={isSubmitting}
                >
                   {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Upload"}
                </button>
            </div>
        </div>

    );
}

export default UploadCV;
