import { useState, useEffect } from "react";
import { consultancyLists, countryProgressLists } from "../config/array-config";
import {
  getConsultantDocumentLists,
  getConsultantLists,
} from "../../customer/redux/action/consultant-action";
import { useDispatch, useSelector } from "react-redux";
import Consultancyimage from "../../Images/consultant1.webp";
import U1 from "../../Images/Strategic consulting-pana.svg";
import { Link } from "react-router-dom";
import VideoCall from "../../../src/video-call-svgrepo-com.svg";
import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
import Chart3 from "./Chart3";
import { Testimonial } from "../../customer/compomemts/Testimonial";

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <svg
          key={i}
          className="w-6 h-6 fill-current text-yellow-500"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 1L12.245 6.38H18.619L13.827 9.62L16.072 15L10 11.875L3.928 15L6.173 9.62L1.381 6.38H7.755L10 1Z" />
        </svg>
      );
    } else {
      stars.push(
        <svg
          key={i}
          className="w-6 h-6 fill-current text-gray-400"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 1L12.245 6.38H18.619L13.827 9.62L16.072 15L10 11.875L3.928 15L6.173 9.62L1.381 6.38H7.755L10 1Z" />
        </svg>
      );
    }
  }
  return stars;
};

const Dashboard = () => {
  const circumference = ((2 * 22) / 7) * 90;
  const dispatch = useDispatch();
  const { consultants } = useSelector((state) => state?.consultants || {});
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("lowToHigh");
  const [currentPage, setCurrentPage] = useState(1);
  const consultantsPerPage = 6;

  useEffect(() => {
    dispatch(getConsultantLists());
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (type) => {
    setSortBy(type);
  };

  const indexOfLastConsultant = currentPage * consultantsPerPage;
  const indexOfFirstConsultant = indexOfLastConsultant - consultantsPerPage;

  const sortedAndFilteredConsultants = consultants
    .filter((consultant) =>
      consultant.consultantName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "lowToHigh") {
        return a.rating - b.rating;
      } else {
        return b.rating - a.rating;
      }
    });

  const currentConsultants = sortedAndFilteredConsultants.slice(
    indexOfFirstConsultant,
    indexOfLastConsultant
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <section className="pt-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 mb-4 col-lg-5 col-12">
              <div className="card bg-welcome">
                <div className="d-flex align-items-end row ">
                  <div className="col-7">
                    <div className="card-body text-nowrap">
                      <h5 className="card-title mb-0">
                        Welcome to <br></br>
                        <span className="vv">Videsh Apna 🎉</span>
                      </h5>
                      <p className="mb-2 mt-1">
                        Begin your journey right away.
                      </p>
                      <Link
                        to="/user/document"
                        className="btn btn-primary waves-effect waves-light mt-2"
                      >
                        Fill Documents
                      </Link>
                    </div>
                  </div>
                  <div className="col-5 text-center text-sm-left">
                    <div className="card-body pb-0 px-0 px-md-4">
                      <img
                        src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/card-advance-sale.png"
                        height="140"
                        alt="view sales"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8 mb-4 col-lg-7 col-12 ab">
              <div className="card h-100">
                <div className="card-header">
                  <div className="d-flex justify-content-between mb-0">
                    <h5 className="card-title mb-0 text-lg">Statistics</h5>
                    <small className="">Updated 1 month ago</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row gy-3">
                    <div className="col-md-3 col-6">
                      <div className="d-flex align-items-center">
                        <div className=" rounded-pill bg-label-primary me-3 p-2">
                          <i class="fa-solid fa-chart-pie fa-sm"></i>
                        </div>
                        <div className="card-info">
                          <h5 className="mb-0 text-xl">500+</h5>
                          <small>No of Deals</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="d-flex align-items-center">
                        <div className=" rounded-pill bg-label-info me-3 p-2">
                          <i class="fa-solid fa-user fa-sm"></i>
                        </div>
                        <div className="card-info">
                          <h5 className="mb-0 text-xl">8.9k</h5>
                          <small>No of Users</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="d-flex align-items-center">
                        <div className=" rounded-pill bg-label-danger me-3 p-2">
                          <i class="fa-solid fa-users-line  fa-sm"></i>
                        </div>
                        <div className="card-info">
                          <h5 className="mb-0 text-xl">1.42k</h5>
                          <small>No of Consultant</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="d-flex align-items-center">
                        <div className=" rounded-pill bg-label-success me-3 p-2">
                          <i class="fa-solid fa-file  fa-sm"></i>
                        </div>
                        <div className="card-info">
                          <h5 className="mb-0 text-xl">500</h5>
                          <small>Complete Files</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4 order-md-3 order-lg-0 ab">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="card-title mb-0">
                    <h5 className="mb-0 text-lg">
                      Completed files by videsh apna
                    </h5>
                    <small className="text-white">Last 1 Years</small>
                  </div>
                </div>
                <div className="card-body row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <Chart2 />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4 order-md-3 order-lg-0 ab">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="card-title mb-0">
                    <h5 className="mb-0 text-lg">Support Tracker</h5>
                    <small className="text-white">Last 7 Days</small>
                  </div>
                </div>
                <div className="card-body row">
                  <div className="col-12 col-sm-4 col-md-12 col-lg-4">
                    <div className="mt-lg-4 mt-lg-2 mb-lg-4 mb-2 pt-1">
                      <h1 className="mb-0">164</h1>
                      <p className="mb-0 text-white">Total Files</p>
                    </div>
                    <ul className="p-0 m-0">
                      <li className="d-flex gap-3 align-items-center mb-lg-3 pt-2 pb-1">
                        <div className=" rounded rg-ss bg-label-primary p-1">
                          <i class="fa-solid fa-ticket fa-sm"></i>
                        </div>
                        <div>
                          <h6 className="mb-0 text-nowrap">New Files</h6>
                          <small className="">142</small>
                        </div>
                      </li>
                      <li className="d-flex gap-3 align-items-center mb-lg-3 pb-1">
                        <div className=" rounded rg-ss bg-label-info p-1">
                          <i class="fa-solid fa-clock fa-sm"></i>
                        </div>
                        <div>
                          <h6 className="mb-0 text-nowrap">Open Files</h6>
                          <small className="">28</small>
                        </div>
                      </li>
                      <li className="d-flex gap-3 align-items-center pb-1">
                        <div className=" rounded rg-ss bg-label-warning p-1 opacity-70">
                          <i class="fa-solid fa-clock fa-sm"></i>
                        </div>
                        <div>
                          <h6 className="mb-0 text-nowrap">Response Time</h6>
                          <small className="">1 Day</small>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-sm-8 col-md-12 col-lg-8">
                    <Chart1 />
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-8 col-lg-7 col-md-7 ab">
            <div className="card card-action mb-4">
      <div className="card-header align-items-center">
        <h5 className="card-action-title mb-0 text-lg">Activity Timeline</h5>
      
      </div>
      <div className="card-body pb-0">
        <ul className="timeline ms-1 mb-0">
          <li className="timeline-item timeline-item-transparent">
            <span className="timeline-point timeline-point-primary"></span>
            <div className="timeline-event">
              <div className="timeline-header">
                <h6 className="mb-0">Consultant Meeting</h6>
                <small className="text-muted">Today</small>
              </div>
              <p className="mb-2">Project meeting with john @10:15am</p>
              <div className="d-flex flex-wrap">
                
                <div className="">
                  <h6 className="mb-0">Lester McCarthy (consultant)</h6>
                </div>
              </div>
            </div>
          </li>
          <li className="timeline-item timeline-item-transparent">
            <span className="timeline-point timeline-point-success"></span>
            <div className="timeline-event">
              <div className="timeline-header">
                <h6 className="mb-0">Create a new requste for consultant</h6>
                <small className="text-muted">2 Day Ago</small>
              </div>
              <p className="mb-0">Add files</p>
            </div>
          </li>
          <li className="timeline-item timeline-item-transparent">
            <span className="timeline-point timeline-point-danger"></span>
            <div className="timeline-event">
              <div className="timeline-header">
                <h6 className="mb-0">Documents Upload</h6>
                <small className="text-muted">6 Day Ago</small>
              </div>
              <p className="mb-2">Sent by Mollie Dixon </p>
              <div className="d-flex flex-wrap gap-2 pt-1">
               
                <a href="javascript:void(0)" style={{ borderBottom: '1px solid #fff'}}>
                  <span className="fw-medium text-heading">View Results</span>
                </a>
              </div>
            </div>
          </li>
          <li className="timeline-item timeline-item-transparent border-transparent">
            <span className="timeline-point timeline-point-info"></span>
            <div className="timeline-event">
              <div className="timeline-header">
                <h6 className="mb-0">Sign in</h6>
                <small className="text-muted">10 Day Ago</small>
              </div>
              <p className="mb-0">Welcom to Videsh Apna</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
            </div>


            <div className="col-xl-4 col-lg-5 col-md-5 ab">
            <div className="card card-action mb-4">
      <div className="card-header align-items-center">
        <h5 className="card-action-title mb-0 text-lg">Check Eligibility
</h5>
      
      </div>
      <div className="card-body">
        <Chart3 />
      </div>
    </div>
            </div>


          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Testimonial />
            </div>
            </div>
            </div>
            </section>
      {/* <section className="pt-5">
        <div className="container">
          <div className="row">
            <div class="col-lg-3 mb-3 position-relative">
              <div class="t-box light-blue b11">
                <h3 class="t-h3 mb-3">No of Deals</h3>
                <p className="c-count">
                  50
                </p>
              </div>
            </div>
            <div class="col-lg-3 mb-3 position-relative">
              <div class="t-box light-pink b12">
                <h3 class="t-h3 mb-3">No of Users</h3>
                <p className="c-count">
                  1000
                </p>
              </div>
            </div>
            <div class="col-lg-3 mb-3 position-relative">
              <div class="t-box light-green b13">
                <h3 class="t-h3 mb-3">No of Consultant</h3>
                <p className="c-count">
                  25
                </p>
              </div>
            </div>
            <div class="col-lg-3 mb-3 position-relative">
              <div class="t-box w-100  light-gray b14">
                <h3 class="t-h3 mb-3">Complete Files</h3>
                <p className="c-count">
                  25
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="pt-4 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="user-d-box">
                <div className="row">
                  <div className="col-lg-7 align-self-center">
                    <div className="mb-3">
                      <span className="user-tag">Lorem Ipsum</span>
                    </div>
                    <h1 className="text-xl sm:text-3xl text-white font-bold font-p">
                      Lorem Ipsum is simply
                    </h1>
                    <p className="text-white mt-2 text-lg">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <button className="btn btn-lg btns-white mt-3">
                      Connect to exports
                    </button>
                  </div>
                  <div className="col-lg-5 text-center">
                    <img className="img-fluid" alt="" src={U1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="bg-gray-100 pt-5 pb-5">
        <div className="container">
          <div className="text-left">
            <h2 class="text-2xl font-bold tracking-tight text-slate-600 sm:text-5xl ">
              Check Your Eligibility
            </h2>
          </div>
          <div className="row pt-20">
            {countryProgressLists?.map((countryProgress, index) => {
              return (
                <div className="col-xxl-3 col-md-6 col-12" key={index}>
                  <div
                    class="flex items-center justify-center mb-3"
                    x-data={circumference}
                  >
                    <svg class="transform -rotate-90 w-52 h-52">
                      <circle
                        cx="100"
                        cy="100"
                        r="90"
                        stroke="currentColor"
                        stroke-width="20"
                        fill="transparent"
                        class="text-light-100"
                      />
                      <circle
                        cx="100"
                        cy="100"
                        r="90"
                        stroke="currentColor"
                        stroke-width="20"
                        fill="transparent"
                        stroke-dasharray={circumference}
                        stroke-dashoffset={
                          circumference -
                          (countryProgress?.progress / 100) * circumference
                        }
                        class="text-sky-500"
                      />
                    </svg>
                    <span class="absolute text-2xl">
                      {countryProgress?.country}
                    </span>
                  </div>
                  <h4 className="text-3xl font-bold leading-9 tracking-tight text-center mb-1 mb-3">{`${countryProgress?.progress}%`}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </section> */}

    
    </>
  );
};

export default Dashboard;