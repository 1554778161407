
export default {
    setupInterceptors: (axios, isToken, isFormData) => {
        axios.interceptors.request.use(config => {
            // if (isToken) {
            let token = localStorage.getItem('token');
            // console.log('token', token)
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            // }

            if (isFormData) {
                // Check if it's a FormData request
                config.headers['Content-Type'] = 'multipart/form-data';
            }

            return config;
        },
            error => {
                return Promise.reject(error);
            });

        axios.interceptors.response.use(
            response => successHandler(response),
            error => errorHandler(error)
        );

        const errorHandler = (error) => {       
            return Promise.reject({ ...error });
        };

        const successHandler = (response) => {
            return response;
        };
    }
};
