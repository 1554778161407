import React, { useState } from 'react'
import Select from '../../../shared/components/form/Select'
import { countryCurrency, filterCountryCurrency } from '../../../shared/utils/shared-method'
import { amountLists } from '../../../shared/constants/arrayConfig'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import InputText from '../../../shared/components/form/InputText'
import { assessmentsPersonalUser } from '../../redux/action/assessments-personal-actions'
import { Country } from 'country-state-city'
import { ThreeDots } from 'react-loader-spinner'

function PersonalNetWorth({ handleNext, country, visaType, userData }) {
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const handleNetWorth = async (formData) => {
    setIsSubmitting(true);  
    await dispatch(assessmentsPersonalUser({
      userData: {
        ...formData,
        userId: userData?.id
      },
      handleNext, visaType
    }));
    setIsSubmitting(false)
  }

  return (
    <form>
      <div className="row">
        <div className='col-12 mb-4'>
          <p>
            This refers to the total value of all assets
            of an investor (combined with source or
            common-law partner), minus the total debts
            owed by the investor. Assests primarily include
            real estate, shares of publicly traded
            companies, and case deposits in banks,. Real
            estate must be supported by market appraisals.
            To participate in the program there is no
            requirement to liquidate assets beyond the
            amount of the government investment.
          </p>
        </div>
        <div className="col-12 mb-4">
          <div className="row">
            <div className="col-lg-4 align-self-center">
              <p className="block mb-2 text-lg font-medium text-gray-900">
                Personal Net worth (cash and assets)
              </p>
            </div>
            <div className="col-lg-4">
              <Select
                name="currency"
                label="Currency"
                options={countryCurrency(Country.getAllCountries())}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "currency year is required",
                }}
                required
              />
            </div>
            <div className="col-lg-4">
              <Select
                name="amount"
                label="Amount"
                options={amountLists}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Amount is required",
                }}
                required
              />
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <InputText
            name="breakDownDescription"
            label={`What is the breakdown of your Personal Net Worth in ${filterCountryCurrency({ country, type: "currency" })}$?`}
            errors={errors}
            register={register}
            isTextarea
          />
        </div>
        <div className="col-12 text-end">
          <button
            className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
            type="submit"
            onClick={handleSubmit(handleNetWorth)}
            disabled={isSubmitting} 
          >
            {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Save & Next"}
          </button>
        </div>
      </div>
    </form>
  )
}

export default PersonalNetWorth