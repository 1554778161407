// import { CheckIcon } from "@heroicons/react/20/solid";
// import im from "../../Images/ai1.png"
import { useTranslation } from "react-i18next";
import R1 from "../../Images/r1.gif"
import R2 from "../../Images/r3.gif"
import R3 from "../../Images/r4.gif"

export default function Relocated() {

  const {t} = useTranslation();
  const {heading1,p1,p2,p3,p4} = t("Relocated");

  return (
    <div className="relative">
      {/* <div className="pattern-layer"><div className="pattern-11"></div><div className="pattern-12"></div></div> */}
      <div className="bg-custome-blue">
    <div className="container">
      <div className="row py-24 sm:py-32">
        <div className="col-12">

        <div className=" px-6 lg:px-8">
              <div className="mx-auto max-w-4xl text-center">
                <h2 className="text-3xl font-bold tracking-tight mb-3 text-gray-600 sm:text-6xl">
                {heading1} <span className="text-sky-500">VideshApna?</span>
                </h2>
                <p className="step-sub-text mb-0">{p1}</p>
              </div>
            </div>
            </div>
          {/* <div className="col-12 mx-auto text-center mt-5">
          <img className="img-fluid text-center mx-auto " style={{position:'relative',zIndex:'11'}} src={im} alt=""/>

          </div> */}
          {/* <div className="col-12 mx-auto text-center mt-5">
            <Skills />
          </div> */}
          <div className="col-12 mx-auto text-center mt-5">
            <div className="row">
              <div className="col-lg-4 mb-3">
                <img className="img-fluid" src={R1} alt="" />
                <p className="font-p text-lg">{p2}</p>
              </div>
              <div className="col-lg-4 mb-3">
                <img className="img-fluid" src={R2} alt="" />
                <p className="font-p text-lg">{p3}</p>
              </div>
              <div className="col-lg-4 mb-3">
                <img className="img-fluid" src={R3} alt="" />
                <p className="font-p text-lg">{p4}</p>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
   
    </div>

  );
}
