import { createAsyncThunk } from '@reduxjs/toolkit'
import apiConfig from '../../config/api-config';
import { toast } from 'react-toastify';

export const createFreeAssessments = createAsyncThunk("createFreeAssessments", async (userData) => {
    try {
        const response = await apiConfig.post("UserAssessments/Create", userData);
        if (response?.data?.succeeded) {
            toast.success("Form created successfully");
            return response?.data;
        }
    } catch (error) {
        toast.error("Something went wrong");        
        throw error; // Re-throw the error to let the caller handle it  
    }
});

