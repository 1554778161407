export const optionLists = [
    {
        label: "Option 1",
        value: "option_1"
    },
    {
        label: "Option 2",
        value: "option_2"
    },
    {
        label: "Option 3",
        value: "option_3"
    },
    {
        label: "Option 4",
        value: "option_4"
    }
]

export const radioLists1 = [
    {
        label: "Radio 1",
        value: "radio_1"
    },
    {
        label: "Radio 2",
        value: "radio_2"
    },
    {
        label: "Radio 3",
        value: "radio_3"
    },
    {
        label: "Radio 4",
        value: "radio_4"
    }
]

export const genderLists = [
    {
        label: "Male",
        value: "Male"
    },
    {
        label: "Female",
        value: "Female"
    }
]

export const maritalStatusLists = [
    {
        label: "Single",
        value: "Single"
    },
    {
        label: "Married",
        value: "Married"
    }
]

export const englishLevelLists = [
    {
        label: "High",
        value: "High"
    },
    {
        label: "Moderate",
        value: "Moderate"
    },
    {
        label: "Basic",
        value: "Basic"
    },
    {
        label: "Not at All",
        value: "Not at All"
    },
]

export const radioLists = [
    {
        label: "Yes",
        value: "Yes"
    },
    {
        label: "No",
        value: "No",
    }
]

export const employmentStatusLists = [
    {
        label: "Full Time",
        value: "Full Time"
    },
    {
        label: "Part Time",
        value: "Part Time"
    }
]
export const relationshipLists = [
    {
        label: "Parent",
        value: "Parent"
    },
    {
        label: "Grand parent",
        value: "Grand parent"
    },
    {
        label: "Son/Daughter",
        value: "Son/Daughter"
    },
    {
        label: "Sibling",
        value: "Sibling"
    },
    {
        label: "Aunt/Uncle",
        value: "Aunt/Uncle"
    },
    {
        label: "Niece/Nephew",
        value: "Niece/Nephew"
    },
    {
        label: "Other",
        value: "Other"
    },
]

export const amountLists = [
    {
        label: "Less than 4,999",
        value: "4,999"
    },
    {
        label: "5,000 - 9,999",
        value: "5,000 - 9,999"
    },
    {
        label: "10,000 - 15,000",
        value: "10,000 - 15,000"
    },
    {
        label: "15,000 - 20,000",
        value: "15,000 - 20,000"
    },
    {
        label: "20,000 - 29,999",
        value: "20,000 - 29,999"
    },
    {
        label: "30,000 - 39,999",
        value: "30,000 - 39,999"
    },
    {
        label: "40,000 - 49,999",
        value: "40,000 - 49,999"
    },
    {
        label: "50,000 - 59,999",
        value: "50,000 - 59,999"
    },
    {
        label: "60,000 - 69,999",
        value: "60,000 - 69,999"
    },
    {
        label: "70,000 - 79,999",
        value: "70,000 - 79,999"
    },
    {
        label: "80,000 - 89,999",
        value: "80,000 - 89,999"
    },
    {
        label: "90,000 - 99,999",
        value: "90,000 - 99,999"
    },
    {
        label: "100,000 - 199,999",
        value: "100,000 - 199,999"
    },
    {
        label: "200,000 - 299,999",
        value: "200,000 - 299,999"
    },
    {
        label: "300,000 - 399,999",
        value: "300,000 - 399,999"
    },
    {
        label: "400,000 - 499,999",
        value: "400,000 - 499,999"
    },
    {
        label: "500,000 - 599,999",
        value: "500,000 - 599,999"
    },
    {
        label: "600,000 - 699,999",
        value: "600,000 - 699,999"
    },
    {
        label: "700,000 - 799,999",
        value: "700,000 - 799,999"
    },
    {
        label: "800,000 - 899,999",
        value: "800,000 - 899,999"
    },
    {
        label: "900,000 - 999,999",
        value: "900,000 - 999,999"
    },
    {
        label: "1,000,000 - 1,500,000",
        value: "1,000,000 - 1,500,000"
    },
    {
        label: "2,000,000 - 2,500,000",
        value: "2,000,000 - 2,500,000"
    },
    {
        label: "3,000,000 - 3,500,000",
        value: "3,000,000 - 3,500,000"
    },
    {
        label: "4,000,000 - 4,500,000",
        value: "4,000,000 - 4,500,000"
    },
    {
        label: "More than 5,000,000",
        value: "More than 5,000,000"
    },
]



export const ageGroupOptions = [
    {
      label: "1-10 Years",
      value: "1-10 Years",
    },
    {
        label: "11-20 Years",
        value: "11-20 Years",
      },

    {
      label: "21-30 Years",
      value: "21-30 Years",
    },
    {
      label: "31-40 Years",
      value: "31-40 Years",
    },
    {
      label: "41-50 Years",
      value: "41-50 Years",
    },
    {
      label: "51-60 Years",
      value: "51-60 Years",
    },
    {
      label: "61-70 Years",
      value: "61-70 Years",
    },  
    {
      label: "70+ Years",
      value: "70+ Years",
    },
  ];

export const countryCurrencyLists = [
    {
        label: "UK",
        value: "UK",
        currency: "GBP",
        currencyName: "British Pound"
    },
    {
        label: "Australia",
        value: "Australia",
        currency: "AUD",
        currencyName: "Australian Dollar"
    },
    {
        label: "Canada",
        value: "Canada",
        currency: "CAD",
        currencyName: "Canadian Dollar"
    },
    {
        label: "Usa",
        value: "Usa",
        currency: "USD",
        currencyName: "United States Dollar"
    },
]

export const visaTypes = [
    {
        label: "Skilled Worker",
        value: "Skilled Worker"
    },
    {
        label: "Start-Up Visa",
        value: "Start-Up Visa"
    },
    {
        label: "Business Candidate",
        value: "Business Candidate"
    },
    {
        label: "Study Visa",
        value: "Study Visa"
    },
]

export const noOfFounders = [
    {
        label: 1,
        value: 1
    },
    {
        label: 2,
        value: 2
    },
    {
        label: 3,
        value: 3
    },
    {
        label: 4,
        value: 4
    },
]

export const skilledWorker = ["Personal Information", "Ability in English", "Educational Profile", "Employment History", "Upload CV"]
export const startUpVisa = ["Personal Information", "Business Information", "Upload CV"]
export const businessCandidate = ["Personal Information", "Management Experience", "Educational Profile", "Net Worth"]
export const studyVisa = ["Personal Information", "Ability in English", "Educational Profile", "Employment History", "Other"]