// For customer App
import { Route, Routes } from "react-router-dom";
import CustomerLayout from "./compomemts/layout";
import { consultantRoutes } from "./config/page-routes-config";
import React, { Suspense } from "react";
import Error from "./Pages/Error";
import { userRoutes } from "./config/userRoute";
import Layout from "../user/components/layout/Layout";
import AuthRouteError from "../user/pages/AuthRouteError";
import ForgotPassword from "./Pages/ForgotPassword";
import { commonPageRoute } from "../common/config/pageRoute";
import { useSelector } from "react-redux";

const CustomerApp = () => {
  const userRole = useSelector((state) => state?.userData?.role);
  const routes = userRole == 1 ? userRoutes : consultantRoutes;
  return (
    <React.Fragment>
      <Suspense fallback={<div className="loader-container"><span className="loader"></span></div>}>
        <Routes>
          {routes?.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  userRole == 1 ? (
                    <Layout>
                      <route.component />
                    </Layout>
                  ) : (
                    <CustomerLayout
                      showHeaderAndFooter={route.showHeaderAndFooter}
                    >
                      <route.component />
                    </CustomerLayout>
                  )
                }
              />
            );
          })}
          {commonPageRoute?.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <CustomerLayout
                    showHeaderAndFooter={route.showHeaderAndFooter}
                  >
                    <route.component />
                  </CustomerLayout>
                }
              />
            );
          })}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth-error" element={<AuthRouteError />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};

export default CustomerApp;
