export const commonRoutes = {
    HOME: "/",
    LOGIN: "/login",
    SIGN_UP: "/sign-up",
    FORGOT_PASSWORD: "/forgot-password",
    CONTACT: "/contact",
    BLOG: "/blog",
    BLOG_DETAILS: "/blog/:id",
    SERVICES_OVERVIEW: "/services-overview",
    PLATFORM_OVERVIEW: "/platform-overview",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS: "/terms",
    REFUND: "/refund",
    ANTI_FRAUD_POLICY: "/anti-fraud-policy",
    INFORMATION_HUB: "/information-hub",
    ASSESSMENT: "/assessment",
    FREE_ASSESSMENT: "/free-assessment",
    CHECKELIGIBILITY: "/check-eligibility",
    THANKYOU: "/thank-you"
}