import { createSlice } from "@reduxjs/toolkit";
import apiConfig from "../../config/apiConfig";
import { Slide, toast } from "react-toastify";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    authData: [],
    isLogin: false,
    isError: false,
    role: null,
  },
  reducers: {
    setAuthData: (state, action) => {
      state.authData = action.payload;
    },
    setLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setAuthData, setLogin, setLoading, setRole } = authSlice.actions;
export default authSlice.reducer;

export const loginUser = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await apiConfig.post("Authentication/Login", payload?.data);
    if (res?.data?.data) {
      dispatch(setLoading(false));
      dispatch(setLogin(true));
      await localStorage.setItem("token", res?.data?.data?.token);
      await localStorage.setItem("isLogin", true);
      const userId = res?.data?.data?.userId;
      const rolData = await apiConfig.get(`UserRole/GetByUserId/${userId}`);
      dispatch(setRole(rolData?.data[0]?.role));
      await localStorage.setItem("role", rolData?.data[0]?.role);
      dispatch(setAuthData(res.data));
      payload?.navigate("/user/dashboard");

      toast.success(" LOGIN SUCCESSFULLY!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    } else {
      dispatch(setLoading(false));

      toast.error(" LOGIN Failed!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};
export const createUSer = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await apiConfig.post("Users/Create", payload);

    if (res) {
      const createRolePayload = {
        role: payload?.type,
        userId: res?.data?.data,
      };
      const createRole = await apiConfig.post(
        "UserRole/Create",
        createRolePayload
      );
      console.log("createRole", createRole);
    }
    dispatch(setLoading(false));
  } catch (error) {
    console.log("error", error);
    dispatch(setLoading(false));
  }
};
