const InputText = ({ name, label, register, errors, required, type, validationSchema, disable, isTextarea }) => (
    <div className="form-control-input">
        {label && <label className="block mb-2 text-lg font-medium text-gray-900" htmlFor={name} >
            {label} {required && <span className="text-red-500">*</span>}
        </label>}
        {isTextarea ? <textarea
            className={`form-control custom-form-control2 ${isTextarea && "h-32"}`}
            id={name}
            name={name}
            {...register(name, validationSchema)}
        /> :
            <input
                className={`form-control custom-form-control2`}
                id={name}
                name={name}
                type={type}
                {...register(name, validationSchema)}
                disabled={disable}
            />
        }
        {errors && (errors[name]?.type === "required" || errors[name]?.type === "validate") && (
            <p className="text-red-500 text-sm pt-1">{errors[name]?.message}</p>
        )}
    </div>
);
export default InputText;