import { createSlice } from "@reduxjs/toolkit";
import { getFAQLists } from "../action/faq-actions";

const faqSlice = createSlice({
    name: 'faq',
    initialState: {
        isLoading: false,
        faqs: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFAQLists.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.faqs = payload
        })
    },
});

export default faqSlice.reducer