import React, { useEffect } from "react";
import InputText from "../../../../shared/components/form/InputText";
import Radio from "../../../../shared/components/form/Radio";
import { employmentStatusLists, radioLists } from "../../../../shared/constants/arrayConfig";
import { useFieldArray } from "react-hook-form";

const AddEmploymentHistory = ({ register, errors, control, setValue, watch }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employmentForms",
  });

  useEffect(() => {
    fields.forEach((field, index) => {
      if (watch(`employmentForms[${index}].isPresent`) === "Yes") {
        setValue(`employmentForms[${index}].completionDate`, new Date().toISOString().split('T')[0]);
      }
    });
  }, [fields, setValue, watch]);

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div className="row border border-5px mt-4 p-3" key={field.id}>
            <div className="col-12 mt-1 text-end">
              <button
                type="button"
                className="close md-1 bg-sky-400 float-right text-center font-medium text-white text-uppercase"
                style={{ height: "30px", width: "30px" }}
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => remove(index)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="col-lg-6 mb-3">
              <InputText
                type="text"
                name={`employmentForms[${index}].companyName`}
                label="Name of company or organization"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Company name is required",
                }}
                required
              />
            </div>
            <div className="col-lg-6 mb-3">
              <InputText
                type="text"
                name={`employmentForms[${index}].designation`}
                label="Designation"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Designation is required",
                }}
                required
              />
            </div>
            <div className="col-lg-4 mb-3">
              <InputText
                type="date"
                name={`employmentForms[${index}].startingDate`}
                label="Start Date"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Start Date is required",
                }}
                required
              />
            </div>
            <div className="col-lg-4 mb-3">
              <Radio
                name={`employmentForms[${index}].isPresent`}
                label="Are you currently working?"
                radioOptions={radioLists}
                errors={errors}
                onChangeRadio={(value) => {
                  setValue(`employmentForms[${index}].isPresent`, value);
                  if (value === "Yes") {
                    setValue(`employmentForms[${index}].completionDate`, new Date().toISOString().split('T')[0]);
                  } else {
                    setValue(`employmentForms[${index}].completionDate`, "");
                  }
                }}
                register={register}
                mt={3}
              />
            </div>
            <div className="col-lg-4 mb-3">
              <InputText
                type="date"
                name={`employmentForms[${index}].completionDate`}
                label="End Date"
                errors={errors}
                register={register}
                validationSchema={{
                  required: watch(`employmentForms[${index}].isPresent`) !== "Yes" ? "End Date is required" : false,
                }}
                required={watch(`employmentForms[${index}].isPresent`) !== "Yes"}
                disable={watch(`employmentForms[${index}].isPresent`) === "Yes"}
              />
            </div>
            <div className="col-lg-4 mb-3">
              <InputText
                type="number"
                name={`employmentForms[${index}].hoursPerWeek`}
                label="Number of hours worked per week"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Hours per week is required",
                }}
                required
              />
            </div>
            <div className="col-lg-4 mb-3">
              <Radio
                name={`employmentForms[${index}].employmentStatus`}
                label="Employment status"
                radioOptions={employmentStatusLists}
                errors={errors}
                onChangeRadio={(value) => setValue(`employmentForms[${index}].employmentStatus`, value)}
                register={register}
                validationSchema={{
                  required: "Employment status is required",
                }}
                required
                mt={3}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <InputText
                type="number"
                name={`employmentForms[${index}].pinCode`}
                label="PinCode"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Pin-code is required",
                }}
                required
              />
            </div>
            <div className="col-12 mb-4">
              <InputText
                type="text"
                name={`employmentForms[${index}].address`}
                label="Address"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Address is required",
                }}
                required
              />
            </div>
            <div className="col-lg-12 mb-4">
              <InputText
                name={`employmentForms[${index}].employmentDuties`}
                label="Employment duties"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Employment duties is required",
                }}
                required
                isTextarea
              />
            </div>
          </div>
        );
      })}
      <div className="col-12 mt-4 text-center">
        <button
          className="rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600 text-uppercase"
          onClick={(event) => append(event.preventDefault())}
          btn
          btn-primary
        >
          <strong>Add Another Employment</strong>
        </button>
      </div>
    </>
  );
};

export default AddEmploymentHistory;
