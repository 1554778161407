import React, { useState } from "react";
import { englishLevelLists } from "../../../shared/constants/arrayConfig";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "../../../shared/components/form/Select";
import { assessmentsAbilityEnglish } from "../../redux/action/assessments-personal-actions";
import { ThreeDots } from "react-loader-spinner";

function AbilityInEnglish({ handleNext, userData }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const handleAbilityEnglish = async (formData) => {
    console.log('formData', formData)
    setIsSubmitting(true);
    await dispatch(assessmentsAbilityEnglish({
      userData: {
        userId: userData?.id,
        language: "English",
        ...formData
      },
      handleNext
    }));
    setIsSubmitting(false)
  }

  return (
    <form>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6 mb-4">
              <Select
                name="read"
                label="Read"
                options={englishLevelLists}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Read is required",
                }}
                required
              />
            </div>
            <div className="col-lg-6 mb-4">
              <Select
                name="write"
                label="Write"
                options={englishLevelLists}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "write is required",
                }}
                required
              />
            </div>
            <div className="col-lg-6 mb-4">
              <Select
                name="speak"
                label="Speak"
                options={englishLevelLists}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Speak is required",
                }}
                required
              />
            </div>

            <div className="col-lg-6 mb-4">
              <Select
                name="listen"
                label="Listen"
                options={englishLevelLists}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Listen is required",
                }}
                required
              />
            </div>
          </div>
        </div>
        <div className="col-12 text-end pb-2">
          <button
            className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
            type="submit"
            onClick={handleSubmit(handleAbilityEnglish)}
            disabled={isSubmitting}
          >
            {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Save & Next"} 
          </button>
        </div>
      </div>
    </form>
  );
}

export default AbilityInEnglish;
