import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Img2 from "../../Images/FAQs.gif";
import { getFAQLists } from "../redux/action/faq-actions";
import { useDispatch, useSelector } from "react-redux";

export default function Faq() {
  const dispatch = useDispatch();
  const { faqs } = useSelector((state) => state?.faqs || []);

  useEffect(() => {
    dispatch(getFAQLists());
  }, [dispatch]);

  const filteredFaqs = faqs?.filter((faq) => faq?.deleted !== true);

  return (
    <div className="faq-section bg-white">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl mb-5">
              FAQ's
            </h2>
          </div>
          <div className="col-lg-6 align-self-center">
            <Accordion defaultActiveKey="0" flush>
              {filteredFaqs?.map((faq, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{faq?.question}</Accordion.Header>
                  <Accordion.Body>{faq?.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="col-lg-5 mx-auto align-self-center mt-lg-0 mt-5">
            <img className="img-fluid" src={Img2} alt="FAQ" />
          </div>
        </div>
      </div>
    </div>
  );
}
