import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
// import { PayPalButton } from "react-paypal-button-v2";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const stripePromise = loadStripe(
  "pk_test_51OoQ7RSFXh1UZ6QfnLDsbOKkjuOoCEtP9BkA4HXmj2FZqDSyrTMpqKQ5zh4Xkb7bKOUnx0KtRGDDk1tMNVcyWAzA00xQCBRWL7"
);

const includedFeaturesCancelPremium = [];

function Pricing() {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setPaymentError(error.message);
      setPaymentSuccess(false);
    } else {
      setPaymentSuccess(true);
    }
  };

  const { t } = useTranslation();
  const { heading1, p1, p2, p3, p4, p5, p6, btn } = t("Pricing");
  const l1 = t("Pricing.l1");
  const l2 = t("Pricing.l2");
  const l3 = t("Pricing.l3");
  const l4 = t("Pricing.l4");
  const l5 = t("Pricing.l5");
  const l6 = t("Pricing.l6");
  const l7 = t("Pricing.l7");
  const l8 = t("Pricing.l8");
  const l9 = t("Pricing.l9");
  const l10 = t("Pricing.l10");
  const l11 = t("Pricing.l11");
  const l12 = t("Pricing.l12");
  const l13 = t("Pricing.l13");
  const l14 = t("Pricing.l14");

  const includedFeatures = [l1, l2, l3, l4, l5];

  const includedFeaturesCancel = [l6, l7, l8, l9, l10, l11, l12, l13, l14];

  const includedFeaturesStandard = [l1, l2, l3, l4, l5, l6, l7, l8, l9];

  const includedFeaturesCancelStandard = [l10, l11, l12, l13, l14];

  const includedFeaturesPremium = [
    l1,
    l2,
    l3,
    l4,
    l5,
    l6,
    l7,
    l8,
    l9,
    l10,
    l11,
    l12,
    l13,
    l14,
  ];

  const handlePayPalPayment = async (details, data) => {
    try {
      const response = await fetch("/api/paypal/checkout", {
        method: "POST",
        body: JSON.stringify({
          orderID: data.orderID,
          payerID: data.payerID,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      if (result.success) {
        setPaymentSuccess("Payment successful");
      } else {
        setPaymentError("Payment failed: " + result.error);
      }

      setPaymentSuccess("Payment successful");
    } catch (error) {
      setPaymentError("Payment failed: " + error.message);
    }
  };

  return (
    <div className=" py-24 pb-0 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight  text-gray-600 sm:text-6xl mb-5">
            {heading1}
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="mx-auto px-8 rounded-2xl p-box bg-gray-50 py-10 text-left ring-1 ring-sky-300 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <p className=" font-semibold text-center text-gray-600 text-2xl font-p">
                {p1}{" "}
              </p>
              <p className="mt-3 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  {p2}
                </span>
              </p>

              <button
                type="button"
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Get Access
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title font-semibold text-black-600 text-2xl font-p"
                        id="exampleModalLabel"
                      >
                        Payment Method
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <a
                        href="https://buy.stripe.com/test_7sIaICd0Ofy65lCdQT"
                        onClick={handlePayment}
                        className="mt-6 mb-6 block w-full rounded-md bg-indigo-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {btn}
                      </a>
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h4 style={{ fontWeight: "bold", fontSize: "20px" }}>
                          OR
                        </h4>
                      </div>
                      <PayPalScriptProvider options={{ clientId: "test" }}>
                        <PayPalButtons
                          amount="10.00"
                          onSuccess={handlePayPalPayment}
                          onError={(error) => console.log(error)}
                          // options={{ clientId: "AYqhEpRzewRnpTf8tBLbRPV2SeIMZGV9UBaqDeptZzVqp5YDbT6h1tk72_XKrJ2mKuK15dSBggI--ipL" }}
                        />
                      </PayPalScriptProvider>
                    </div>
                  </div>
                </div>
              </div>
              {/* <a
                href="https://buy.stripe.com/test_7sIaICd0Ofy65lCdQT"
                onClick={handlePayment}
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                {btn}
              </a> */}

              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg">
                  <CheckIcon
                    className="h-8 w-5 flex-none text-sky-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              {includedFeaturesCancel.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg">
                  <XMarkIcon
                    className="h-8 w-5 flex-none text-red-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              <li className="flex gap-x-3 font-bold text-center mt-2 text-lg">
                {p3}
              </li>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mx-auto px-8 rounded-2xl p-box bg-gray-100 py-10 text-left ring-1 ring-sky-500 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <p className=" font-semibold text-center text-gray-600 text-2xl font-p">
                {p4}
              </p>
              <p className="mt-3 flex items-baseline justify-center gap-x-2">
                <span className="text-lg font-semibold leading-6 tracking-wide text-gray-600">
                  ₹
                </span>
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  499
                </span>
                <span className="text-xl font-semibold leading-6 tracking-wide text-gray-600">
                  {p5}
                </span>
              </p>

              <button
                type="button"
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Get Access
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title font-semibold text-black-600 text-2xl font-p"
                        id="exampleModalLabel"
                      >
                        Payment Method
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <a
                        href="https://buy.stripe.com/test_7sIaICd0Ofy65lCdQT"
                        onClick={handlePayment}
                        className="mt-6 mb-6 block w-full rounded-md bg-indigo- px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {btn}
                      </a>
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h4 style={{ fontWeight: "bold", fontSize: "20px" }}>
                          OR
                        </h4>
                      </div>
                      <PayPalScriptProvider options={{ clientId: "test" }}>
                        <PayPalButtons
                          amount="10.00"
                          onSuccess={handlePayPalPayment}
                          onError={(error) => console.log(error)}
                          // options={{ clientId: "AYqhEpRzewRnpTf8tBLbRPV2SeIMZGV9UBaqDeptZzVqp5YDbT6h1tk72_XKrJ2mKuK15dSBggI--ipL" }}
                        />
                      </PayPalScriptProvider>
                    </div>
                  </div>
                </div>
              </div>
              {/* <a
                href="https://buy.stripe.com/test_7sIaICd0Ofy65lCdQT"
                onClick={handlePayment}
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                {btn}
              </a>
              <PayPalScriptProvider options={{ clientId: "test" }}>
                <PayPalButtons
                  amount="10.00"
                  onSuccess={handlePayPalPayment}
                  onError={(error) => console.log(error)}
                // options={{ clientId: "AYqhEpRzewRnpTf8tBLbRPV2SeIMZGV9UBaqDeptZzVqp5YDbT6h1tk72_XKrJ2mKuK15dSBggI--ipL" }} 
                />
              </PayPalScriptProvider> */}
              {includedFeaturesStandard.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg">
                  <CheckIcon
                    className="h-8 w-5 flex-none text-sky-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              {includedFeaturesCancelStandard.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg">
                  <XMarkIcon
                    className="h-8 w-5 flex-none text-red-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              <li className="flex gap-x-3 font-bold text-center mt-2 text-lg">
                {p3}
              </li>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mx-auto px-8 rounded-2xl p-box bg-gray-50 py-10 text-left ring-1 ring-sky-300 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <p className=" font-semibold text-center text-gray-600 text-2xl font-p">
                {p6}
              </p>
              <p className="mt-3 flex items-baseline justify-center gap-x-2">
                <span className="text-lg font-semibold leading-6 tracking-wide text-gray-600">
                  ₹
                </span>
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  999
                </span>
                <span className="text-xl font-semibold leading-6 tracking-wide text-gray-600">
                  {p5}
                </span>
              </p>

              <button
                type="button"
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Get Access
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title font-semibold text-black-600 text-2xl font-p"
                        id="exampleModalLabel"
                      >
                        Payment Method
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <a
                        href="https://buy.stripe.com/test_fZeg2W8KydpYdS828c"
                        onClick={handlePayment}
                        className="mt-6 mb-6 block w-full rounded-md bg-indigo-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {btn}
                      </a>
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h4 style={{ fontWeight: "bold", fontSize: "20px" }}>
                          OR
                        </h4>
                      </div>
                      <PayPalScriptProvider options={{ clientId: "test" }}>
                        <PayPalButtons
                          amount="10.00"
                          onSuccess={handlePayPalPayment}
                          onError={(error) => console.log(error)}
                          // options={{ clientId: "AYqhEpRzewRnpTf8tBLbRPV2SeIMZGV9UBaqDeptZzVqp5YDbT6h1tk72_XKrJ2mKuK15dSBggI--ipL" }}
                        />
                      </PayPalScriptProvider>
                    </div>
                  </div>
                </div>
              </div>

              {/* <a
                href=" https://buy.stripe.com/test_fZeg2W8KydpYdS828c"

                onClick={handlePayment}
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                {btn}
              </a> */}
              {includedFeaturesPremium.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg">
                  <CheckIcon
                    className="h-8 w-5 flex-none text-sky-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              {includedFeaturesCancelPremium.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg">
                  <XMarkIcon
                    className="h-8 w-5 flex-none text-red-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              <li className="flex gap-x-3 font-bold text-center mt-2 text-lg">
                {p3}
              </li>
            </div>
          </div>
        </div>
        {paymentError && <div className="text-red-500">{paymentError}</div>}
        {paymentSuccess && (
          <div className="text-green-500">{paymentSuccess}</div>
        )}
      </div>
    </div>
  );
}

export default function App() {
  return (
    <Elements stripe={stripePromise}>
      <Pricing />
    </Elements>
  );
}
