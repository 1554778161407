import React from 'react';
import S1 from '../../Images/s1.gif'
import S2 from '../../Images/s2.gif'
import S3 from '../../Images/s3.gif'
import S4 from '../../Images/s4.gif'
import S5 from '../../Images/s5.gif'
import A1 from '../../Images/a1.png'
import A2 from '../../Images/a2.png'
import A3 from '../../Images/a3.png'
import A4 from '../../Images/a4.png'
import { useTranslation } from 'react-i18next';

const Steps = () => {

    const { t } = useTranslation();
    const {The,
        heading1,
        p1,
        heading2,
        subheading1,
        subhead1,
        p2,
        subp2,
        heading3,
        subheading2,
        subhead2,
        p3,
        heading4,
        subheading3,
        subhead3,
        p4,
        heading5,
        subheading4,
        subhead4,
        p5,
        heading6,
        subheading5,
        subhead5,
        p6
     } = t("Steps");

    return (
        <section className="process-section mb-5">
            <div className="container">
                <div className='row'>
                    <div className='col-12'>
                        <div className="process-main">
                            <div className="process-content text-center">
                                <h2 className="text-3xl font-bold tracking-tight  text-gray-600 sm:text-6xl">{The} <span className='text-sky-500'>{heading1}</span></h2>
                                <p className='mt-2 text-lg leading-8 text-gray-600'>{p1}</p>
                            </div>
                            <div className="process-steps mt-10">
                                {/* Step 1 */}
                                <div className="process-step row justify-content-center mb-5 position-relative">
                                    <div className='col-lg-6 align-self-center'>
                                        <h4 className="grdHdng">{heading2}</h4>
                                        <span className="step-num">{subheading1}</span>
                                        <h5 className='step-names'>{subhead1}</h5>
                                        <p className='step-sub-text'>{p2}<br className='br'></br>{subp2}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img
                                            className="step-image img-fluid"
                                            src={S1}
                                            alt="Step 1"
                                        />
                                    </div>
                                    <img className='img-fluid pp-img1' src={A1} alt='' />
                                </div>

                                {/* Step 2 */}

                                <div className="process-step row justify-content-center mb-5 position-relative">
                                    <div className='col-lg-4  order-lg-first order-last'>
                                        <img
                                            className="step-image img-fluid"
                                            src={S2}
                                            alt="Step 2"
                                        />
                                    </div>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-6 align-self-center order-lg-last order-frist'>
                                        <h4 className="grdHdng">{heading3}</h4>
                                        <span className="step-num">{subheading2}</span>
                                        <h5 className='step-names'>{subhead2}</h5>
                                        <p className='step-sub-text'>{p3}</p>
                                    </div>
                                    <img className='img-fluid pp-img2' src={A2} alt='' />

                                </div>

                                {/* Step 3 */}
                                <div className="process-step row justify-content-center mb-5  position-relative">
                                    <div className='col-lg-6 align-self-center'>
                                        <h4 className="grdHdng">{heading4}</h4>
                                        <span className="step-num">{subheading3}</span>
                                        <h5 className='step-names'>{subhead3}</h5>
                                        <p className='step-sub-text'>{p4}
                                        </p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img
                                            className="step-image img-fluid"
                                            src={S3}
                                            alt="Step 3"
                                        />
                                    </div>
                                    <img className='img-fluid pp-img3' src={A3} alt='' />

                                </div>



                                {/* Step 4 */}

                                <div className="process-step row justify-content-center mb-5 position-relative">
                                    <div className='col-lg-4 order-lg-first order-last'>
                                        <img
                                            className="step-image img-fluid"
                                            src={S4}
                                            alt="Step 4"
                                        />
                                    </div>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-6 align-self-center align-self-center order-lg-last order-frist'>
                                        <h4 className="grdHdng">{heading5}</h4>
                                        <span className="step-num">{subheading4}</span>
                                        <h5 className='step-names'>{subhead4}</h5>
                                        <p className='step-sub-text'>{p5}

                                        </p>
                                    </div>
                                    <img className='img-fluid pp-img4' src={A4} alt='' />

                                </div>
                                {/* Step 5 */}
                                <div className="process-step row justify-content-center mb-5 position-relative">
                                    <div className='col-lg-6 align-self-center'>
                                        <h4 className="grdHdng">{heading6}</h4>
                                        <span className="step-num">{subheading5}</span>
                                        <h5 className='step-names'>{subhead5}
                                        </h5>
                                        <p className='step-sub-text'>{p6}
                                        </p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <img
                                            className="step-image img-fluid"
                                            src={S5}
                                            alt="Step 5"
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Steps;
