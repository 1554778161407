import { createSlice } from "@reduxjs/toolkit";
import {
  documentsList,
  documentsCreate,
  documentsUpdate,
  documentsDelete,
  singleDocument,
  documentsUploadDocument,
} from "../action/uploadDoc-actions";

const uploadDocSlice = createSlice({
  name: "uploadDoc",
  initialState: {
    uploadDoc: [],
    status: "idle",
    singleDocument: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => [
    builder
      .addCase(documentsList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(documentsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.uploadDoc = action.payload;
      })
      .addCase(documentsList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(documentsCreate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(documentsCreate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(documentsCreate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(documentsUpdate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(documentsUpdate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.singleDocument = action.payload;
      })
      .addCase(documentsUpdate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(singleDocument.pending, (state) => {
        state.status = "loading";
      })
      .addCase(singleDocument.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleDocument = action.payload;
      })
      .addCase(singleDocument.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(documentsUploadDocument.pending, (state) => {
        state.status = "loading";
      })
      .addCase(documentsUploadDocument.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(documentsUploadDocument.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(documentsDelete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(documentsDelete.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.documents = action.payload;
      })
      .addCase(documentsDelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }),
  ],
});
export default uploadDocSlice.reducer;
