import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Home from "../../Images/house.png";
import Visa from "../../Images/boarding-pass1.png";
import Travel from "../../Images/travel1.png";
import Suitcase from "../../Images/suitcase.png";
import Migrate from "../../Images/Migrate.png";
import Study from "../../Images/passport.png";
import Immigration from "../../Images/plane-ticket.png";
import Faq from "../compomemts/Faq";
import News from "./News";
import Why2 from "../compomemts/Why2";
import { useTranslation } from "react-i18next";

function InformationHub() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {t} = useTranslation();
    const {heading1,heading2,heading3,heading4,heading5,p1,subhead1,p2,btn1,subhead2,p3,subhead3,p4,subhead4,p5,heading6,heading7,p6,subhead5,p7,subhead6,p8,subhead7,p9} = t("InformationHub");

  return (
    <>
      <Helmet>
        <title>Information Hub - Videsh Apna </title>
      </Helmet>
      <div className="bg-stone-100">
        <div className="relative  px-6 pt-14 pb-14 lg:px-8">
          <div className="mx-auto max-w-[80%] py-20 sm:py-12 lg:py-12">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-zinc-600 sm:text-6xl font-p">
                {heading1}<br></br> {heading2}{" "}
                <span className="text-sky-400">{heading3}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white px-6 py-24 sm:py-24 lg:overflow-visible lg:px-0">
        <div className="container">
          <div className="row">
            <div className="col-12 text-left">
              <h1 class="text-2xl  tracking-tight text-slate-900 sm:text-4xl main-text">
                <span class="relative whitespace-nowrap text-slate-600 font-p">
                  {heading4}
                  <span class="relative text-sky-500"> {heading5}</span>
                </span>
              </h1>
              <p className="mt-4 text-xl text-gray-500">
                {p1}
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 mt-2 mb-3">
              <div className="c-body">
                <img className="img-fluid" src={Home} width={80} alt=""></img>
                <h3 className="text-2xl font-semibold font-p mt-3 mb-2">
                  {subhead1}
                </h3>
                <p className="mb-3">
                  {p2}
                </p>
                <Link
                  to="/assessment"
                  className="font-medium font-p  text-sky-400 hover:text-sky-600 border-b"
                >
                  {btn1}
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-2 mb-3">
              <div className="c-body">
                <img className="img-fluid" src={Visa} width={80} alt=""></img>
                <h3 className="text-2xl font-semibold font-p mt-3 mb-2">
                  {subhead2}
                </h3>
                <p className="mb-3">
                  {p3}
                </p>
                <Link
                  to="/assessment"
                  className="font-medium font-p  text-sky-400 hover:text-sky-600 border-b"
                >
                  {btn1}
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-2 mb-3">
              <div className="c-body">
                <img className="img-fluid" src={Travel} width={80} alt=""></img>
                <h3 className="text-2xl font-semibold font-p mt-3 mb-2">
                  {subhead3}
                </h3>
                <p className="mb-3">
                  {p4}
                </p>
                <Link
                  to="/assessment"
                  className="font-medium font-p  text-sky-400 hover:text-sky-600 border-b"
                >
                 {btn1}
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-2 mb-3">
              <div className="c-body">
                <img
                  className="img-fluid"
                  src={Suitcase}
                  width={80}
                  alt=""
                ></img>
                <h3 className="text-2xl font-semibold font-p mt-3 mb-2">
                  {subhead4}
                </h3>
                <p className="mb-3">
                 {p5}
                </p>
                <Link
                  to="/assessment"
                  className="font-medium font-p  text-sky-400 hover:text-sky-600 border-b"
                >
                  {btn1}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-custome-blue px-6 py-24 sm:py-24 lg:overflow-visible lg:px-0">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-3">
              <h1 class="text-2xl tracking-tight text-slate-900 sm:text-4xl main-text">
                
                 {heading6}
                  <span class="relative text-sky-500">
                    &nbsp;{heading7}
                </span>
              </h1>
              <p className="mt-3 text-xl text-gray-500">
                {p6}
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6 text-center">
              <div className="text-center">
                <img
                  className="img-fluid mx-auto"
                  src={Migrate}
                  width={80}
                  alt=""
                ></img>
                <h3 className="text-2xl font-semibold font-p mt-3 mb-2">
                  {subhead5}
                </h3>
                <p className="mb-3 font-p px-3">
                 {p7}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="text-center">
                <img
                  className="img-fluid mx-auto"
                  src={Immigration}
                  width={80}
                  alt=""
                ></img>
                <h3 className="text-2xl font-semibold font-p mt-3 mb-2">
                  {subhead6}
                </h3>
                <p className="mb-3 font-p px-3">
                  {p8}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 text-center">
              <div className="text-center">
                <img
                  className="img-fluid mx-auto"
                  src={Study}
                  width={80}
                  alt=""
                ></img>
                <h3 className="text-2xl font-semibold font-p mt-3 mb-2">
                  {subhead7}
                </h3>
                <p className="mb-3 font-p px-3">
                  {p9}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faq />
      <News />
      <Why2 />

    </>
  );
}

export default InformationHub;
