import React, { useState } from "react";

const Radio = ({ name, label, register, errors, required, validationSchema, radioOptions, mt, onChangeRadio }) => {
    const [selectedValue, setSelectedValue] = useState(null)

    return (
        <>
            <p className="block mb-2 text-lg font-medium text-gray-900">{label} {required && <span className="text-red-500">*</span>}</p>
            {radioOptions && radioOptions.map((option, idx) => {
                return <label htmlFor={`${option?.value}-${name}`} className={`mt-${mt} mr-2`} key={idx}>
                    <input
                        {...register(name, validationSchema)}
                        type="radio"
                        name={name}
                        value={option?.value}
                        className="form-check-input me-2"
                        id={`${option?.value}-${name}`}
                        checked={option?.value === selectedValue}
                        onChange={(event) => {
                            setSelectedValue(event.target.value)
                            if (onChangeRadio) {
                                onChangeRadio(event.target.value)
                            }
                        }}
                    />
                    {option?.label}
                </label>
            })}
            {errors && (errors[name]?.type === "required" || errors[name]?.type === "validate") && (
                <p className="text-red-500 text-sm pt-1">{errors[name]?.message}</p>
            )}
        </>
    );
};

export default Radio;
