import { createSlice } from "@reduxjs/toolkit";
import { getTestimonialLists } from "../action/testimonial-actions";

const testimonialSlice = createSlice({
    name: 'testimonial',
    initialState: {
        isLoading: false,
        testimonials: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTestimonialLists.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.testimonials = payload
        })
    },
});

export default testimonialSlice.reducer