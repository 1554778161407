import { createAsyncThunk } from '@reduxjs/toolkit'
import apiConfig from '../../config/api-config';

const initialUserData = {
    limit: '20',
    offset: '0',
    filter: null,
    sorting: {
        fieldName: 'created_at',
        direction: 'asc',
    },
};

export const getConsultantLists = createAsyncThunk("consultantLists", async () => {
    try {
        const response = await apiConfig.post("Consultant/List", initialUserData);
        if (response?.data?.succeeded) {
            return response?.data?.data;
        }
    } catch (error) {
        console.error('error', error);
        throw error; 
    }
});

export const getConsultantDocumentLists = createAsyncThunk("consultantDocumentLists", async () => {
    try {
        const response = await apiConfig.post("ConsultantDocument/List", initialUserData);
        if (response?.data?.succeeded) {
            return response?.data?.data;
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});


