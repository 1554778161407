import { color } from "framer-motion";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class Chart2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [{
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        
      }],
      
      options: {
        chart: {
          type: 'bar',
          height: 300,
          labels: {
            style: {
              colors: '#000' // Set font color to white
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            labels: {
              style: {
                colors: '#000' // Set font color to white
              }
            }
          }
        },
        dataLabels: {
          enabled: false,
          labels: {
            style: {
              colors: '#000' // Set font color to white
            }
          }
        },
        xaxis: {
          categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
            'United States', 'China', 'Germany'
          ],
          
          labels: {
            style: {
              colors: '#ffffff' // Set font color to white
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#ffffff' // Set font color to white
            }
          }
        },
      }
    };
  }


  render() {
    return (
      <div className="apexcharts-toolbar1">
       <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={300}  />
              </div>
              <div id="html-dist"></div>
    </div>
    );
  }
}

export default Chart2;
