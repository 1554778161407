import { createAsyncThunk } from '@reduxjs/toolkit';
import apiConfig from '../../config/api-config';


const initialUserData = {
    limit: '20',
    offset: '0',
    filter: null,
    sorting: {
        fieldName: 'created_at',
        direction: 'asc',
    },
};

export const getconsultantConnected = createAsyncThunk(
  'consultants/connectConsultant',
  async ({ userId, consultantId }) => {
    const response = await apiConfig.post(`ConsultantConnectedUserList/Create`, {
      userId,
      consultantId,
      initialUserData,
      connected: true,
    });
    return response.data;
  }
);