import React from "react";
import InputText from "../../../../shared/components/form/InputText";
import { useFieldArray } from "react-hook-form";

const AddEmploymentBusinessHistory = ({ register, errors, control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employmentForms",
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div className="row border border-5px mt-4 p-3" key={index}>
            <div className="col-12">
              <button
                type="button"
                className="close md-1 bg-sky-400 float-right text-center font-medium text-white text-uppercase"
                style={{ height: "30px", width: "30px" }}
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => remove(index)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="text"
                name={`employmentForms[${index}].companyName`}
                label="Name of company or organization"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Company name is required",
                }}
                required
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="text"
                name={`employmentForms[${index}].designation`}
                label="Job Title"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Job title is required",
                }}
                required
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="date"
                name={`employmentForms[${index}].startingDate`}
                label="StartDate"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "StartDate is required",
                }}
                required
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="date"
                name={`employmentForms[${index}].completionDate`}
                label="EndDate"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "EndDate is required",
                }}
                required
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="text"
                name={`employmentForms[${index}].natureOfBusiness`}
                label="Nature of Business or industry in which the company operates"
                register={register}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="text"
                name={`employmentForms[${index}].ownership`}
                label="% of ownership (if applicable)"
                register={register}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="text"
                name={`employmentForms[${index}].ownerEquity`}
                label="Owner's equity US$ (if applicable)"
                register={register}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <InputText
                type="number"
                name={`employmentForms[${index}].noOfSubordinates`}
                label="Number of subordinates directly reporting to you"
                register={register}
              />
            </div>
          </div>
        )
      })}
      <div className="col-12 mt-4 text-center">
        <button
          className="rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600 text-uppercase"
          onClick={(event) => append(event.preventDefault())}
          btn
          btn-primary
        >
          <strong>Add Another Employment</strong>
        </button>
      </div>
    </>
  );
};

export default AddEmploymentBusinessHistory;
