import { createSlice } from "@reduxjs/toolkit";
import { createFreeAssessments } from "../action/freeAssessments-action";

const freeAssessmentsSlice = createSlice({
    name: 'freeAssessmentsSlice',
    initialState: {
        isLoading: false,
        data: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createFreeAssessments.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.data = payload
        })
    },
});

export default freeAssessmentsSlice.reducer