import { createSlice } from "@reduxjs/toolkit";
import { getBlogDetails, getBlogLists } from "../action/blogs-actions";

const blogsSlice = createSlice({
    name: 'blog',
    initialState: {
        isLoading: false,
        blogs: [],
        blogDetails: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBlogLists.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.blogs = payload
        })
        builder.addCase(getBlogDetails.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.blogDetails = payload
        })
    },
});

export default blogsSlice.reducer