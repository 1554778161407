const Select = ({ name, label, register, errors, required, validationSchema, options }) => (
    <>
        {label &&
            <label className="block mb-2 text-lg font-medium text-gray-900" htmlFor={name} >
                {label} {required && <span className="text-red-500">*</span>}
            </label>
        }
        <select
            className="form-control custom-form-control2"
            id={name}
            name={name}
            type="select"
            {...register(name, { ...validationSchema })}
        >
            <option value="">Please Select</option>
            {options && options.map((option, idx) => (
                <option
                    key={idx}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </select>
        {errors && (errors[name]?.type === "required" || errors[name]?.type === "validate") && (
            <p className="text-red-500 text-sm pt-1">{errors[name]?.message}</p>
        )}
    </>
);
export default Select;