import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Radio from '../../../shared/components/form/Radio';
import AddEmploymentBusinessHistory from './history/AddEmploymentBusinessHistory';
import { radioLists } from '../../../shared/constants/arrayConfig';
import { assessmentsEmployHistory } from '../../redux/action/assessments-personal-actions';
import { convertBooleanValue } from '../../../shared/utils/shared-method';
import { ThreeDots } from 'react-loader-spinner';

function ManagementExperience({ handleNext, visaType, userData }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmployHistory = async (formData) => {
    if (watch("paidExperience") === "Yes") {
      const employmentForms = formData?.employmentForms || [];

      // Add new fields to each object in the employmentForms array
      const updatedEmploymentForms = employmentForms.map((employmentForm) => ({
        ...employmentForm,
        address: "",
        employmentStatus: "",
        employmentDuties: "",
        pinCode: "",
        noOfSubordinates: 0,
        description: "",
        userId: userData?.id,
        ...convertBooleanValue(["paidExperience"], watch),
      }));

      // Loop through each updated employment form and make an API call
      for (const updatedEmploymentForm of updatedEmploymentForms) {
        setIsSubmitting(true);
        await dispatch(assessmentsEmployHistory({
          userData: [updatedEmploymentForm],
          handleNext, visaType
        }));
        setIsSubmitting(false)
      }
    } else {
      handleNext({ currentStep: 3 });
    }
  };

  return (
    <form>
      <div className="row">
        <div className="col-12 mb-3">
          <Radio name="paidExperience" label="Have you had paid work experience in the last 10 years" radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("paidExperience", value)}
            register={register}
            validationSchema={{
              required: "Paid experience is required",
            }}
            required />
        </div>
        <div className="col-12 mb-4 px-4">
          {watch("paidExperience") === "Yes" && <AddEmploymentBusinessHistory register={register} errors={errors} control={control} />}
        </div>
        <div className="col-12 text-end pb-2">
          <button
            className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
            type="submit"
            onClick={handleSubmit(handleEmployHistory)}
            disabled={isSubmitting}
          >
           {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Save & Next"}
          </button>
        </div>
      </div>
    </form >
  )
}

export default ManagementExperience