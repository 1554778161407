import { createAsyncThunk } from "@reduxjs/toolkit";
import apiConfig from "../../config/api-config";

// const initialUserData = {
//   limit: '10',
//   offset: '0',
//   filter: null,
//   sorting: {
//     fieldName: 'created_at',
//     direction: 'asc',
//   },
// };

export const personalInfoUpdate = createAsyncThunk(
  "userdetails/update",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await apiConfig.post(`UserDetails/Update`, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
