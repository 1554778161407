import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const stripePromise = loadStripe(
  "pk_test_51OoQ7RSFXh1UZ6QfnLDsbOKkjuOoCEtP9BkA4HXmj2FZqDSyrTMpqKQ5zh4Xkb7bKOUnx0KtRGDDk1tMNVcyWAzA00xQCBRWL7"
);

const includedFeaturesCancelPremium = [];

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    console.log("error :--------", error);
    console.log("paymentMethod :--------", paymentMethod);
    if (error) {
      console.log("\n error", error);
      setPaymentError(error.message);
      setPaymentSuccess(false);
    } else {
      console.log("PaymentMethod", paymentMethod);
      setPaymentSuccess(true);
    }
  };

  const { t } = useTranslation();
  const { heading1, p1, p2, p3, p4, p5, p6, p7, p8, btn } = t("Pricing");
  const l1 = t("Pricing.l1");
  const l2 = t("Pricing.l2");
  const l3 = t("Pricing.l3");
  const l4 = t("Pricing.l4");
  const l5 = t("Pricing.l5");
  const l6 = t("Pricing.l6");
  const l7 = t("Pricing.l7");
  const l8 = t("Pricing.l8");
  const l9 = t("Pricing.l9");
  const l10 = t("Pricing.l10");
  const l11 = t("Pricing.l11");
  const l12 = t("Pricing.l12");
  const l13 = t("Pricing.l13");
  const l14 = t("Pricing.l14");

  const includedFeatures = [l1, l2, l3, l4, l5];

  const includedFeaturesCancel = [l6, l7, l8, l9, l10, l11, l12, l13, l14];

  const includedFeaturesStandard = [l1, l2, l3, l4, l5, l6, l7, l8, l9];

  const includedFeaturesCancelStandard = [l10, l11, l12, l13, l14];

  const includedFeaturesPremium = [
    l1,
    l2,
    l3,
    l4,
    l5,
    l6,
    l7,
    l8,
    l9,
    l10,
    l11,
    l12,
    l13,
    l14,
  ];
  // rzp payment
  const handleSubmit = (amount) => {
    if (amount === "") {
      alert("please enter amount");
    } else {
      var options = {
        key: "rzp_test_3LpDehXF7KPUkq",
        key_secret: "cY0u3rmnQDFhJEoKROrsSi78",
        amount: amount * 100,
        currency: "INR",
        name: "Videsh Apna",
        description: "for testing purpose",
        handler: function (response) {
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name: "Videsh Apna",
          email: "info@videshapna.com",
          // contact: "9023620998",
        },
        notes: {
          address: "Razorpay Corporate office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };
  const users = useSelector((state) => state?.users?.singleUser?.data);
  console.log("Usersss", users);
  const params = useLocation();
  let userId = params?.search.replace("?id=", "");
  console.log("userId", userId);

  return (
    <div className="py-24 pb-5 sm:py-24 " style={{ background: "#25293c" }}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight  text-white sm:text-6xl mb-5">
            {heading1}
          </h2>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="mx-auto px-8 rounded-2xl p-box bg-new-dark py-10 text-left ring-1  lg:flex lg:flex-col lg:justify-center lg:py-16">
              <p className=" font-semibold text-center text-white text-2xl font-p">
                {p1}{" "}
              </p>
              <p className="mt-3 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-white">
                  {p2}
                </span>
              </p>

              <button
                type="button"
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Get Access
              </button>

              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg text-white">
                  <CheckIcon
                    className="h-8 w-5 flex-none text-sky-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              {includedFeaturesCancel.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg text-white">
                  <XMarkIcon
                    className="h-8 w-5 flex-none text-red-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              <li className="flex gap-x-3 font-bold text-center mt-2 text-lg text-white">
                {p3}
              </li>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mx-auto px-8 rounded-2xl p-box bg-new-dark py-10 text-left ring-1 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <p className=" font-semibold text-center text-white text-2xl font-p">
                {p4}
              </p>
              <p className="mt-3 flex items-baseline justify-center gap-x-2">
                <span className="text-lg font-semibold leading-6 tracking-wide text-white">
                  ₹
                </span>
                <span className="text-5xl font-bold tracking-tight text-white">
                  499
                </span>
                <span className="text-xl font-semibold leading-6 tracking-wide text-white">
                  {p5}
                </span>
              </p>
              <button
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500
      focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                onClick={() => handleSubmit(499)}
              >
                Get Access
              </button>

              {includedFeaturesStandard.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg text-white">
                  <CheckIcon
                    className="h-8 w-5 flex-none text-sky-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              {includedFeaturesCancelStandard.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg text-white">
                  <XMarkIcon
                    className="h-8 w-5 flex-none text-red-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              <li className="flex gap-x-3 font-bold text-center mt-2 text-lg text-white">
                {p7}
              </li>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mx-auto px-8 rounded-2xl p-box bg-new-dark py-10 text-left ring-1  lg:flex lg:flex-col lg:justify-center lg:py-16">
              <p className=" font-semibold text-center text-white text-2xl font-p">
                {p6}
              </p>
              <p className="mt-3 flex items-baseline justify-center gap-x-2">
                <span className="text-lg font-semibold leading-6 tracking-wide text-white">
                  ₹
                </span>
                <span className="text-5xl font-bold tracking-tight text-white">
                  999
                </span>
                <span className="text-xl font-semibold leading-6 tracking-wide text-white">
                  {p5}
                </span>
              </p>

              <button
                className="mt-6 mb-6 block w-full rounded-md bg-sky-400 px-3 py-3 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500
      focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                onClick={() => handleSubmit(999)}
              >
                Get Access
              </button>

              {includedFeaturesPremium.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg text-white">
                  <CheckIcon
                    className="h-8 w-5 flex-none text-sky-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              {includedFeaturesCancelPremium.map((feature) => (
                <li key={feature} className="flex gap-x-3 text-lg text-white">
                  <XMarkIcon
                    className="h-8 w-5 flex-none text-red-600 text-lg"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
              <li className="flex gap-x-3 font-bold text-center mt-2 text-lg text-white">
                {p8}
              </li>
            </div>
          </div>
        </div>
        {paymentError && <div className="text-red-500">{paymentError}</div>}
        {paymentSuccess && (
          <div className="text-green-500">{paymentSuccess}</div>
        )}
      </div>
    </div>
  );
};

export default function App() {
  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  );
}
