import React from "react";
import { commonRoutes } from "../route";
import Home from "../../customer/Pages/Home";
import Signup from "../../customer/Pages/SignUp";
import Login from "../../customer/Pages/Login";
import Assessment from "../../customer/Pages/Assessment";
import Assessments from "../../customer/Pages/Assessments";
import AssessmentsForm from "../../customer/Pages/AssessmentsForm";
import CheckEligibility from "../../customer/Pages/CheckEligibility";
import ThankYou from "../../customer/Pages/ThankYou";
const AntiFraudPolicy = React.lazy(() =>
  import("../../customer/Pages/AntiFraudPolicy")
);
const Blogs = React.lazy(() => import("../../customer/Pages/Blogs"));
const BlogsDetails = React.lazy(() =>
  import("../../customer/Pages/BlogsDetails")
);
const Contact = React.lazy(() => import("../../customer/Pages/Contact"));
const Error = React.lazy(() => import("../../customer/Pages/Error"));
const PlatformOverview = React.lazy(() =>
  import("../../customer/Pages/PlatformOverview")
);
const PrivacyPolicy = React.lazy(() =>
  import("../../customer/Pages/PrivacyPolicy")
);
const Refund = React.lazy(() => import("../../customer/Pages/Refund"));
const ServicesOverview = React.lazy(() =>
  import("../../customer/Pages/ServicesOverview")
);
const Terms = React.lazy(() => import("../../customer/Pages/Terms"));

const InformationHub = React.lazy(() =>
  import("../../customer/Pages/InformationHub")
);
export const commonPageRoute = [
  {
    path: commonRoutes.LOGIN,
    component: Login,
    errorElement: <Error />,
    showHeaderAndFooter: false,
  },
  {
    path: commonRoutes.SIGN_UP,
    component: Signup,
    errorElement: <Error />,
    showHeaderAndFooter: false,
  },
  {
    path: commonRoutes.HOME,
    component: Home,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.CONTACT,
    component: Contact,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.BLOG,
    component: Blogs,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.BLOG_DETAILS,
    component: BlogsDetails,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.SERVICES_OVERVIEW,
    component: ServicesOverview,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.PLATFORM_OVERVIEW,
    component: PlatformOverview,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.PRIVACY_POLICY,
    component: PrivacyPolicy,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.TERMS,
    component: Terms,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.REFUND,
    component: Refund,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.ANTI_FRAUD_POLICY,
    component: AntiFraudPolicy,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },

  {
    path: commonRoutes.INFORMATION_HUB,
    component: InformationHub,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },

  {
    path: commonRoutes.ASSESSMENT,
    component: Assessment,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.ASSESSMENTS,
    component: Assessments,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.FREE_ASSESSMENT,
    component: AssessmentsForm,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.CHECKELIGIBILITY,
    component: CheckEligibility,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: commonRoutes.THANKYOU,
    component: ThankYou,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
];
