import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { cityLists, convertBooleanValue, countryCurrency, countryLists, stateLists } from "../../../shared/utils/shared-method";
import { genderLists, maritalStatusLists, radioLists } from "../../../shared/constants/arrayConfig";
import { useForm } from "react-hook-form";
import { assessmentsPersonalUser } from "../../redux/action/assessments-personal-actions";
import Select from "../../../shared/components/form/Select";
import InputText from "../../../shared/components/form/InputText";
import { Country } from "country-state-city";
import Radio from "../../../shared/components/form/Radio";
import { validEmail, validatePwd } from "../../../shared/utils/validation";
import { createUserWithoutLogin } from "../../redux/action/auth-actions";
import { ThreeDots } from "react-loader-spinner";
// import { getUser } from "../../redux/action/users-actions";

function PersonalDetails({ handleNext, preferredCountry, visaType, pathname }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange"
  });
  const dispatch = useDispatch();
  const booleanFields = ["seriousMedicalCondition", "criminalRecord"];  // Convert specific fields to boolean

  const handlePersonalInfo = async (formData) => {
    setIsSubmitting(true);
    const response = await dispatch(createUserWithoutLogin({
      userData: {
        username: formData?.username,
        name: formData?.name,
        email: formData?.email,
        phone: formData?.phone,
        password: formData?.password,
        type: pathname,
      }, dispatch
    }))
    if (response?.payload?.succeeded) {
      await dispatch(assessmentsPersonalUser({
        userData: {
          userId: response?.payload?.data,
          preferredCountry: preferredCountry,
          visaType: visaType,
          ...formData,
          ...convertBooleanValue(booleanFields, watch), // Include boolean fields in the userData
        },
        handleNext, dispatch
      }));
      setIsSubmitting(false)
    }
  };

  return (
    <>
      <form>
        <div className="row">
          <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="username"
              label="Username"
              errors={errors}
              register={register}
              validationSchema={{
                required: "User name is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="name"
              label="Name"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Name is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="email"
              name="email"
              label="Email"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Email is required",
                validate: (value) => validEmail(value),
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="number"
              name="phone"
              label="Phone"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Mobile is required",
                validate: (value) => {
                  if (!/^[0-9]+$/.test(value)) {
                    return "Only numeric values are allowed";
                  }
                  if (value.length < 10) {
                    return "Mobile number must be at least 10 digits";
                  }
                  return true;
                },
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="password"
              name="password"
              label="Password"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Password is required",
                validate: (value) => {
                  return validatePwd(value);
                },
              }}
              required
            />
          </div>
          {/* <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="name"
              label="Name"
              register={register}
              disable={true}
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="email"
              label="Email"
              register={register}
              disable={true}
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="phone"
              label="Phone"
              register={register}
              disable={true}
            />
          </div> */}
          <div className="col-lg-4 mb-4">
            <InputText
              type="date"
              name="dateOfBirth"
              label="Date Of Birth"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Birth date is required",
                validate: (value) => {
                  const today = new Date().toISOString().split("T")[0];
                  if (value >= today) {
                    return "Birth date cannot be today or a future date";
                  }
                  return true;
                },
              }}
              required
            />
          </div>
          <div className="col-lg-8 mb-4">
            <InputText
              type="text"
              name="address1"
              label="Address"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Address is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="number"
              name="pinCode"
              label="PinCode"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Pin-code is required",
                validate: (value) => {
                  if (!/^[0-9]+$/.test(value)) {
                    return "Only numeric values are allowed";
                  }
                  if (value.length < 6) {
                    return "Pin-code must be at least 6 digits";
                  }
                  return true;
                },
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <Select
              name="country"
              label="Country"
              options={countryLists(Country.getAllCountries())}
              errors={errors}
              register={register}
              validationSchema={{
                required: "Country is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <Select
              name="state"
              label="State"
              options={stateLists(watch("country"))}
              errors={errors}
              register={register}
              validationSchema={{
                required: "State is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <Select
              name="city"
              label="City"
              options={cityLists(watch("state"))}
              errors={errors}
              register={register}
              validationSchema={{
                required: "City is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="education"
              label="Education"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Education  is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="occupation"
              label="Occupation"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Occupation is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <Select
              name="gender"
              label="Gender"
              options={genderLists}
              errors={errors}
              register={register}
              validationSchema={{
                required: "Gender is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <Select
              name="maritalStatus"
              label="Marital Status"
              options={maritalStatusLists}
              errors={errors}
              register={register}
              validationSchema={{
                required: "Marital status is required",
              }}
              required
            />
          </div>
          <div className="col-lg-4 mb-4">
            <InputText
              type="text"
              name="citizenship"
              label="Citizenship"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Citizenship is required",
              }}
              required
            />
          </div>
          <div className="col-12 mb-4">
            <div className="row align-items-center">
              <div className="col-lg-8 col-12">
                <p>What were your results in each of the four language skills (reading, speaking, listening and writing)?</p>
              </div>
              <div className="col-lg-4 col-12">
                <InputText
                  type="text"
                  name="skills"
                  errors={errors}
                  register={register}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col-lg-4 align-self-center">
                <p className="text-lg font-p">
                  Personal Net worth
                </p>
                <small>
                  the net worth is considered for some
                  immigration programs
                </small>
              </div>
              <div className="col-lg-4">
                <Select
                  name="netWorthCurrency"
                  label="Currency"
                  options={countryCurrency(Country.getAllCountries())}
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "currency year is required",
                  }}
                  required
                />
              </div>
              <div className="col-lg-4">
                <InputText
                  type="number"
                  name="netWorth"
                  label="Yearly Amount"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "Yearly Amount is required",
                    validate: (value) => {
                      if (!/^[0-9]+$/.test(value)) {
                        return "Only numeric values are allowed";
                      }
                      if (value.length < 3) {
                        return "Yearly Amount must be at least 3 digits";
                      }
                      return true;
                    },
                  }}

                  
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <Radio name="seriousMedicalCondition"
              label="Have you (or your spouse or common-law partner or any of your dependent children) ever had a serious medical condition?" radioOptions={radioLists} errors={errors}
              onChangeRadio={(value) => setValue("seriousMedicalCondition", value)}
              register={register}
              validationSchema={{
                required: "Medical condition is required",
              }}
              required />
          </div>
          {watch("seriousMedicalCondition") === "Yes" && (
            <div className="col-12 mb-3">
              <InputText
                name="medicalReason"
                label="Please provide details"
                errors={errors}
                register={register}
                isTextarea
              />
            </div>
          )}

          <div className="col-12 mb-3">
            <Radio name="criminalRecord"
              label="Have you (or your spouse or common-law partner or any of your dependent children) ever had a criminal record (arrested, charged or convicted)?" radioOptions={radioLists} errors={errors}
              onChangeRadio={(value) => setValue("criminalRecord", value)}
              register={register}
              validationSchema={{
                required: "Criminal Record is required",
              }}
              required />
          </div>
          {watch("criminalRecord") === "Yes" && (
            <div className="col-12 mb-3">
              <InputText
                name="criminalRecordDescription"
                label="Please provide details"
                register={register}
                isTextarea
              />
            </div>
          )}
          <div className="col-12 text-end">
            <button
              className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
              type="submit"
              onClick={handleSubmit(handlePersonalInfo)}
              disabled={isSubmitting}
            >
              {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Save & Next"}
            </button>
          </div>
        </div>
      </form >
    </>
  );
}

export default PersonalDetails;
