import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
// import search from "../../Images/search.png";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogLists } from "../redux/action/blogs-actions";
import avatarImg from "../../Images/avatar.png";
import moment from "moment";

function News() {
  const dispatch = useDispatch();
  const { blogs } = useSelector(state => state?.blogs || []);
  const blogLists = blogs?.filter((item) => item?.deleted !== true).slice(0, 3); // Take only the first two items

  useEffect(() => {
    dispatch(getBlogLists());
  }, [dispatch]);

  return (
    <>
      <section className="main-section bg-custome-blue">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl mb-5">Latest News</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="row mx-auto">
                  {blogLists.map((blog, index) => (
                    <article key={index} className="col-lg-4 mb-4 flex max-w-xl flex-col items-start justify-between">
                      <div className="box-blog shadow-sm w-100 h-100">
                        <img
                          className="img-fluid bolg-img w-100"
                          src={`data:image/jpeg;base64,${blog?.img}`}
                          alt={blog?.title}
                        />
                        <div className="inner-blog-blox">
                          <p className="text-gray-500 text-sm">
                            {moment(blog?.createdAt).format("MMM DD YYYY")}
                          </p>
                          <div className="group relative">
                            <h3 className="mt-2 text-xl font-semibold leading-6 text-gray-900 sm:text-2xl sm:leading-7">
                              <Link to={`/blog/${blog?.slug}`}>
                                {blog?.title}
                              </Link>
                            </h3>
                            <div
                              className="blog-details mt-2 line-clamp-3 text-md leading-6 text-gray-600"
                              dangerouslySetInnerHTML={{
                                __html: `${blog?.description}`,
                              }}
                            />
                          </div>
                          <div className="relative mt-6 flex items-center gap-x-4">
                            <img
                              src={avatarImg}
                              alt="avatar"
                              className="h-10 w-10 rounded-full bg-gray-50"
                            />
                            <div className="text-sm leading-6">
                              <p className="font-semibold text-gray-900">
                                {blog?.authorName}
                              </p>
                              <p className="text-gray-600">
                                Co-Founder / CTO
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default News;
