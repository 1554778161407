import { Navigate } from "react-router-dom";
import ScrollToTop from "../../../customer/compomemts/ScrollToTop";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
  const isLogin = localStorage.getItem("isLogin");
  return (
    <>
      <Header />
      <main style={{background:"#25293c"}}>
        {isLogin == "true" ? children : <Navigate to="/auth-error" />}
      </main>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Layout;
