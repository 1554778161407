import { createSlice } from "@reduxjs/toolkit";
import { getUser, getUserDetails } from "../action/users-actions";

const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState: {
        loadingState: {
            singleUser: false,
        },
        userDetails: {}, // Renamed to details for clarity
        userData: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetails.fulfilled, (state, { payload: userDetails }) => {
                state.details = userDetails;
                state.loadingState.singleUser = false;
            })
            .addCase(getUser.pending, (state) => {
                state.loadingState.singleUser = true;
            })
            .addCase(getUser.fulfilled, (state, { payload: userData }) => {
                state.userData = userData;
                state.loadingState.singleUser = false;
            })
            .addCase(getUser.rejected, (state) => {
                state.loadingState.singleUser = false;
            });
    },
});

export default userDetailsSlice.reducer;
