import React from "react";
import "react-step-progress/dist/index.css";
import { Helmet } from "react-helmet";
import search from "../../Images/search.png";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import Im1 from "../../Images/construction-worker.png";
import Im2 from "../../Images/career-choice.png";
import Im3 from "../../Images/travel.png";
import Im4 from "../../Images/briefcase.png";
import Im5 from "../../Images/family.png";
import Im6 from "../../Images/mortarboard.png";
import Im7 from "../../Images/boarding-pass.png";
import ss8 from "../../Images/ss8.gif";
import Iim1 from "../../Images/meeting-point.png";
import Iim2 from "../../Images/operator.png";
import Iim3 from "../../Images/email.png";
import Iim4 from "../../Images/hourglass.png";

export default function Assessments() {
  return (
    <>
      <Helmet>
        <title>Assessment - Videsh Apna</title>
      </Helmet>
      {/* <div className="bg-stone-100">
        <div className="relative  px-6 pt-14 pb-14 lg:px-8">
          <div className="mx-auto max-w-[100%] py-20 sm:py-12 lg:py-12">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-sky-500 sm:text-6xl font-p mb-2">
                Free Immigration Evaluation Forms
              </h1>
              <p className="text-lg leading-8 text-zinc-600 font-p">
                Complete one of the questionnaires below to receive a free assessment of your qualifications for temporary or permanent immigration.
              </p><p className="mt-0 text-lg leading-8 text-zinc-600 font-p">
                We will provide you with your immigration options within 1-2 business days.
              </p><p className="mt-0 text-lg leading-8 text-zinc-600 font-p">
                FIND OUT NOW how to start your Immigration journey!
              </p>

            </div>
          </div>
        </div>
      </div> */}
      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <span className="sub-title text-sky-900 font-p">QUICK CHECK</span>
                  <h2 className="main-h2 text-sky-500 font-p">Free Assessments</h2>
                  <p className="mt-3 text-lg leading-8 text-zinc-600 font-p">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur, eveniet labore. Earum nihil consectetur rerum nulla accusamus perspiciatis voluptas ipsum perferendis, similique enim delectus labore asperiores provident est ratione iure?</p>
                </div>
                <div className="col-12 mt-5">
                  <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-4 col-sm-6 text-center mb-4">
                      <Link to="/assessments-form">
                        <div className="contect-box text-center ">
                          <img className="img-fluid mb-3 mx-auto text-center" src={Im1} alt="" />
                          <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                            Skilled <br className="br"></br> Worker
                          </h3>

                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 text-center mb-4">
                      <Link to="/assessments-form">
                        <div className="contect-box text-center ">
                          <img className="img-fluid mb-3 mx-auto text-center" src={Im2} alt="" />
                          <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">

                            Temporary Pr Pathways
                          </h3>

                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 text-center mb-4">
                      <Link to="/assessments-form">
                        <div className="contect-box text-center ">
                          <img className="img-fluid mb-3 mx-auto text-center" src={Im3} alt="" />
                          <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                            Start-Up<br className="br"></br> Visa
                          </h3>

                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 text-center mb-4">
                      <Link to="/assessments-form">
                        <div className="contect-box text-center ">
                          <img className="img-fluid mb-3 mx-auto text-center" src={Im4} alt="" />
                          <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                            Business<br className="br"></br> Candidate
                          </h3>

                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 text-center mb-4">
                      <Link to="/assessments-form">
                        <div className="contect-box text-center ">
                          <img className="img-fluid mb-3 mx-auto text-center" src={Im5} alt="" />
                          <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                            Family<br className="br"></br> Sponsorship
                          </h3>

                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 text-center mb-4">
                      <Link to="/assessments-form">
                        <div className="contect-box text-center ">
                          <img className="img-fluid mb-3 mx-auto text-center" src={Im6} alt="" />
                          <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                            Canada<br className="br"></br> Study
                          </h3>

                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 text-center mb-4">
                      <Link to="/assessments-form">
                        <div className="contect-box text-center ">
                          <img className="img-fluid mb-3 mx-auto text-center" src={Im7} alt="" />
                          <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                            Visitor<br className="br"></br> Visa
                          </h3>

                        </div>
                      </Link>
                    </div>
                  </div>
                </div>



              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="main-section bg-[#f0f9ff]">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h2 className="main-h2 text-sky-500 font-p">Immigration Telephone Consultation
              </h2>
              <p className="mt-3 text-lg leading-8 text-zinc-600 font-p">If you wish to consult with us by telephone to address any issue, please let us know by completing the following short form. Upon receipt, we will immediately contact you to schedule a 30-minute telephone appointment consultation with a licensed Canadian immigration practitioner approved by the Government of Canada to represent your interests.</p>
              <Link to="/contact" className="mt-4 inline-block rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600">Book a telephone consultation</Link>
            </div>
            <div className="col-lg-6 align-self-center">
              <img className="img-fluid w-100" src={ss8} />
            </div>
          </div>
        </div>
      </section>




      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                QUICK CONTACT
              </span>
              <h2 className="main-h2 text-sky-500 font-p">
                Have Questions?
              </h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}

              <div className="col-12">
                <div className="row mt-5 md:w-[96%]">
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim1} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Location
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        204, 205, Titanium One, Pakwan Cross Road
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim2} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Contact
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        Phone:<br></br>
                        +91 9023 620 998
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim3} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Email
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Phone:<br></br>
                        info@videshapna.com
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim4} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Opening Hrs
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Mon - Friday:<br></br>
                        09.00 am to 07.00 pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                LET’S CONNECT
              </span>
              <h2 className="main-h2 text-sky-500 font-p">Send Your Message</h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}
              <div className="col-12 mt-5">
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Name</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Email</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Phone</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Subject</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <p className="text-lg font-p mb-2">Your Message</p>
                      <textarea rows={7} className="form-control custom-form-control2" placeholder="Enter here..."></textarea>
                    </div>
                    <div className="col-12">
                      <button className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}