// import { CheckIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import gg from "../../Images/aa1.gif"

export default function Application() {

  const { t } = useTranslation();
  const { heading1,heading2,p1,subheading1,subp1,subheading2,subp2,subheading3,subp3,subheading4,subp4} = t("Application");

  return (
    <div className="relative bg-white">
      {/* <div className="pattern-layer"><div className="pattern-11"></div><div className="pattern-12"></div></div> */}
      <div className="bg-white">
        <div className="container">
          <div className="row py-24 sm:py-32">
            <div className="col-12">

              <div className=" px-6 lg:px-8">
                <div className="mx-auto max-w-6xl sm:text-center">
                  <h2 className="text-3xl font-bold tracking-tight  text-gray-600 sm:text-6xl">
                    {heading1}
                    <span className="text-sky-500"> {heading2}</span>
                  </h2>
                  <p className="mt-2 text-lg leading-8 text-gray-600">
                    {p1}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="row">
                <div className="col-lg-4 align-self-center">
                  <div className="row">
                    <div className="col-12 mt-3 mb-3">
                      <div className="bb">
                        <h3>
                        {subheading1}
                        </h3>
                        <p>
                        {subp1}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 mt-3 mb-3">
                      <div className="bb">
                        <h3>
                        {subheading2}
                        </h3>
                        <p>
                        {subp2}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mx-auto text-center lg-mt-5  align-self-center">
                  <img className="img-fluid text-center mx-auto " style={{ position: 'relative', zIndex: '11' }} src={gg} alt="" />

                </div>
                <div className="col-lg-4  align-self-center">
                  <div className="row">
                    <div className="col-12 mt-3 mb-3">
                      <div className="bb">
                        <h3>
                        {subheading3}
                        </h3>
                        <p>
                        {subp3}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 mt-3 mb-3">
                      <div className="bb">
                        <h3>
                        {subheading4}
                        </h3>
                        <p>
                        {subp4}

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}
