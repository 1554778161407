import { createSlice } from "@reduxjs/toolkit";
import { createInquiry } from "../action/inquiry-actions";

const inquirySlice = createSlice({
    name: 'inquirySlice',
    initialState: {
        isLoading: false,
        data: null 
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createInquiry.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createInquiry.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = payload;
        });
        builder.addCase(createInquiry.rejected, (state) => {
            state.isLoading = false;
        });
    },
});
export default inquirySlice.reducer;
