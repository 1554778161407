import { createSlice } from '@reduxjs/toolkit';
import { personalInfoUpdate } from '../action/personalInfo-slice-actions';




const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState: { personalInfo: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (info) => [
    info
      .addCase(personalInfoUpdate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(personalInfoUpdate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.personalInfo = action.payload;
      })
      .addCase(personalInfoUpdate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
        ],
});

export default personalInfoSlice.reducer;
