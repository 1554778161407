import { Helmet } from "react-helmet";
import UserProfile from "../../Images/account.png";
import Certification from "../../Images/certification.png";
import Details from "../../Images/details.png";
import { Link } from "react-router-dom";

export default function Tracking() {
  return (
    <>
      <Helmet>
        <title>Tracking - Videsh Apna</title>
      </Helmet>
      <section className="pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="font-p text-3xl text-white font-semibold">
                Good morning, Akshay 😊
              </h2>
              <p className="text-white mt-1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-3 mb-3 d-flex">
              <div className="t-box light-blue">
                <div>
                  <img
                    alt=""
                    src={UserProfile}
                    width={70}
                    className="img-fluid mb-4"
                  />
                </div>
                <h3 className="t-h3 mb-3">External profile</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </p>
                <h5 className="mt-4 t-h5 text-underline">Complited 95%</h5>
              </div>
            </div>
            <div className="col-lg-3 mb-3 d-flex">
              <div className="t-box light-pink">
                <div>
                  <img
                    alt=""
                    src={Certification}
                    width={70}
                    className="img-fluid mb-4"
                  />
                </div>
                <h3 className="t-h3 mb-3">Professional journey</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </p>
                <button className="mt-4 t-h5 btn btn-custom-blue">
                  Fill Details
                </button>
              </div>
            </div>
            <div className="col-lg-3 mb-3 d-flex">
              <div className="t-box light-green">
                <div>
                  <img
                    alt=""
                    src={Details}
                    width={70}
                    className="img-fluid mb-4"
                  />
                </div>
                <h3 className="t-h3 mb-3">Education Details</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </p>
                <button className="mt-4 t-h5 btn btn-custom-green">
                  Fill Details
                </button>
              </div>
            </div>
            <div className="col-lg-3 mb-3 d-flex">
              <div className="t-box w-100  light-gray">
                <h3 className="t-h3 mb-3">Latest update</h3>
                <ul>
                  <li className="bb-underline-1">
                    <div>
                      <p className="text-05lg font-medium text-gray-600">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p>
                      <p className="font-p font-semibold mt-2 text-sm text-gray-400">
                        17 Aug ,2023
                      </p>
                    </div>
                  </li>
                  <li className="bb-underline-1">
                    <div>
                      <p className="text-05lg font-medium text-gray-600">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p>
                      <p className="font-p font-semibold mt-2 text-sm text-gray-400">
                        16 Aug ,2023
                      </p>
                    </div>
                  </li>
                  <li className="bb-underline-1">
                    <div>
                      <p className="text-05lg font-medium text-gray-600">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p>
                      <p className="font-p font-semibold mt-2 text-sm text-gray-400">
                        15 Aug ,2023
                      </p>
                    </div>
                  </li>
                  <li className="">
                    <div>
                      <Link to={"/"} className="mt-4 t-h5 text-underline">View all</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
