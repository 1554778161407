import AbilityInEnglish from "../../customer/Pages/FreeAssessments/AbilityInEnglish"
import BusinessInformation from "../../customer/Pages/FreeAssessments/BusinessInformation"
import EmploymentHistory from "../../customer/Pages/FreeAssessments/EmploymentExperience"
import ManagementExperience from "../../customer/Pages/FreeAssessments/ManagementExperience"
import PersonalDetails from "../../customer/Pages/FreeAssessments/PersonalDetails"
import PersonalNetWorth from "../../customer/Pages/FreeAssessments/PersonalNetWorth"
import StudyVisaOther from "../../customer/Pages/FreeAssessments/StudyVisaOther"
import UploadCV from "../../customer/Pages/FreeAssessments/UploadCV"
import EducationalProfile from "../../customer/Pages/FreeAssessments/education-profile/EducationalProfile"
import EducationalProfileBusinessCandidate from "../../customer/Pages/FreeAssessments/education-profile/EducationalProfileBusinessCandidate"

//icons
import personalInformation from "../../Images/userss.png";
import abilityEnglish from "../../Images/translation.png";
import educationalProfile from "../../Images/mortarboard.png";
import employmentHistory from "../../Images/seo-report.png";
import resume from "../../Images/resume.png";
import netWorth from "../../Images/net-worth.png";
import more from "../../Images/more.png";

export const assessmentFormStepsArrayConfig = ({ onHandleNextStep, preferredCountry, country, visaType, userData, pathname }) => {
    return [
        {
            key: "Personal Information",
            name: "Personal Information",
            icon: personalInformation,
            component: <PersonalDetails handleNext={onHandleNextStep} preferredCountry={preferredCountry} visaType={visaType} pathname={pathname} />,
        },
        {
            key: "Ability in English",
            name: "Ability in English",
            icon: abilityEnglish,
            component: <AbilityInEnglish handleNext={onHandleNextStep} userData={userData} />,
        },
        {
            key: "Educational Profile",
            name: "Educational Profile",
            icon: educationalProfile,
            component: visaType === "Business Candidate" ? <EducationalProfileBusinessCandidate handleNext={onHandleNextStep} visaType={visaType} userData={userData} /> : <EducationalProfile handleNext={onHandleNextStep} userData={userData} visaType={visaType} />,
        },
        {
            key: "Employment History",
            name: "Employment History",
            icon: employmentHistory,
            component: <EmploymentHistory handleNext={onHandleNextStep} userData={userData} visaType={visaType} />,
        },
        {
            key: "Business Information",
            name: "Business Information",
            icon: more,
            component: <BusinessInformation handleNext={onHandleNextStep} country={country} userData={userData} />,
        },
        {
            key: "Management Experience",
            name: "Employment & Business Management Experience(for the past 5 years- start with most recent information)",
            icon: employmentHistory,
            component: <ManagementExperience handleNext={onHandleNextStep} visaType={visaType} userData={userData} />,
        },
        {
            key: "Net Worth",
            name: "What is the breakdown of your Personal Net Worth in CAD$?",
            icon: netWorth,
            component: <PersonalNetWorth handleNext={onHandleNextStep} country={country} visaType={visaType} userData={userData} />,
        },
        {
            key: "Upload CV",
            name: "Upload CV",
            icon: resume,
            component: <UploadCV handleNext={onHandleNextStep} userData={userData} />,
        },
        {
            key: "Other",
            name: "Other",
            icon: more,
            component: <StudyVisaOther handleNext={onHandleNextStep} country={country} userData={userData} />,
        },
    ]
}