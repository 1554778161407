import { createSlice } from "@reduxjs/toolkit";
import { getConnectedUser } from "../action/ConnectedUser-action";

const getConnectedUserSlice = createSlice({
    name: 'connectedUsers',
    initialState: {
      connectedConsultant: [],
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getConnectedUser.fulfilled, (state, action) => {
          state.connectedConsultant = action.payload;
        })
        .addCase(getConnectedUser.rejected, (state, action) => {
          state.connectedConsultant = [];
        });
    },
});

export default getConnectedUserSlice.reducer