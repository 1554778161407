import React, { useState } from "react";
import Radio from "../../../shared/components/form/Radio";
import { noOfFounders, radioLists } from "../../../shared/constants/arrayConfig";
import InputText from "../../../shared/components/form/InputText";
import { useForm } from "react-hook-form";
import { assessmentBusinessInformation } from "../../redux/action/assessments-personal-actions";
import { useDispatch } from "react-redux";
import Select from "../../../shared/components/form/Select";
import { ThreeDots } from "react-loader-spinner";

function BusinessInformation({ handleNext, country, userData }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange"
  });
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBusinessInformation = async (formData) => {  
    setIsSubmitting(true);
    await dispatch(assessmentBusinessInformation({
      userData: {
        userId: userData?.id,
        ...formData
      },
      handleNext
    }));
    setIsSubmitting(false)
  }

  return (
    <form>
      <div className="row">
        <div className="col-lg-6 col-12 mb-4">
          <InputText
            type="text"
            name="companyName"
            label="Company Name"
            register={register}
          />
        </div>
        <div className="col-lg-6 col-12 mb-4">
          <InputText
            type="text"
            name="companyWebsite"
            label="Company Website"
            register={register}
          />
        </div>
        <div className="col-lg-6 col-12 mb-4">
          <Select
            name="noOfFounders"
            label="Number of Founders That Will be participating?"
            options={noOfFounders}
            register={register}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <InputText
            type="url"
            name="linkedInProfile"
            label="Please share a link to your LinkedIn profile"
            errors={errors}
            register={register}
            validationSchema={{
              required: "LinkedIn Profile is required",
            }}
            required
          />
        </div>
        <div className="col-12 mb-4">
          <InputText
            type="text"
            name="currentBusinessActivity"
            label=" What is your current business activity and for how long?"
            register={register}
          />
        </div>
        <div className="col-12 mb-4">
          <Radio name="involvementWithStartupBusiness" label="Do you have any current or past involvement with a start-up business?" radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("involvementWithStartupBusiness", value)}
            register={register}
            validationSchema={{
              required: "Involvement with start up business is required",
            }}
            required />
        </div>
        {watch("involvementWithStartupBusiness") === "Yes" &&
          <div className="col-12 mb-4">
            <InputText
              label="If yes please give details:"
              name="involvementWithStartupBusinessDetails"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Business details is required",
              }}
              required
              isTextarea
            />
          </div>
        }
        <div className="col-12 mb-4">
          <Radio name="appliedStartupVisa" label={`Have you ever applied for a start-up visa in ${country} or any other country?`} radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("appliedStartupVisa", value)}
            register={register} />
        </div>
        {watch("appliedStartupVisa") === "Yes" &&
          <div className="col-12 mb-4">
            <InputText
              label="If yes where and when?"
              name="appliedStartupVisaDetail"
              errors={errors}
              register={register}
              validationSchema={{
                required: "visa details is required",
              }}
              required
              isTextarea
            />
          </div>
        }
        <div className="col-12 mb-4">
          <InputText
            label=" What is your highest level of education? Please give relevant details?"
            name="highestEducation"
            errors={errors}
            register={register}
            validationSchema={{
              required: "highest education is required",
            }}
            required
            isTextarea
          />
        </div>
        <div className="col-12 mb-4">
          <Radio name="intermediateAbilitiesInEnglish" label="Do you have at least intermediate abilities in the English language (speaking, reading, writing and understanding)" radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("intermediateAbilitiesInEnglish", value)}
            register={register} validationSchema={{
              required: "Intermediate abilities english is required",
            }}
            required />
        </div>
        <div className="col-12 mb-4">
          <Radio name="experienceWorkingWith" label="Do you have any experience working with venture capital, angel investors, or incubators/accelerators?" radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("experienceWorkingWith", value)}
            register={register} validationSchema={{
              required: "Experience working is required",
            }}
            required />
        </div>
        {watch("experienceWorkingWith") === "Yes" &&
          <div className="col-12 mb-4">
            <InputText
              label="If yes please give details:"
              name="experienceWorkingWithDetails"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Details is required",
              }}
              required
              isTextarea
            />
          </div>
        }
        <div className="col-12 mb-4">
          <Radio name="specificBusinessConcept" label={`Do you have a specific business concept that you believe could support a project to ${country}?`} radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("specificBusinessConcept", value)}
            register={register} validationSchema={{
              required: "Experience working is required",
            }}
            required />
        </div>
        {watch("specificBusinessConcept") === "Yes" &&
          <div className="col-12 mb-4">
            <InputText
              label="If yes please give details:"
              name="specificBusinessConceptDetails"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Details is required",
              }}
              required
              isTextarea
            />
          </div>
        }
        <div className="col-12 mb-4">
          <Radio name="interestToCollabrate" label="If no, would you have interest to collaborate with our business advisory team to create a viable business project to support a credible project under this program?" radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("interestToCollabrate", value)}
            register={register} validationSchema={{
              required: "Interest to collaborate is required",
            }}
            required />
        </div>
        {/* <div className="col-12 mb-3">
          <p className="text-lg font-p ">
            If you have a pitch deck, business plan, or other documents you'd
            like to share, please upload them here,
          </p>
          <div className="flex gap-1">
            <input
              className="form-control control2"
              type="file"
              name="filename"
            />
          </div>
        </div> */}
        <div className="col-12 text-end">
          <button
            className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
            type="submit"
            onClick={handleSubmit(handleBusinessInformation)}
            disabled={isSubmitting}
          >
           {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Save & Next"} 
          </button>
        </div>
      </div>
    </form>
  );
}

export default BusinessInformation;
