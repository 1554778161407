export const routes = {
    INFORMATION_HUB: "/information-hub",
    ASSESSMENT: "/assessment",
    ASSESSMENTS: "/assessments",
    SCORING: "/scoring",
    SKILLED_WORKER: "/skilled-worker",
    TEMPORARY_POLICIES: "/temporary-policies",
    BUSINESS_STARTUP: "/business-startup",
    STARTUP_VISA: "/startup-visa",
    VISIT_VISE: "/visit-visa",
    STUDENT: "/student",
    SPONSORSHIP: "/sponsorship",
    CHECK_ELIGIBILITY: "/check-eligibility",
    FREE_ASSESSMENT: "/free-assessment",
    EXAMPLE: "/example",
    FORM: "/form",
}