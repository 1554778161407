import { createAsyncThunk } from '@reduxjs/toolkit'
import apiConfig from '../../config/api-config';

const initialUserData = {
    limit: '20',
    offset: '0',
    filter: null,
    sorting: {
        fieldName: 'created_at',
        direction: 'asc',
    },
};

export const getBlogLists = createAsyncThunk("BlogLists", async () => {
    try {
        const response = await apiConfig.post("Blogs/List", initialUserData);
        if (response?.data?.succeeded) {
            return response?.data?.data;
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});

export const getBlogDetails = createAsyncThunk('blogDetails', async (slug) => {
    try {
        const response = await apiConfig.get(`Blogs/${slug}`);
        console.log('response', response?.data?.data)
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
});