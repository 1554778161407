import Error from "../Pages/Error";
import InformationHub from "../Pages/InformationHub";
import Assessment from "../Pages/Assessment";
import Assessments from "../Pages/Assessments";
import Scoring from "../Pages/Scoring";
import SkilledWorker from "../Pages/SkilledWorker";
import TemporaryPolicies from "../Pages/TemporaryPolicies";
import BusinessStartup from "../Pages/BusinessStartup";
import StartupVisa from "../Pages/StartupVisa";
import VisitorVisa from "../Pages/VisitorVisa";
import Student from "../Pages/Student";
import Sponsorship from "../Pages/Sponsorship";
import AssessmentsForm from "../Pages/AssessmentsForm";
import { routes } from "../constants";
import CheckEligibility from "../Pages/CheckEligibility";
import Dashboard from "../../user/components/Dashboard";
import Tracking from "../../user/components/Tracking";
import Document from "../../user/components/Document";
import Payment from "../../user/components/Payment";
import FeedbackForm from "../../user/components/FeedbackForm";
import Notifications from "../../user/components/Notifications";
import ThankYou from "../Pages/ThankYou";

export const consultantRoutes = [

    {
        path: routes.INFORMATION_HUB,
        component: InformationHub,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },

    {
        path: routes.ASSESSMENT,
        component: Assessment,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.ASSESSMENTS,
        component: Assessments,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.SCORING,
        component: Scoring,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.SKILLED_WORKER,
        component: SkilledWorker,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.TEMPORARY_POLICIES,
        component: TemporaryPolicies,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.BUSINESS_STARTUP,
        component: BusinessStartup,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.STARTUP_VISA,
        component: StartupVisa,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.VISIT_VISE,
        component: VisitorVisa,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.STUDENT,
        component: Student,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.SPONSORSHIP,
        component: Sponsorship,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.CHECK_ELIGIBILITY,
        component: CheckEligibility,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.FREE_ASSESSMENT,
        component: AssessmentsForm,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.DASHBOARD,
        component: Dashboard,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.TRACKING,
        component: Tracking,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.DOCUMENT,
        component: Document,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.PAYMENT,
        component: Payment,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.FEEDBACKFORM,
        component: FeedbackForm,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },
    {
        path: routes.NOTIFICATION,
        component: Notifications,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },

    {
        path: routes.THANKYOU,
        component: ThankYou,
        errorElement: <Error />,
        showHeaderAndFooter: true,
    },

];