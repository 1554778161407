import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userGetUserInfo } from "../../customer/redux/slice/userSlice";
import UserGetDocuments from "./UserGetDocuments";
import UserUploadDocuments from "./layout/UserUploadDocuments";

const Document = () => {
  const dispatch = useDispatch(); 

  const users = useSelector((state) => state?.users?.singleUser?.data);
  const userData = useSelector((state) => state?.userData?.authData?.data);
  const education = users?.education?.[0];
  const employmentHistory = users?.employmentHistory?.[0];
  const documents = users?.documents?.[0];

  const [showUploadComponent, setShowUploadComponent] = useState(false);

  useEffect(() => {
    dispatch(userGetUserInfo(userData?.userId));
  }, [dispatch, userData?.userId]);


  return (
    <Container className="mt--7" fluid>
      <Row className="justify-content-center ab">
        <Col className="pt-5 pb-5" xl="10">
          <Card className="border-0">
            <CardBody>
              <Form>
                <div className="documnet-profile">
                  <div className="flex justify-between items-center">
                    <h2 className="text-3xl font-bold tracking-tight text-slate-600 sm:text-4xl mb-3 bg-transparent border-bottom-0">
                      Upload Documents
                    </h2>  
                    <Button
                      className="text-blue-600 mr-2 border-1 text-white bg-blue-500 border-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none font-medium rounded-lg px-4 py-3 inline-flex justify-center items-center"
                      onClick={() => setShowUploadComponent(!showUploadComponent)}
                    >               
                      Upload 
                    </Button>
                  </div>
                </div>        
               
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showUploadComponent && <UserUploadDocuments />}
       <UserGetDocuments />      
    </Container>
  );
};

export default Document;
