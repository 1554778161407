// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
// import Bg1 from "../../Images/bg1.png"
// import Bg2 from "../../Images/bg2.png"
// import Bg3 from "../../Images/bg3.png"
import { useEffect } from "react"
import ss1 from "../../Images/ss1.gif"
import ss2 from "../../Images/ss2.gif"
import ss3 from "../../Images/ss3.gif"
import ss4 from "../../Images/ss4.gif"
import ss5 from "../../Images/ss5.gif"
import ss6 from "../../Images/ss6.gif"
import ss7 from "../../Images/ss7.gif"
import { useTranslation } from "react-i18next"

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const { heading1,
    p1,
    heading2,
    p21,
    p22,
    p3,
    btn1,
    btn2,
    btn3,
    btn4,
    heading3,
    p31,
    p32,
    p4,
    btn5,
    btn6,
    btn7,
    heading4,
    p41,
    p42,
    p5,
    btn8,
    btn9,
    btn10,
    heading5,
    p51,
    p52,
    p6,
    btn11,
    btn12,
    btn13,
    heading6,
    p61,
    p62,
    p7,
    btn14,
    btn15,
    btn16,
    btn17,
    heading7,
    p71,
    p72,
    p8,
    btn18,
    btn19,
    btn20,
    btn21,
    heading8,
    p81,
    p82,
    p9,
    btn22,
    btn23,
    btn24,


  } = t("About");

  return (
    <>
      <div className="relative container-fluid isolate overflow-hidden bg-white px-6 py-24 sm:py-32 pb-5 lg:overflow-visible lg:px-0">
        {/* <div className="absolute inset-0 -z-10 overflow-hidden">
         <img className="img-fluid opacity-[25%]" alt="" src={Bg1} />
        </div> */}
        <div className="row mb-2">
          <div className="col-lg-8 mx-auto text-center">
            <h1 className="text-2xl  tracking-tight text-slate-900 sm:text-6xl main-text">
              <span className="relative whitespace-nowrap text-slate-600">
                {heading1}

                <span className="relative text-sky-500"> VideshApna?</span>
              </span>
            </h1>
            <p className="mt-4 text-xl text-gray-500">
              {p1}
            </p>
          </div>
        </div>

      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center ">
            <div className="lg:pr-8 lg:pt-4 ">
              <div className="lg:max-w-xl">
                {/* <h2 className="text-base font-semibold leading-7 text-sky-500">
                  Lorem ipsum
                </h2> */}
                <h5 className="grdHdng">{heading2}</h5>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl tt-shadow">
                  <span className="text-sky-500">{p21}</span> {p22}
                </p>
                <p className="mt-3 mb-3 text-lg leading-8 text-gray-600">
                  {p3}
                </p>
                <ul className="nav navbar ul-line navbar-left d-flex d-inline-flex ">
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn1}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn2}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn3}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn4}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6  align-self-center mt-5 mt-lg-0 text-center">
            <img
              src={ss1}
              alt="Product screenshot"
              className="text-center mx-auto rounded-xl left-20 ring-gray-400/10 img-fluid"

            />

          </div>
        </div>
      </div>
      <div className=" container">
        {/* <div className="absolute inset-0 -z-10 overflow-hidden">
        <img className="img-fluid opacity-[25%]" alt="" src={Bg2} />
        </div> */}

        <div className="row">
          <div className="col-lg-6 align-self-center order-lg-last">
            <div className="lg:pr-8 lg:pt-4 ">
              <div className="lg:max-w-xl">
                {/* <h2 className="text-base font-semibold leading-7 text-sky-500">
                  Lorem ipsum
                </h2> */}
                <h5 className="grdHdng">{heading3}</h5>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl  tt-shadow">
                  <span className="text-sky-500">{p31}</span> {p32}
                </p>
                <p className="mt-3 mb-3 text-lg leading-8 text-gray-600">
                  {p4}
                </p>
                <ul className="nav navbar ul-line navbar-left d-flex d-inline-flex ">
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn5}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn6}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn7}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6   align-self-center mt-5  text-center order-lg-first mx-auto">
            <img
              src={ss2}
              alt="Product screenshot"
              className="w-100 img-fluid text-center mx-auto relative "
            />
          </div>
        </div>
      </div>
      <div className=" container">


        <div className="row">
          <div className="col-lg-6 align-self-center ">
            <div className="lg:pr-6 lg:pt-4 ">
              <div className="lg:max-w-xl">
                {/* <h2 className="text-base font-semibold leading-7 text-sky-500">
                  Lorem ipsum
                </h2> */}
                <h5 className="grdHdng">{heading4}</h5>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl  tt-shadow">
                  <span className="text-sky-500">{p41}</span> {p42}
                </p>
                <p className="mt-3 mb-3 text-lg leading-8 text-gray-600">
                  {p5}
                </p>
                <ul className="nav navbar ul-line navbar-left d-flex d-inline-flex ">
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn8}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn9}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn10}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6  align-self-center mt-5 mt-lg-0 text-center">
            <img
              src={ss3}
              alt="Product screenshot"
              className="img-fluid w-100 left-20 ring-gray-400/10 mx-auto text-center"
            />
          </div>
        </div>
      </div>

      <div className=" container">

        <div className="row">
          <div className="col-lg-6  align-self-center order-lg-last">
            <div className="lg:pr-8 lg:pt-4 ">
              <div className="lg:max-w-xl">
                {/* <h2 className="text-base font-semibold leading-7 text-sky-500">
                  Lorem ipsum
                </h2> */}
                <h5 className="grdHdng">{heading5}</h5>

                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl  tt-shadow">
                  <span className="text-sky-500">{p51}</span> {p52}
                </p>
                <p className="mt-3 mb-3 text-lg leading-8 text-gray-600">
                  {p6}
                </p>
                <ul className="nav navbar ul-line navbar-left d-flex d-inline-flex ">
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn11}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn12}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn13}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 align-self-center mt-5 mt-lg-0 text-center order-lg-first">
            <img
              src={ss4}
              alt="Product screenshot"
              className="w-100 img-fluid max-w-none mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="container">


        <div className="row">
          <div className="col-lg-6 align-self-center ">
            <div className="lg:pr-8 lg:pt-4 ">
              <div className="lg:max-w-xl">
                {/* <h2 className="text-base font-semibold leading-7 text-sky-500">
                  Lorem ipsum
                </h2> */}
                <h5 className="grdHdng">{heading6}</h5>

                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl  tt-shadow">
                  <span className="text-sky-500">{p61}</span> {p62}
                </p>
                <p className="mt-3 mb-3 text-lg leading-8 text-gray-600">
                  {p7}
                </p>
                <ul className="nav navbar ul-line navbar-left d-flex d-inline-flex ">
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn14}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn15}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn16}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn17}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 align-self-center mt-5 mt-lg-0 text-center">
            <img
              src={ss5}
              alt="Product screenshot"
              className="max-w-none text-center w-100 mx-auto"
            />
          </div>
        </div>
      </div>

      <div className=" container">


        <div className="row">
          <div className="col-lg-6  align-self-center order-lg-last">
            <div className="lg:pr-8 lg:pt-4 ">
              <div className="lg:max-w-[100%]">
                {/* <h2 className="text-base font-semibold leading-7 text-sky-500">
                  Lorem ipsum
                </h2> */}
                <h5 className="grdHdng">{heading7}</h5>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl  tt-shadow">
                  <span className="text-sky-500">{p71}</span> {p72}
                </p>
                <p className="mt-3 mb-3 text-lg leading-8 text-gray-600">
                 {p8}
                </p>
                <ul className="nav navbar ul-line navbar-left d-flex d-inline-flex ">
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                     {btn18}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn19}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn20}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn21}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 align-self-center mt-5 mt-lg-0 text-center order-lg-first">
            <img
              src={ss6}
              alt="Product screenshot"
              className=" img-fluid w-100 max-w-none mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="container">


        <div className="row">
          <div className="col-lg-6 align-self-center ">
            <div className="lg:pr-8 lg:pt-4 ">
              <div className="lg:max-w-[100%]">
                {/* <h2 className="text-base font-semibold leading-7 text-sky-500">
                  Lorem ipsum
                </h2> */}
                <h5 className="grdHdng">{heading8}</h5>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl  tt-shadow">
                  <span className="text-sky-500">{p81} </span>{p82}
                </p>
                <p className="mt-3 mb-3 text-lg leading-8 text-gray-600">
                 {p9}
                </p>
                <ul className="nav navbar ul-line navbar-left d-flex d-inline-flex ">
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn22}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn23}
                    </p>
                  </li>
                  <li className="nav-item d-inline-flex  align-items-center mr-2 mb-2">
                    <p className="nav-link d-inline-flex">
                      {btn24}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 align-self-center mt-5 mt-lg-0 text-center">
            <img
              src={ss7}
              alt="Product screenshot"
              className=" max-w-none img-fluid left-20 ring-gray-400/10 w-100 mx-auto"

            />
          </div>
        </div>
      </div>
      {/* 
<div className="relative isolate container-fluid overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
    
<div className="row">
      
      <div className=" col-lg-6 align-self-center mt-5 mt-lg-0 text-center">
       
      <img src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png" alt="Product screenshot" className="w-[48rem] max-w-none rounded-xl shadow-xl left-20 left-gray-400/10 md:-mr-4 lg:-mr-0"/>

      </div>
      <div className="col-lg-4 offset-lg-1 align-self-center ">
      <div className="lg:pr-8 lg:pt-4 ">
            <div className="lg:max-w-xl">
              <h2 className="text-base font-semibold leading-7 text-sky-500">Lorem ipsum</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-600 sm:text-6xl">Full transparency</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
                iste dolor cupiditate blanditiis ratione.
              </p>
            
            </div>
          </div>
      </div> 
    </div>
        <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
    </div> */}
    </>
  );
}
