import React from "react";
import "react-step-progress/dist/index.css";
import { Helmet } from "react-helmet";
import { ChevronLeftIcon, ChevronRightIcon, PhotoIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Radio } from "@material-tailwind/react";
import Im1 from "../../Images/userss.png";
import Im2 from "../../Images/translation.png";
import Im3 from "../../Images/mortarboard.png";
import Im4 from "../../Images/seo-report.png";
import Im5 from "../../Images/more.png";
import Im6 from "../../Images/resume.png";
import Im8 from "../../Images/survey.png";
import ss8 from "../../Images/ss8.gif";
import Iim1 from "../../Images/meeting-point.png";
import Iim2 from "../../Images/operator.png";
import Iim3 from "../../Images/email.png";
import Iim4 from "../../Images/hourglass.png";

export default function BusinessStartup() {
  return (
    <>
      <Helmet>
        <title>Business Start-Up - Videsh Apna</title>
      </Helmet>
      <div className="bg-stone-100">
        <div className="relative  px-6 pt-14 pb-14 lg:px-8">
          <div className="mx-auto max-w-[100%] py-20 sm:py-12 lg:py-12">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-500 sm:text-6xl font-p mb-2">
                Free Assessment Forms for <span className="text-sky-500">Business Start-Up</span>
              </h1>
              <p className="text-lg leading-8 text-zinc-600 font-p">
                Express Entry is a simplified, fast and efficient immigration selection system offering permanent<br className="br"></br> residence in 6-months to qualified Business Start-Up.
              </p>

            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky-100">
        <div className="relative container px-6 pt-5 pb-5 lg:px-5">
          <div className="mx-auto max-w-[100%] ">
            <div className="text-left d-flex text-lg leading-8 text-zinc-600 font-p  align-items-center">
              <img className="img-fluid mr-2" src={Im8}></img>
              Complete the following questionnaire and receive in 1-2 days a free assessment of your qualifications for Canadian permanent residence under the Express Entry System, the Quebec Immigration Program and Provincial Nominee Programs.
            </div>
          </div>
        </div>
      </div>
      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-12"><span className="sub-title text-sky-900 font-p">QUICK CHECK</span><h2 className="main-h2 text-sky-500 font-p">Free Assessments</h2>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-12">
                  <Accordion defaultActiveKey="0" flush className="newaccordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header> <img className="img-fluid mr-2 ww-45" src={Im1}></img> Personal Information</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Name *</p>
                            <input className="form-control custom-form-control2" placeholder="Enter here..." />
                          </div>
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Email *</p>
                            <input className="form-control custom-form-control2" placeholder="Enter here..." />
                          </div>

                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Phone *</p>
                            <input className="form-control custom-form-control2" placeholder="Enter here..." />
                          </div>
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Country of citizenship: *</p>
                            <select className="form-control custom-form-control2">
                              <option value="">Please Select</option>
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="Brunei Darussalam">Brunei Darussalam</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">Cayman Islands</option>
                              <option value="Central African Republic">Central African Republic</option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
                              <option value="Congo, Republic of the">Congo, Republic of the</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">Czech Republic</option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">Dominican Republic</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">Equatorial Guinea</option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Faroe Islands">Faroe Islands</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">French Guiana</option>
                              <option value="French Polynesia">French Polynesia</option>
                              <option value="French Southern Territories">French Southern Territories</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">Guinea-Bissau</option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Ivory Coast">Ivory Coast</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Kosovo">Kosovo</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao">Lao</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                              <option value="Liechtenstein">Liechtenstein</option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">Marshall Islands</option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                              <option value="Moldova, Republic of">Moldova, Republic of</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">Netherlands Antilles</option>
                              <option value="New Caledonia">New Caledonia</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="North Korea">North Korea</option>
                              <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestine">Palestine</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">Papua New Guinea</option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">Russian Federation</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">Solomon Islands</option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Korea">South Korea</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="St. Helena">St. Helena</option>
                              <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">United Arab Emirates</option>
                              <option value="United Kingdom">United Kingdom</option>
                              <option value="United States">United States</option>
                              <option value="United States minor outlying islands">United States minor outlying islands</option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                              <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Country of residence: *</p>
                            <select className="form-control custom-form-control2">
                              <option value="">Please Select</option>
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="Brunei Darussalam">Brunei Darussalam</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">Cayman Islands</option>
                              <option value="Central African Republic">Central African Republic</option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
                              <option value="Congo, Republic of the">Congo, Republic of the</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">Czech Republic</option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">Dominican Republic</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">Equatorial Guinea</option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Faroe Islands">Faroe Islands</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">French Guiana</option>
                              <option value="French Polynesia">French Polynesia</option>
                              <option value="French Southern Territories">French Southern Territories</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">Guinea-Bissau</option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Ivory Coast">Ivory Coast</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Kosovo">Kosovo</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao">Lao</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                              <option value="Liechtenstein">Liechtenstein</option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">Marshall Islands</option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                              <option value="Moldova, Republic of">Moldova, Republic of</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">Netherlands Antilles</option>
                              <option value="New Caledonia">New Caledonia</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="North Korea">North Korea</option>
                              <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestine">Palestine</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">Papua New Guinea</option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">Russian Federation</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">Solomon Islands</option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Korea">South Korea</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="St. Helena">St. Helena</option>
                              <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">United Arab Emirates</option>
                              <option value="United Kingdom">United Kingdom</option>
                              <option value="United States">United States</option>
                              <option value="United States minor outlying islands">United States minor outlying islands</option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                              <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Where your choice relocation: *</p>
                            <select className="form-control custom-form-control2">
                              <option value="">Please Select</option>
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="Brunei Darussalam">Brunei Darussalam</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">Cayman Islands</option>
                              <option value="Central African Republic">Central African Republic</option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
                              <option value="Congo, Republic of the">Congo, Republic of the</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">Czech Republic</option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">Dominican Republic</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">Equatorial Guinea</option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Faroe Islands">Faroe Islands</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">French Guiana</option>
                              <option value="French Polynesia">French Polynesia</option>
                              <option value="French Southern Territories">French Southern Territories</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">Guinea-Bissau</option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Ivory Coast">Ivory Coast</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Kosovo">Kosovo</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao">Lao</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                              <option value="Liechtenstein">Liechtenstein</option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">Marshall Islands</option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                              <option value="Moldova, Republic of">Moldova, Republic of</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">Netherlands Antilles</option>
                              <option value="New Caledonia">New Caledonia</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="North Korea">North Korea</option>
                              <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestine">Palestine</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">Papua New Guinea</option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">Russian Federation</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">Solomon Islands</option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Korea">South Korea</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="St. Helena">St. Helena</option>
                              <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">United Arab Emirates</option>
                              <option value="United Kingdom">United Kingdom</option>
                              <option value="United States">United States</option>
                              <option value="United States minor outlying islands">United States minor outlying islands</option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                              <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Age: *</p>
                            <select className="form-control custom-form-control2">
                              <option value="">Please Select</option>
                              <option value="17 or under">17 or under</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                              <option value="32">32</option>
                              <option value="33">33</option>
                              <option value="34">34</option>
                              <option value="35">35</option>
                              <option value="36">36</option>
                              <option value="37">37</option>
                              <option value="38">38</option>
                              <option value="39">39</option>
                              <option value="40">40</option>
                              <option value="41">41</option>
                              <option value="42">42</option>
                              <option value="43">43</option>
                              <option value="44">44</option>
                              <option value="45">45</option>
                              <option value="46">46</option>
                              <option value="47">47</option>
                              <option value="48">48</option>
                              <option value="49">49</option>
                              <option value="50">50</option>
                              <option value="51 and older">51 and older</option>
                            </select>
                          </div>
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Gender *</p>
                            <select className="form-control custom-form-control2">
                              <option value="">Please Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                          <div className="col-lg-4 mb-4">
                            <p className="text-lg font-p mb-2">Marital Status: *</p>
                            <select className="form-control custom-form-control2">
                              <option value="">Please Select</option>
                              <option value="Single">Single (or divorced)</option>
                              <option value="Married">Married/Common-Law Partnership</option>
                            </select>
                          </div>
                        </div>


                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header> <img className="img-fluid mr-2 ww-45" src={Im2}></img> Ability in English and French</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">English Read:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>
                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">English Write:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>
                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">English Speak:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>

                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">English Listen:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>








                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">French Read:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>
                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">French Write:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>
                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">French Speak:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>

                              <div className="col-lg-12 mb-4">
                                <p className="text-lg font-p mb-2">French Listen:</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="High">High</option>
                                  <option value="Moderate">Moderate</option>
                                  <option value="Basic">Basic</option>
                                  <option value="Not at All">Not at All</option>
                                </select>
                              </div>








                            </div>
                          </div>


                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header> <img className="img-fluid mr-2 ww-45" src={Im3}></img> Educational Profile</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <p className="text-lg font-p ">Have you graduated high school: *</p>
                            <div className="flex gap-1">
                              <Radio name="type" label="Yes" />
                              <Radio name="type" label="No" />
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <p className="text-lg font-p ">Have you received any post-secondary education or training: *</p>
                            <div className="flex gap-1">
                              <Radio name="type" label="Yes" />
                              <Radio name="type" label="No" />
                            </div>
                          </div>

                          <div className="col-lg-12 mb-3">
                            <p className="text-lg font-p mb-3">Total Years of education (Including Primary, Secondary, and Post Secondary): *</p>
                            <input className="form-control custom-form-control2 lg:w-[30%]" placeholder="Enter here..." />
                          </div>
                          <div className="col-12 mb-4">
                            <p>Post-secondary education is known as tertiary education, or the level that follows the successful completion of secondary education, which is also referred to as high school. Post-secondary education includes universities, colleges, as well as trade and vocational schools.
                            </p>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header> <img className="img-fluid mr-2 ww-45" src={Im4}></img> Employment History</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <p className="text-lg font-p ">Have you had paid work experience in the last 10 years: *</p>
                            <div className="flex gap-1">
                              <Radio name="type" label="Yes" />
                              <Radio name="type" label="No" />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header> <img className="img-fluid mr-2 ww-45" src={Im5}></img> Other</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <p className="text-lg font-p ">Have you submitted a profile in the Federal Express Entry pool:</p>
                            <div className="flex gap-1">
                              <Radio name="type" label="Yes" />
                              <Radio name="type" label="No" />
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <p className="text-lg font-p ">Did you sit an English and/or French test:</p>
                            <div className="flex gap-1">
                              <Radio name="type" label="Yes" />
                              <Radio name="type" label="No" />
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <p className="text-lg font-p ">Do you (or your spouse, if applicable) have family members or relatives who are over 18 and residing in Canada?</p>
                            <div className="flex gap-1">
                              <Radio name="type" label="Yes" />
                              <Radio name="type" label="No" />
                            </div>
                          </div>

                          <div className="col-12 mb-3">
                            <p className="text-lg font-p ">Do you have friends residing in Canada?</p>
                            <div className="flex gap-1">
                              <Radio name="type" label="Yes" />
                              <Radio name="type" label="No" />
                            </div>
                          </div>
                          <div className="col-12 mb-3">

                            <div className="row">
                              <div className="col-lg-4 align-self-center mb-3">
                                <p className="text-lg font-p ">Personal Net worth: *</p>
                                <small>the net worth is considered for some immigration programs</small>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <p className="text-lg font-p mb-2">Currency</p>
                                <select className="form-control custom-form-control2">
                                  <option value="">Please Select</option>
                                  <option value="AFN">Afghan afghani (AFN)</option>
                                  <option value="ALL">Albanian lek (ALL)</option>
                                  <option value="DZD">Algerian dinar (DZD)</option>
                                  <option value="AOA">Angolan kwanza (AOA)</option>
                                  <option value="ARS">Argentine peso (ARS)</option>
                                  <option value="AMD">Armenian dram (AMD)</option>
                                  <option value="AWG">Aruban florin (AWG)</option>
                                  <option value="AUD">Australian dollar (AUD)</option>
                                  <option value="AZN">Azerbaijani manat (AZN)</option>
                                  <option value="BSD">Bahamian dollar (BSD)</option>
                                  <option value="BHD">Bahraini dinar (BHD)</option>
                                  <option value="BDT">Bangladeshi taka (BDT)</option>
                                  <option value="BBD">Barbadian dollar (BBD)</option>
                                  <option value="BYN">Belarusian ruble (BYN)</option>
                                  <option value="BZD">Belize dollar (BZD)</option>
                                  <option value="BMD">Bermudian dollar (BMD)</option>
                                  <option value="BTN">Bhutanese ngultrum (BTN)</option>
                                  <option value="BOB">Bolivian boliviano (BOB)</option>
                                  <option value="BAM">Bosnia and Herzegovina convertible mark (BAM)</option>
                                  <option value="BWP">Botswana pula (BWP)</option>
                                  <option value="BRL">Brazilian real (BRL)</option>
                                  <option value="BND">Brunei dollar (BND)</option>
                                  <option value="BGN">Bulgarian lev (BGN)</option>
                                  <option value="MMK">Burmese kyat (MMK)</option>
                                  <option value="BIF">Burundian franc (BIF)</option>
                                  <option value="KHR">Cambodian riel (KHR)</option>
                                  <option value="CAD">Canadian dollar (CAD)</option>
                                  <option value="CVE">Cape Verdean escudo (CVE)</option>
                                  <option value="KYD">Cayman Islands dollar (KYD)</option>
                                  <option value="XAF">Central African CFA franc (XAF)</option>
                                  <option value="XPF">CFP franc (XPF)</option>
                                  <option value="CLP">Chilean peso (CLP)</option>
                                  <option value="COP">Colombian peso (COP)</option>
                                  <option value="KMF">Comorian franc (KMF)</option>
                                  <option value="CDF">Congolese franc (CDF)</option>
                                  <option value="CRC">Costa Rican colón (CRC)</option>
                                  <option value="CUP">Cuban peso (CUP)</option>
                                  <option value="CZK">Czech koruna (CZK)</option>
                                  <option value="DKK">Danish krone (DKK)</option>
                                  <option value="DJF">Djiboutian franc (DJF)</option>
                                  <option value="DOP">Dominican peso (DOP)</option>
                                  <option value="XCD">Eastern Caribbean dollar (XCD)</option>
                                  <option value="EGP">Egyptian pound (EGP)</option>
                                  <option value="ERN">Eritrean nakfa (ERN)</option>
                                  <option value="ETB">Ethiopian birr (ETB)</option>
                                  <option value="EUR">Euro (EUR)</option>
                                  <option value="FKP">Falkland Islands pound (FKP)</option>
                                  <option value="FJD">Fijian dollar (FJD)</option>
                                  <option value="GMD">Gambian dalasi (GMD)</option>
                                  <option value="GEL">Georgian lari (GEL)</option>
                                  <option value="GHS">Ghanaian cedi (GHS)</option>
                                  <option value="GIP">Gibraltar pound (GIP)</option>
                                  <option value="GTQ">Guatemalan quetzal (GTQ)</option>
                                  <option value="GNF">Guinean franc (GNF)</option>
                                  <option value="GYD">Guyanese dollar (GYD)</option>
                                  <option value="HTG">Haitian gourde (HTG)</option>
                                  <option value="HNL">Honduran lempira (HNL)</option>
                                  <option value="HKD">Hong Kong dollar (HKD)</option>
                                  <option value="HUF">Hungarian forint (HUF)</option>
                                  <option value="ISK">Icelandic króna (ISK)</option>
                                  <option value="INR">Indian rupee (INR)</option>
                                  <option value="IDR">Indonesian rupiah (IDR)</option>
                                  <option value="IRR">Iranian rial (IRR)</option>
                                  <option value="IQD">Iraqi dinar (IQD)</option>
                                  <option value="ILS">Israeli new shekel (ILS)</option>
                                  <option value="JMD">Jamaican dollar (JMD)</option>
                                  <option value="JPY">Japanese yen (JPY)</option>
                                  <option value="JOD">Jordanian dinar (JOD)</option>
                                  <option value="KZT">Kazakhstani tenge (KZT)</option>
                                  <option value="KES">Kenyan shilling (KES)</option>
                                  <option value="KWD">Kuwaiti dinar (KWD)</option>
                                  <option value="KGS">Kyrgyz som (KGS)</option>
                                  <option value="LAK">Lao kip (LAK)</option>
                                  <option value="LBP">Lebanese pound (LBP)</option>
                                  <option value="LSL">Lesotho loti (LSL)</option>
                                  <option value="LRD">Liberian dollar (LRD)</option>
                                  <option value="LYD">Libyan dinar (LYD)</option>
                                  <option value="MOP">Macanese pataca (MOP)</option>
                                  <option value="MKD">Macedonian denar (MKD)</option>
                                  <option value="MGA">Malagasy ariary (MGA)</option>
                                  <option value="MWK">Malawian kwacha (MWK)</option>
                                  <option value="MYR">Malaysian ringgit (MYR)</option>
                                  <option value="MVR">Maldivian rufiyaa (MVR)</option>
                                  <option value="MUR">Mauritian rupee (MUR)</option>
                                  <option value="MXN">Mexican peso (MXN)</option>
                                  <option value="MDL">Moldovan leu (MDL)</option>
                                  <option value="MNT">Mongolian tögrög (MNT)</option>
                                  <option value="MAD">Moroccan dirham (MAD)</option>
                                  <option value="MZN">Mozambican metical (MZN)</option>
                                  <option value="NAD">Namibian dollar (NAD)</option>
                                  <option value="NPR">Nepalese rupee (NPR)</option>
                                  <option value="ANG">Netherlands Antillean guilder (ANG)</option>
                                  <option value="TWD">New Taiwan dollar (TWD)</option>
                                  <option value="NZD">New Zealand dollar (NZD)</option>
                                  <option value="NIO">Nicaraguan córdoba (NIO)</option>
                                  <option value="NGN">Nigerian naira (NGN)</option>
                                  <option value="KPW">North Korean won (KPW)</option>
                                  <option value="NOK">Norwegian krone (NOK)</option>
                                  <option value="OMR">Omani rial (OMR)</option>
                                  <option value="PKR">Pakistani rupee (PKR)</option>
                                  <option value="PAB">Panamanian balboa (PAB)</option>
                                  <option value="PGK">Papua New Guinean kina (PGK)</option>
                                  <option value="PYG">Paraguayan guaraní (PYG)</option>
                                  <option value="PEN">Peruvian sol (PEN)</option>
                                  <option value="PHP">Philippine peso (PHP)</option>
                                  <option value="PLN">Polish złoty (PLN)</option>
                                  <option value="QAR">Qatari riyal (QAR)</option>
                                  <option value="CNY">Renminbi (CNY)</option>
                                  <option value="RON">Romanian leu (RON)</option>
                                  <option value="RUB">Russian ruble (RUB)</option>
                                  <option value="RWF">Rwandan franc (RWF)</option>
                                  <option value="SHP">Saint Helena pound (SHP)</option>
                                  <option value="WST">Samoan tālā (WST)</option>
                                  <option value="SVC">São Tomé and Príncipe dobra (SVC)</option>
                                  <option value="SAR">Saudi riyal (SAR)</option>
                                  <option value="RSD">Serbian dinar (RSD)</option>
                                  <option value="SCR">Seychellois rupee (SCR)</option>
                                  <option value="SLE">Sierra Leonean leone (SLE)</option>
                                  <option value="SLL">Sierra Leonean leone (SLL)</option>
                                  <option value="SGD">Singapore dollar (SGD)</option>
                                  <option value="SBD">Solomon Islands dollar (SBD)</option>
                                  <option value="SOS">Somali shilling (SOS)</option>
                                  <option value="ZAR">South African rand (ZAR)</option>
                                  <option value="KRW">South Korean won (KRW)</option>
                                  <option value="STD">South Sudanese pound (STD)</option>
                                  <option value="LKR">Sri Lankan rupee (LKR)</option>
                                  <option value="GBP">Sterling (GBP)</option>
                                  <option value="SDG">Sudanese pound (SDG)</option>
                                  <option value="SRD">Surinamese dollar (SRD)</option>
                                  <option value="SZL">Swazi lilangeni (SZL)</option>
                                  <option value="SEK">Swedish krona (SEK)</option>
                                  <option value="CHF">Swiss franc (CHF)</option>
                                  <option value="SYP">Syrian pound (SYP)</option>
                                  <option value="TJS">Tajikistani somoni (TJS)</option>
                                  <option value="TZS">Tanzanian shilling (TZS)</option>
                                  <option value="THB">Thai baht (THB)</option>
                                  <option value="TOP">Tongan paʻanga (TOP)</option>
                                  <option value="TTD">Trinidad and Tobago dollar (TTD)</option>
                                  <option value="TND">Tunisian dinar (TND)</option>
                                  <option value="TRY">Turkish lira (TRY)</option>
                                  <option value="TMT">Turkmenistani manat (TMT)</option>
                                  <option value="UGX">Ugandan shilling (UGX)</option>
                                  <option value="UAH">Ukrainian hryvnia (UAH)</option>
                                  <option value="AED">United Arab Emirates dirham (AED)</option>
                                  <option value="USD">United States dollar (USD)</option>
                                  <option value="UYU">Uruguayan peso (UYU)</option>
                                  <option value="UZS">Uzbekistani sum (UZS)</option>
                                  <option value="VUV">Vanuatu vatu (VUV)</option>
                                  <option value="VEF">Venezuelan digital bolívar (VEF)</option>
                                  <option value="VES">Venezuelan sovereign bolívar (VES)</option>
                                  <option value="VND">Vietnamese đồng (VND)</option>
                                  <option value="XOF">West African CFA franc (XOF)</option>
                                  <option value="YER">Yemeni rial (YER)</option>
                                  <option value="ZMW">Zambian kwacha (ZMW)</option>
                                </select>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <p className="text-lg font-p mb-2">Amount</p>
                                <select className="form-control custom-form-control2">
                                  <option data-currency-value="[]" data-original-label="Please Select" value="">Please Select</option>
                                  <option data-currency-value="[4999]" data-original-label="Less than 4,999" value="Less than 4,999">Less than 4,999</option>
                                  <option data-currency-value="[5000,9999]" data-original-label="5,000 - 9,999" value="5,000 - 9,999">5,000 - 9,999</option>
                                  <option data-currency-value="[10000,15000]" data-original-label="10,000 - 15,000" value="10,000 - 15,000">10,000 - 15,000</option>
                                  <option data-currency-value="[15000,20000]" data-original-label="15,000 - 20,000" value="15,000 - 20,000">15,000 - 20,000</option>
                                  <option data-currency-value="[20000,29999]" data-original-label="20,000 - 29,999" value="20,000 - 29,999">20,000 - 29,999</option>
                                  <option data-currency-value="[30000,39999]" data-original-label="30,000 - 39,999" value="30,000 - 39,999">30,000 - 39,999</option>
                                  <option data-currency-value="[40000,49999]" data-original-label="40,000 - 49,999" value="40,000 - 49,999">40,000 - 49,999</option>
                                  <option data-currency-value="[50000,59999]" data-original-label="50,000 - 59,999" value="50,000 - 59,999">50,000 - 59,999</option>
                                  <option data-currency-value="[60000,69999]" data-original-label="60,000 - 69,999" value="60,000 - 69,999">60,000 - 69,999</option>
                                  <option data-currency-value="[70000,79999]" data-original-label="70,000 - 79,999" value="70,000 - 79,999">70,000 - 79,999</option>
                                  <option data-currency-value="[80000,89999]" data-original-label="80,000 - 89,999" value="80,000 - 89,999">80,000 - 89,999</option>
                                  <option data-currency-value="[90000,99999]" data-original-label="90,000 - 99,999" value="90,000 - 99,999">90,000 - 99,999</option>
                                  <option data-currency-value="[100000,199999]" data-original-label="100,000 - 199,999" value="100,000 - 199,999">100,000 - 199,999</option>
                                  <option data-currency-value="[200000,299999]" data-original-label="200,000 - 299,999" value="200,000 - 299,999">200,000 - 299,999</option>
                                  <option data-currency-value="[300000,399999]" data-original-label="300,000 - 399,999" value="300,000 - 399,999">300,000 - 399,999</option>
                                  <option data-currency-value="[400000,499999]" data-original-label="400,000 - 499,999" value="400,000 - 499,999">400,000 - 499,999</option>
                                  <option data-currency-value="[500000,599999]" data-original-label="500,000 - 599,999" value="500,000 - 599,999">500,000 - 599,999</option>
                                  <option data-currency-value="[600000,699999]" data-original-label="600,000 - 699,999" value="600,000 - 699,999">600,000 - 699,999</option>
                                  <option data-currency-value="[700000,799999]" data-original-label="700,000 - 799,999" value="700,000 - 799,999">700,000 - 799,999</option>
                                  <option data-currency-value="[800000,899999]" data-original-label="800,000 - 899,999" value="800,000 - 899,999">800,000 - 899,999</option>
                                  <option data-currency-value="[900000,999999]" data-original-label="900,000 - 999,999" value="900,000 - 999,999">900,000 - 999,999</option>
                                  <option data-currency-value="[1000000,1500000]" data-original-label="1,000,000 - 1,500,000" value="1,000,000 - 1,500,000">1,000,000 - 1,500,000</option>
                                  <option data-currency-value="[2000000,2500000]" data-original-label="2,000,000 - 2,500,000" value="2,000,000 - 2,500,000">2,000,000 - 2,500,000</option>
                                  <option data-currency-value="[3000000,3500000]" data-original-label="3,000,000 - 3,500,000" value="3,000,000 - 3,500,000">3,000,000 - 3,500,000</option>
                                  <option data-currency-value="[4000000,4500000]" data-original-label="4,000,000 - 4,500,000" value="4,000,000 - 4,500,000">4,000,000 - 4,500,000</option>

                                  <option data-currency-value="[5000000]" data-original-label="More than 5,000,000" value="More than 5,000,000">More than 5,000,000</option>
                                </select>
                              </div>
                              <div className="col-12 mb-3">
                                <p className="text-lg font-p ">Have you (or your spouse or common-law partner or any of your dependent children) ever had a serious medical condition? *</p>
                                <div className="flex gap-1">
                                  <Radio name="type" label="Yes" />
                                  <Radio name="type" label="No" />
                                </div>
                              </div>

                              <div className="col-12 mb-3">
                                <p className="text-lg font-p ">Have you (or your spouse or common-law partner or any of your dependent children) ever had a criminal record (arrested, charged or convicted)? *</p>
                                <div className="flex gap-1">
                                  <Radio name="type" label="Yes" />
                                  <Radio name="type" label="No" />
                                </div>
                              </div>

                              <div className="col-12 mb-3">
                                <p className="text-lg font-p ">Have you or your spouse previously applied for a visa to Canada?</p>
                                <div className="flex gap-1">
                                  <Radio name="type" label="Yes" />
                                  <Radio name="type" label="No" />
                                </div>
                              </div>


                              <div className="col-12 mb-3">
                                <p className="text-lg font-p mb-2">How did you find out about us:</p>
                                <select className="form-control custom-form-control2 lg:w-[30%]">
                                  <option value="" data-target-class="website_source">Please select</option>
                                  <option value="Search Engine" data-target-class="website_source" data-toggle-hidden="website_source__search_keyword">Search Engine</option>
                                  <option value="Newspaper or Magazine" data-target-class="website_source" data-toggle-hidden="website_source__newspaper">Newspaper or Magazine</option>
                                  <option value="Newsgroup" data-target-class="website_source" data-toggle-hidden="website_source__newsgroup">Newsgroup</option>
                                  <option value="General Recommendation" data-target-class="website_source" data-toggle-hidden="website_source__recommendation">General Recommendation</option>

                                </select>
                              </div>


                              <div className="col-12 mb-3">
                                <p className="text-lg font-p mb-2">Additional Comments if Any:</p>
                                <textarea className="form-control custom-form-control2" rows={5} placeholder="Enter here..."></textarea>
                              </div>

                            </div>

                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="5">
                      <Accordion.Header> <img className="img-fluid mr-2 ww-45" src={Im6}></img> Upload CV
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                              <div className="text-center">
                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                <div className=" flex text-sm leading-6 text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                  >
                                    <span>Upload a file</span>
                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                  </label>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>


                  </Accordion>
                </div>
                <div className="col-12 mt-3">
                  <div className="row">

                    <div className="col-lg-12 align-self-center mt-3">
                      <button className="lg:w-[20%] rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600 text-uppercase">Submit Form</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="main-section bg-[#f0f9ff]">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h2 className="main-h2 text-sky-500 font-p">Immigration Telephone Consultation
              </h2>
              <p className="mt-3 text-lg leading-8 text-zinc-600 font-p">If you wish to consult with us by telephone to address any issue, please let us know by completing the following short form. Upon receipt, we will immediately contact you to schedule a 30-minute telephone appointment consultation with a licensed Canadian immigration practitioner approved by the Government of Canada to represent your interests.</p>
              <Link className="mt-4 inline-block rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600">Book a telephone consultation</Link>
            </div>
            <div className="col-lg-6 align-self-center">
              <img className="img-fluid w-100" src={ss8} />
            </div>
          </div>
        </div>
      </section>




      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                QUICK CONTACT
              </span>
              <h2 className="main-h2 text-sky-500 font-p">
                Have Questions?
              </h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}

              <div className="col-12">
                <div className="row mt-5 md:w-[96%]">
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim1} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Location
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        204, 205, Titanium One, Pakwan Cross Road
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim2} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Contact
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        Phone:<br></br>
                        +91 9023 620 998
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim3} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Email
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Phone:<br></br>
                        info@videshapna.com
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim4} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        Opening Hrs
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Mon - Friday:<br></br>
                        09.00 am to 07.00 pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                LET’S CONNECT
              </span>
              <h2 className="main-h2 text-sky-500 font-p">Send Your Message</h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}
              <div className="col-12 mt-5">
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Name</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Email</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Phone</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Subject</p>
                      <input className="form-control custom-form-control2" placeholder="Enter here..." />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <p className="text-lg font-p mb-2">Your Message</p>
                      <textarea rows={7} className="form-control custom-form-control2" placeholder="Enter here..."></textarea>
                    </div>
                    <div className="col-12">
                      <button className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}