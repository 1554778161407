import { createAsyncThunk } from '@reduxjs/toolkit'
import apiFormDataConfig from '../../config/api-config-without-token';
import apiConfig from '../../config/api-config';
import { getUser } from './users-actions';

export const assessmentsPersonalUser = createAsyncThunk("assessments/userDetails", async ({ userData, handleNext, visaType }) => {
    try {
        const response = await apiConfig.post('UserDetails/Create', userData);   
        if (response?.data?.succeeded) {
            if (visaType === "Business Candidate") {
                handleNext({ currentStep: 5 }); // Close the modal if the response indicates success
            } else {
                handleNext({ currentStep: 1 }); // Close the modal if the response indicates success

            }
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});

export const assessmentsAbilityEnglish = createAsyncThunk("assessments/userLanguage", async ({ userData, handleNext }) => {
    try {
        const response = await apiConfig.post('UserLanguage/Create', userData);
        if (response?.data?.succeeded) {
            handleNext({ currentStep: 2 }); // Close the modal if the response indicates success
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});

export const assessmentsEducationProfile = createAsyncThunk("assessments/userEducation", async ({ userData, handleNext, visaType }) => {
    try {
        const response = await apiConfig.post('UserEducation/Create', userData);
        if (response?.data?.succeeded) {
            if (visaType) {
                handleNext({ currentStep: 2 }); // Close the modal if the response indicates success
            } else {
                handleNext({ currentStep: 3 }); // Close the modal if the response indicates success
            }
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});

export const assessmentsEmployHistory = createAsyncThunk("assessments/userEmpHistory", async ({ userData, handleNext, visaType }) => {
    try {
        const response = await apiConfig.post('UserEmpHistory/CreateMultiple', userData);
        if (response?.data?.succeeded) {
            if (visaType) {
                handleNext({ currentStep: 3 });
            } else {
                handleNext({ currentStep: 4 });
            }

        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});

export const assessmentsUploadCSV = createAsyncThunk("assessments/userDocuments/uploadDocument", async ({ userData, handleNext }) => {
    try {
        const formData = new FormData();
        // Append your form fields and files to formData
        formData.append('FileDetails', userData.FileDetails); // Adjust this line based on your actual form field

        const response = await apiFormDataConfig.post('UserDocuments/UploadDocument', userData);
        handleNext({ currentStep: 5 });
        if (response?.data?.succeeded) {
            handleNext({ currentStep: 5 });
            return true
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});

export const assessmentBusinessInformation = createAsyncThunk("assessments/UserBusinessDetails", async ({ userData, handleNext }) => {
    try {
        const response = await apiConfig.post('UserBusinessDetails/Create', userData);
        console.log('response', response)
        if (response?.data?.succeeded) {
            handleNext({ currentStep: 2 });
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});

export const assessmentFinancialOther = createAsyncThunk("assessments/UserFinancialOther", async ({ userData, handleNext }) => {
    try {
        const response = await apiConfig.post('UserFinancialOther/Create', userData);
        console.log('response', response)
        if (response?.data?.succeeded) {
            handleNext({ currentStep: 5 }); // Close the modal if the response indicates success
        }
    } catch (error) {
        console.error('error', error);
        throw error; // Re-throw the error to let the caller handle it  
    }
});


