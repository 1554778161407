import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { loginUser } from "../../user/redux/slice/auth-slice";
import Logo from "../../Images/logo.svg";
import InputText from "../../shared/components/form/InputText";
import Select from "../../shared/components/form/Select";
import { validEmail, validatePwd } from "../../shared/utils/validation";
import { roleOption } from "../../user/config/array-config";

export default function Login() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state?.userData);

  const onLogin = (data) => {
    const payload = {
      data: data,
      navigate: navigate
    };
    dispatch(loginUser(payload));
  };

  return (
    <>
      <Helmet>
        <title>Login - Videsh Apna</title>
      </Helmet>
      <div className="relative isolate flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 h-100vh">
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }} />
        </div>

        <div className="box-log sm:mx-auto shadow-md sm:w-full md:max-w-xl bg-sky-50 pb-0">
          <div className="row">
            <div className="col-12">
              <div className="">
                <img
                  className="mx-auto h-10 w-auto"
                  src={Logo}
                  alt="Your Company"
                />
                <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
              </div>
            </div>
          </div>

          <div className="relative isolate flex p-3 row">
            <form className="space-y-6" onSubmit={handleSubmit(onLogin)}>
              <div className="row">
                {/* <div className="col-lg-6 col-md-12 mb-4">
                  <InputText
                    type="text"
                    name="userName"
                    label="Username"
                    errors={errors}
                    register={register}
                    validationSchema={{ required: "User name is required" }}
                  />
                </div> */}
                <div className="col-lg-12 col-md-12 mb-4">
                  <InputText
                    type="email"
                    name="email"
                    label="Email"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Email is required",
                      validate: (value) => validEmail(value),
                    }}
                  />
                </div>
                <div className="col-lg-12 col-md-12 mb-4">
                  <InputText
                    type="password"
                    name="password"
                    label="Password"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Password is required",
                      validate: (value) => validatePwd(value),
                    }}
                  />
                </div>
                <div className="col-lg-12 col-md-12 mb-4">
                  <Select
                    label="Role"
                    name="role"
                    options={roleOption}
                    errors={errors}
                    register={register}
                    validationSchema={{ required: "Role is required" }}
                  />
                </div>
                <div className="col-lg-12 text-center mb-4">
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-sky-400 px-3 py-3 text-md font-semibold leading-6 text-white hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                  >
                    {loginData?.loading ? (
                      <ThreeDots
                        visible={true}
                        height="30"
                        width="30"
                        color="white"
                        radius="5"
                        ariaLabel="three-dots-loading"
                      />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
                <div className="col-12 text-center">
                  <p className="mb-10 text-center text-md text-gray-500">
                    Not a member?{" "}
                    <Link
                      className="font-semibold leading-6 text-sky-600 hover:text-sky-500"
                      to="/sign-up"
                    >
                      Start to Sign Up
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-45rem)]" aria-hidden="true">
          <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }} />
        </div>
      </div>
    </>
  );
}
