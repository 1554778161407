import React, { useState } from "react";
import "./Notification.css";
import { io } from "socket.io-client";
import NotificationList from "./NotificationList";
const socket = io("http://64.227.182.223:4205/");

const Notifications = () => {
  const [notification, setNotification] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    socket.emit("notification", notification);
    setNotification("");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter notification"
          value={notification}
          onChange={(e) => setNotification(e.target.value)}
        />
        <button type="submit">Send Notification</button>
      </form>
      <NotificationList socket={socket} />
    </>
  );
};
export default Notifications;
