import { Helmet } from "react-helmet"

const PageTitle = ({ pageTitle }) => {
    return (
        <Helmet>
            <title>{pageTitle} - Videsh Apna</title>
        </Helmet>
    )
}

export default PageTitle