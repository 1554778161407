import React, { useEffect, useState } from "react";
import { Form, Row, Col, Card, CardBody, Button } from "reactstrap";
import Pdf from "../../../Images/pdf.png";
import { documentsUploadDocument } from "../../../customer/redux/action/uploadDoc-actions";
import { useDispatch, useSelector } from "react-redux";
import { userGetUserInfo } from "../../../customer/redux/slice/userSlice";
import { getConnectedUser } from "../../../customer/redux/action/ConnectedUser-action";
import { getConsultantDocuments } from "../../../customer/redux/action/consultantDocuments";

const UserUploadDocuments = () => {
  const [document, setDocument] = useState([]);
  console.log("document", document);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [validationDocuments, setValidationDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileUploadMessages, setFileUploadMessages] = useState({});
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const dispatch = useDispatch();

  const { connectedConsultant } = useSelector(
    (state) => state.connectedUsers || {}
  );
  const userData = useSelector((state) => state.userData.authData.data);
  const { documents } = useSelector((state) => state.consultantDocuments);

  // Define required documents
  const requiredDocuments = documents.map((doc) => doc.documentName);

  useEffect(() => {
    if (userData?.userId) {
      const consultantId = connectedConsultant[0].consultantId;
      dispatch(getConnectedUser(userData.userId));
      dispatch(userGetUserInfo(userData.userId));
      dispatch(
        getConsultantDocuments({ consultantId, userId: userData.userId })
      );
    }
  }, [dispatch, userData?.userId]);

  useEffect(() => {
    if (userData?.userId && connectedConsultant.length > 0) {
      const consultantId = connectedConsultant[0].consultantId;
      dispatch(
        getConsultantDocuments({ consultantId, userId: userData.userId })
      );
    }
  }, [dispatch, userData?.userId, connectedConsultant]);

  const handleFile = (file, type) => {
    if (!file) return;

    if (file.type !== "application/pdf") {
      setErrorMessage("Only PDF files are allowed.");
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      setErrorMessage(
        "File size exceeds the limit of 10 MB. Please choose a smaller file."
      );
      return;
    }

    setErrorMessage("");
    const newDocument = {
      file,
      fileName: file.name,
      documentType: type,
    };
    setDocument((prevDocuments) => [...prevDocuments, newDocument]);

    setFileUploadMessages((prevMessages) => ({
      ...prevMessages,
      [type]: "File uploaded successfully.",
    }));
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    handleFile(file, type);
  };

  const responseData1 = (formData) => {
    dispatch(documentsUploadDocument(formData)).catch((error) => {
      console.error("API error:", error); // Log any errors
      // setValidationMessages({ apiError: error.message });
    });
  };

  const handleSubmit = async () => {
    const uploadedDocumentTypes = document.map((doc) => doc.documentType);
    const validate = requiredDocuments.filter(
      (reqDoc) => !uploadedDocumentTypes.includes(reqDoc)
    );
    setValidationDocuments(validate);

    if (validate.length > 0) {
      return;
    }

    try {
      // for (const doc of document) {
      //   console.log("doc", doc);
      //   const formData = new FormData();
      //   formData.append("UserId", userData?.userId);
      //   formData.append("FileDetails", doc.file);
      //   formData.append("DocumentType", doc.documentType);
      //   formData.append("FileType", "PDF");
      //   formData.append("Name", doc.fileName);

      //   const response = await dispatch(documentsUploadDocument(formData));
      //   console.log("response 123456", response);
      //   console.log("formData", formData);

      //   // Check if the response is successful
      //   if (response.error) {
      //     throw new Error(response.error.message);
      //   }
      // }
      for (let i = 0; i < document.length; i++) {
        const doc = document[i];
        const formData = new FormData();
        formData.append("UserId", userData?.userId);
        formData.append("FileDetails", doc.file);
        formData.append("DocumentType", doc.documentType);
        formData.append("FileType", "PDF");
        formData.append("Name", doc.fileName);
        responseData1(formData);
      }

      setUploadSuccess(true);
      setShowCountdown(true);

      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(timer);
        setShowCountdown(false);
        setCountdown(3);
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error uploading documents:", error);
      setErrorMessage("An error occurred during the upload. Please try again.");
    }
  };

  return (
    <div>
      <Row className="justify-content-center pb-10 ab">
        <Col xl="10">
          <Card className="document-profile">
            <CardBody>
              {uploadSuccess ? (
                <div className="text-center">
                  <h3>Thank you! Documents uploaded successfully.</h3>
                  {showCountdown && (
                    <p>Refreshing page in {countdown} seconds...</p>
                  )}
                </div>
              ) : (
                <Form>
                  <div>
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                    <Row>
                      {documents && documents.length > 0 ? (
                        documents.map((doc) => (
                          <Col lg="4" key={doc.id} className="mb-4">
                            <div className="upload-ddd">
                              <label className="form-control-label w-100 text-white text-lg font-p mb-3 form-label">
                                {doc.documentName}
                              </label>
                              <input
                                type="file"
                                accept=".pdf"
                                onChange={(e) =>
                                  handleFileChange(e, doc.documentName)
                                }
                              />
                              <div className="flex-container upload-dd">
                                <div>
                                  <img src={Pdf} alt="Selected Document" />
                                </div>
                              </div>
                              {validationDocuments.includes(
                                doc.documentName
                              ) && <p className="text-danger">Is required.</p>}
                              {fileUploadMessages[doc.documentName] && (
                                <p className="text-success">
                                  {fileUploadMessages[doc.documentName]}
                                </p>
                              )}
                            </div>
                          </Col>
                        ))
                      ) : (
                        <Col>
                          <p>No documents available.</p>
                        </Col>
                      )}
                    </Row>

                    <Button
                      className="mt-4 text-blue-600 border-1 text-white bg-blue-500 border-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none font-medium rounded-lg px-4 py-3 inline-flex justify-center align-items-center text-center"
                      onClick={handleSubmit}
                    >
                      Upload Documents
                    </Button>
                  </div>
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserUploadDocuments;
