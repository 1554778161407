import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { assessmentFinancialOther } from '../../redux/action/assessments-personal-actions';
import { useForm } from 'react-hook-form';
import InputText from '../../../shared/components/form/InputText';
import Radio from '../../../shared/components/form/Radio';
import Select from '../../../shared/components/form/Select';
import { amountLists, radioLists, relationshipLists } from '../../../shared/constants/arrayConfig';
import { filterCountryCurrency } from '../../../shared/utils/shared-method';
import { ThreeDots } from 'react-loader-spinner';

function StudyVisaOther({ handleNext, country, userData }) {
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const handleOther = async (formData) => {
    setIsSubmitting(true)
    await dispatch(assessmentFinancialOther({ userData: { userId: userData?.id, ...formData }, handleNext }))
    setIsSubmitting(false)
  }

  return (
    <form>
      <div className="row">
        <div className="col-12 mb-4">
          <Radio name="funds" label={`Did you have at least $25,000 ${filterCountryCurrency({ country, type: "currency" })} in liquid, readily available funds?`} radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("funds", value)}
            register={register}
            validationSchema={{
              required: "Funds is required",
            }}
            required
          />
        </div>
        <div className="col-12 mb-2">
          <p className="block mb-2 text-lg text-gray-900">
            These funds must be in your name, your spouse's name (if available) or your parent's name (if available).
          </p>
        </div>
        <div className="col-12 mb-4">
          <div className="row align-items-center">
            <div className="col-8">
              <p className="block mb-2 text-lg font-medium text-gray-900">
                Please specify the amount of funds, in ${filterCountryCurrency({ country, type: "currencyName" })}, available for your studies in {country}
              </p>
            </div>
            <div className="col-4">
              <Select
                name="fundsAmount"
                options={amountLists}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Amount is required",
                }}
                required
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-3 mb-5">
          <h2 className="text-lg fs-2 fw-bold">
            Do you have relatives in {country} ? If yes, please provide
          </h2>
        </div>
        <div className="col-lg-4 col-12 mb-4">
          <Select
            name="relationship"
            label="Type of Relationship"
            options={relationshipLists}
            register={register}
          />
        </div>
        <div className="col-lg-4 col-12 mb-4">
          <InputText
            type="text"
            name="relativeProvince"
            label="Relative’s province of residence"
            errors={errors}
            register={register}
          />
        </div>
        <div className="col-lg-4 col-12 mb-4">
          <InputText
            type="text"
            name="relativeStatus"
            label="Relative’s status"
            errors={errors}
            register={register}
          />
        </div>
        <div className="col-12 mb-4">
          <Radio name="refusedVisa" label={`Have you previously been refused a visa to ${country}?`} radioOptions={radioLists} errors={errors}
            onChangeRadio={(value) => setValue("refusedVisa", value)}
            register={register}
            validationSchema={{
              required: "Refused visa is required",
            }}
            required
          />
        </div>
        <div className="col-12 mb-4">
          <InputText
            name="provideDetails"
            label="If your answer to the above question is 'Yes' provide details here"
            register={register}
            isTextarea
          />
        </div>
        <div className="col-12 text-end">
          <button
            className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
            type="submit"
            onClick={handleSubmit(handleOther)}
            disabled={isSubmitting} 
          >
           {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Save & Next"}
          </button>
        </div>
      </div>
    </form>

  )
}

export default StudyVisaOther