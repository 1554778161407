import { createAsyncThunk } from '@reduxjs/toolkit';
import apiConfig from '../../config/api-config';

export const getConsultantDocuments = createAsyncThunk(
    "DocumentsLists",
    async ({ consultantId, userId }) => {
        const initialUserData = {
            limit: '20',
            offset: '0',
            filter: null,
            sorting: {
                fieldName: 'created_at',
                direction: 'asc',
            },
            consultantId, // Add consultantId to the payload
            userId, // Add userId to the payload
        };

        try {
            const response = await apiConfig.post("ConsultantDocumentsList/GetByConsultantDocuments", initialUserData);
            if (response?.data?.succeeded) {
                return response?.data?.data;
            }
        } catch (error) {
            console.error('error', error);
            throw error; // Re-throw the error to let the caller handle it  
        }
    }
);
