import { createAsyncThunk } from "@reduxjs/toolkit";
import apiConfig from "../../config/api-config";

export const createContactUs = createAsyncThunk(
  "createContactUs",
  async (userData) => {
    try {
      const response = await apiConfig.post("Users/ContactUs", userData);
      if (response?.data?.succeeded) {
        return "Successfully Form Submitted";
      }
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error to let the caller handle it
    }
  }
);
