import React from "react";
import { Link } from "react-router-dom";

const AuthRouteError = () => {
  return (
    <div className="container text-center mt-5">
      <h1 className="mb-4 fs-5">You Are Not Allowed To Access This Page!!</h1>
      <Link to="/" className="btn btn-primary">Go Back</Link>
    </div>
  );
};

export default AuthRouteError;
