import React from "react";
import Error from "../Pages/Error";
import { routes } from "../../user/route";
import FeedbackForm from "../../user/components/FeedbackForm";
import Consultant from "../../user/components/Consultant";
import Eligibility from "../../user/components/Eligibility";
import Notifications from "../../user/components/Notifications";
const Dashboard = React.lazy(() => import("../../user/components/Dashboard"));
const Document = React.lazy(() => import("../../user/components/Document"));
const Payment = React.lazy(() => import("../../user/components/Payment"));
const Tracking = React.lazy(() => import("../../user/components/Tracking"));
const UserProfile = React.lazy(() =>
  import("../../user/components/UserProfile")
);
const Chat = React.lazy(() => import("../../user/components/Chat"));
const Help = React.lazy(() => import("../../user/components/Help"));
export const userRoutes = [
  {
    path: routes.DASHBOARD,
    component: Dashboard,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.TRACKING,
    component: Tracking,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.DOCUMENT,
    component: Document,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.PAYMENT,
    component: Payment,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.USER_PROFILE,
    component: UserProfile,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.CHAT,
    component: Chat,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.HELP,
    component: Help,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.FEEDBACKFORM,
    component: FeedbackForm,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.CONSULTANT,
    component: Consultant,
    errorElement: <Error />,
    showHeaderAndFooter: true,
  },
  {
    path: routes.ELIGIBILITY,
    component: Eligibility,
    errorElement: <Error />,
    showHeaderAndFooter: true,
},
{
  path: routes.NOTIFICATION,
  component: Notifications,
  errorElement: <Error />,
  showHeaderAndFooter: true,
},

];
