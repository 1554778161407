import React, { useState } from "react";
import Radio from "../../../../shared/components/form/Radio";
import { radioLists } from "../../../../shared/constants/arrayConfig";
import { useForm } from "react-hook-form";
import InputText from "../../../../shared/components/form/InputText";
import { convertBooleanValue, monthDropdown, yearDropdown } from "../../../../shared/utils/shared-method";
import Select from "../../../../shared/components/form/Select";
import { assessmentsEducationProfile } from "../../../redux/action/assessments-personal-actions";
import { useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

function EducationalProfile({ handleNext, userData }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const handleEducationProfile = async (formData) => {
    const booleanFields = ["highSchoolGraduated"];
    // Convert specific fields to boolean
    setIsSubmitting(true);
    await dispatch(assessmentsEducationProfile({
      userData: {
        userId: userData?.id,
        otherEducation: "",
        otherTraining: "",
        ...formData,
        ...convertBooleanValue(booleanFields, watch), // Include boolean fields in the userData
      },
      handleNext,
    }));
    setIsSubmitting(false);
  };

  const selectedStartYear = watch("startingYear");
  const selectedPassoutYear = watch("passoutYear");

  return (
    <form onSubmit={handleSubmit(handleEducationProfile)}>
      <div className="row">
        <div className="col-12 mb-3">
          <Radio
            name="highSchoolGraduated"
            label="Have you graduated high school"
            radioOptions={radioLists}
            errors={errors}
            onChangeRadio={(value) => setValue("highSchoolGraduated", value)}
            register={register}
            validationSchema={{
              required: "High school graduated is required",
            }}
            required
          />
        </div>
        <div className="col-4 mb-3">
          <InputText
            type="text"
            name="educationName"
            label="Education Name"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Education name is required",
            }}
            required
          />
        </div>
        <div className="col-4 mb-3">
          <InputText
            type="text"
            name="university"
            label="University"
            errors={errors}
            register={register}
            validationSchema={{
              required: "University is required",
            }}
            required
          />
        </div>
        <div className="col-4 mb-3">
          <InputText
            type="number"
            name="percentage"
            label="Percentage(GPA/CGPA)"
            errors={errors}
            register={register}
      
            validationSchema={{
              required: "Percentage is required",
              validate: (value) => {
                if (!/^[0-9]+$/.test(value)) {
                  return "Only numeric values are allowed";
                }
                if (value.length < 2) {
                  return "Percentage must be at least 2 digits";
                }
                return true;
              },
            }}
            required
          />
        </div>
        <div className="col-3 mb-3">
          <Select
            name="startingYear"
            label="Start Year"
            options={yearDropdown()}
            errors={errors}
            register={register}
            validationSchema={{
              required: "Starting year is required",
            }}
            required
          />
        </div>
        <div className="col-3 mb-3">
          <Select
            name="startingMonth"
            label="Start Month"
            options={monthDropdown(selectedStartYear)}
            errors={errors}
            register={register}
            validationSchema={{
              required: "Start month is required",
            }}
            required
          />
        </div>
        <div className="col-3 mb-3">
          <Select
            name="passoutYear"
            label="Pass Out Year"
            options={yearDropdown()}
            errors={errors}
            register={register}
            validationSchema={{
              required: "Pass out year is required",
            }}
            required
          />
        </div>
        <div className="col-3 mb-3">
          <Select
            name="passoutMonth"
            label="Pass Out Month"
            options={monthDropdown(selectedPassoutYear)}
            errors={errors}
            register={register}
            validationSchema={{
              required: "Pass out month is required",
            }}
            required
          />
        </div>
        <div className="col-12 mb-4">
          <p>
            Post-secondary education is known as tertiary education, or the
            level that follows the successful completion of secondary education,
            which is also referred to as high school. Post-secondary education
            includes universities, colleges, as well as trade and vocational
            schools.
          </p>
        </div>
        <div className="col-12 text-end pb-2">
          <button
            className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
            type="submit"
            onClick={handleSubmit(handleEducationProfile)}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              />
            ) : (
              "Save & Next"
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default EducationalProfile;
