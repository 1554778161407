import { createAsyncThunk } from '@reduxjs/toolkit'
import apiConfig from '../../config/api-config';
import { toast } from 'react-toastify';
import { userGetUserInfo } from '../slice/userSlice';

const initialUserData = {
    limit: '20',
    offset: '0',
    filter: null,
    sorting: {
        fieldName: 'created_at',
        direction: 'asc',
    },
};

export const documentsList = createAsyncThunk('userdocumets/list', async () => {
  try {
    const response = await apiConfig.post("UserDocuments/List",
      initialUserData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const documentsCreate = createAsyncThunk(
  'userdocuments/create',
  async (requestData, { rejectWithValue }) => {  
    try {
      const response = await apiConfig.post(
       " UserDocuments/Create",
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const documentsUpdate = createAsyncThunk(
  'userdocuments/update',
  async (data, { rejectWithValue, dispatch }) => {
      try {
      const response = await apiConfig.post(
        `UserDocuments/Update`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      dispatch(userGetUserInfo(data?.userId));          
      return response;
    } catch (error) {
        toast.error('Failed to update document');
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleDocument = createAsyncThunk('userdocuments/id', async (id) => {
  try {
    const response = await apiConfig.apiConfig(`UserDocuments/${id}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

export const documentsUploadDocument = createAsyncThunk(
  'userdocuments/uploadDocument',
  async (requestData, { rejectWithValue }) => {  
    try {
      const formData = new FormData();
        // Append your form fields and files to formData
        formData.append('FileDetails', requestData.FileDetails); // Adjust this line based on your actual form field
        const response = await apiConfig.post(
        "UserDocuments/UploadDocument",  requestData,    
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });   
      
      return response.data;
    } catch (error) {
      toast.error('Failed to upload documents')
      return rejectWithValue(error.response.data);
    }
  }
);

export const documentsDelete = createAsyncThunk(
  'userdocumets/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiConfig.delete(`UserDocuments?id=${id}`, {
        withCredentials: true,
      });
      toast.success('Delete Document successfully')   
      return response.data;
    } catch (error) {
      toast.error('Failed to upload documents')
      return rejectWithValue(error.response.data);
    }
  }
);

