import React from "react";
import "react-step-progress/dist/index.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ss8 from "../../Images/ss8.gif";
import Iim1 from "../../Images/meeting-point.png";
import Iim2 from "../../Images/operator.png";
import Iim3 from "../../Images/email.png";
import Iim4 from "../../Images/hourglass.png";
import S1 from "../../Images/q1.gif";
import S2 from "../../Images/q2.gif";
import { routes } from "../constants";
import { useTranslation } from "react-i18next";

export default function Assessment() {

  const {t} = useTranslation();
  const {mainhead1,p2,p3,p4,mainhead2,mainhead3,p5,p6,btn2,mainhead4,mainhead5,p7,p8,mainhead6,p9,btn3,heading1,p1,btn1,heading2,subhead1,subhead2,subhead3,subhead4,heading3,heading4} = t("Assessment");
  return (
    <>
      <Helmet>
        <title>Assessment - Videsh Apna</title>
      </Helmet>
      <div className="bg-stone-100">
        <div className="relative  px-6 pt-14 pb-14 lg:px-8">
          <div className="mx-auto max-w-[100%] py-20 sm:py-12 lg:py-12">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-sky-500 sm:text-6xl font-p mb-2">
                {mainhead1}
              </h1>
              <p className="text-lg leading-8 text-zinc-600 font-p">
                {p2}
              </p>
              <p className="mt-0 text-lg leading-8 text-zinc-600 font-p">
               {p3}
              </p>
              <p className="mt-0 text-lg leading-8 text-zinc-600 font-p text-underline">
                {p4}
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="process-step row justify-content-center mb-5 position-relative">
                <div className="col-lg-6 mb-lg-0 mb-5">
                  <h6 className="sub-title text-sky-900 font-p">
                    {mainhead2}
                  </h6>
                  <h5 className="step-names1 lg:w-[92%] mt-0">
                    {mainhead3}
                    <br className="br"></br>
                    <br className="br"></br>
                  </h5>
                  <div className="position-relative  mb-3">
                    {/* <p className="ff1 text-xl text-sky-500 font-semibold">
                      Do you wish to relocate abroad?{" "}
                    </p> */}
                    <img
                      className="step-image img-fluid mx-auto"
                      src={S1}
                      alt="Step 1"
                    />
                    {/* <p className="ff2 text-xl text-sky-500 font-semibold">
                      Don't know which country is suitable for you?{" "}
                    </p> */}
                  </div>
                  <p className="step-sub-text mb-3">
                    {p5}
                  </p>{" "}
                  <p className="step-sub-text mb-3">
                    {p6}
                  </p>
                  <Link
                    to={`${routes.FREE_ASSESSMENT}`}
                    className="mt-1 inline-block rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600"
                  >
                    {btn2}
                  </Link>
                </div>
                <div className="col-lg-6 mb-lg-0 mb-5">
                  <h6 className="sub-title text-sky-900 font-p">
                    {mainhead4}
                  </h6>
                  <h5 className="step-names1 lg:w-[92%] mt-0">
                   {mainhead5}
                  </h5>
                  <div className="position-relative mb-3">
                    {/* <p className="fff1 text-xl text-sky-500 font-semibold">
                      Do you have any doubts about your eligibility?{" "}
                    </p> */}
                    <img
                      className="step-image img-fluid mx-auto"
                      src={S2}
                      alt="Step 1"
                    />
                    {/* <p className="ff2 text-xl text-sky-500 font-semibold">
                      Do you wish to go to your dream country but don't know
                      where to start?{" "}
                    </p> */}
                  </div>
                  <p className="step-sub-text mb-3">
                    {p7}
                  </p>
                  <p className="step-sub-text mb-3">
                    {p8}
                  </p>
                  <Link
                    to={`${routes.CHECK_ELIGIBILITY}`}
                    className="mt-1 inline-block rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600"
                  >
                    {btn2}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section bg-[#f0f9ff]">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h2 className="main-h2 text-sky-500 font-p">
                {mainhead6}
              </h2>
              <p className="mt-3 text-lg leading-8 text-zinc-600 font-p">
               {p9}
              </p>
              <Link to="/contact" className="mt-4 inline-block rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600">
                {btn3}
              </Link>
            </div>
            <div className="col-lg-6 align-self-center">
              <img className="img-fluid w-100" src={ss8} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                QUICK CONTACT
              </span>
              <h2 className="main-h2 text-sky-500 font-p">{p1}</h2>
             
              <div className="col-12">
                <div className="row mt-5 md:w-[96%]">
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim1} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead1}
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        204, 205, Titanium One, Pakwan Cross Road
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim2} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead2}
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        Phone:<br></br>
                        +91 9023 620 998
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim3} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead3}
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Phone:<br></br>
                        info@videshapna.com
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim4} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead4}
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Mon - Friday:<br></br>
                        09.00 am to 07.00 pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                {heading3}
              </span>
              <h2 className="main-h2 text-sky-500 font-p">{heading4}</h2>
            
              <div className="col-12 mt-5">
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Name</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Email</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Phone</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Subject</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <p className="text-lg font-p mb-2">Your Message</p>
                      <textarea
                        rows={7}
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600">
                        {btn1}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
