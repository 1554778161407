import { createAsyncThunk } from '@reduxjs/toolkit'
import apiConfig from '../../config/api-config';

export const getUserDetails = createAsyncThunk("getUserDetails", async (userId) => {
    try {
        const response = await apiConfig.post("Users/GetUserInfo", { userId: userId });
        if (response?.data?.succeeded) {
            return response?.data?.data;
        }
    } catch (error) {
        console.error('error', error);
        throw error; 
    }
});

export const getUser = createAsyncThunk("getUser", async (userId) => {
    try {
        const response = await apiConfig.get(`Users/${userId}`);
        if (response?.data?.succeeded) {
            return response?.data?.data;
        }
    } catch (error) {
        console.error('error', error);
        throw error; 
    }
});
