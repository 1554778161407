import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


import axios from 'axios';
import { baseURL } from '../baseUrl';

const initialUserData = {
  limit: '10',
  offset: '0',
  filter: null,
  sorting: {
    fieldName: 'created_at',
    direction: 'asc',
  },
};

export const educationCreate = createAsyncThunk(
  'userEducation/create',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseURL}UserEducation/Create`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const educationList = createAsyncThunk(
  'userEducation/list',
  async () => {
    try {
      const response = await axios.post(
        `${baseURL}UserEducation/List`,
        initialUserData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const educationUpdate = createAsyncThunk(
  'userEducation/update',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseURL}UserEducation/Update`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const educationDelete = createAsyncThunk(
  'userEducation/delete',
  async (id, { rejectWithValue }) => {
    try {
      // Use the `axios.delete` method to send a DELETE request to the server with the user ID in the URL
      const response = await axios.delete(`${baseURL}UsersEducation?id=${id}`, {
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const educationSlice = createSlice({
  name: 'education',
  initialState: { education: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => [
    builder
      .addCase(educationCreate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(educationCreate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(educationCreate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(educationList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(educationList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.education = action.payload;
      })
      .addCase(educationList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(educationUpdate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(educationUpdate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.education = action.payload;
      })
      .addCase(educationUpdate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(educationDelete.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(educationDelete.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(educationDelete.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      }),
  ],
});
export default educationSlice.reducer;
