import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsultantDocumentLists,
  getConsultantLists,
} from "../../customer/redux/action/consultant-action";
import Consultancyimage from "../../Images/consultant1.webp";
import { Link } from "react-router-dom";
import VideoCall from "../../../src/video-call-svgrepo-com.svg";
import Chart4 from "./Chart4";
import Tabbs from "./Tabbs";

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <svg
          key={i}
          className="w-6 h-6 fill-current text-yellow-500"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 1L12.245 6.38H18.619L13.827 9.62L16.072 15L10 11.875L3.928 15L6.173 9.62L1.381 6.38H7.755L10 1Z" />
        </svg>
      );
    } else {
      stars.push(
        <svg
          key={i}
          className="w-6 h-6 fill-current text-gray-400"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 1L12.245 6.38H18.619L13.827 9.62L16.072 15L10 11.875L3.928 15L6.173 9.62L1.381 6.38H7.755L10 1Z" />
        </svg>
      );
    }
  }
  return stars;
};

function Eligibility() {
  const circumference = ((2 * 22) / 7) * 90;
  const dispatch = useDispatch();
  const { consultants } = useSelector((state) => state?.consultants || {});
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("lowToHigh");
  const [currentPage, setCurrentPage] = useState(1);
  const consultantsPerPage = 6;

  useEffect(() => {
    dispatch(getConsultantLists());
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (type) => {
    setSortBy(type);
  };

  const indexOfLastConsultant = currentPage * consultantsPerPage;
  const indexOfFirstConsultant = indexOfLastConsultant - consultantsPerPage;

  const sortedAndFilteredConsultants = consultants
    .filter((consultant) =>
      consultant.consultantName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "lowToHigh") {
        return a.rating - b.rating;
      } else {
        return b.rating - a.rating;
      }
    });

  const currentConsultants = sortedAndFilteredConsultants.slice(
    indexOfFirstConsultant,
    indexOfLastConsultant
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section>
      <div className="container">
        <div className="row">
    <div className="col-12 pt-5">
        <Tabbs />
    </div>
          
        </div>
      </div>
    </section>
  );
}

export default Eligibility;
