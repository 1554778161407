import React, { useEffect, useState } from "react";
import "react-step-progress/dist/index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Im8 from "../../Images/survey.png";
import ss8 from "../../Images/ss8.gif";
import Iim1 from "../../Images/meeting-point.png";
import Iim2 from "../../Images/operator.png";
import Iim3 from "../../Images/email.png";
import Iim4 from "../../Images/hourglass.png";
import PageTitle from "../../shared/components/seo/PageTitle";
import { assessmentFormStepsArrayConfig } from "../../shared/config/assessmentFormStepsArrayConfig";
import {
  ageGroupOptions,
  countryCurrencyLists,
  englishLevelLists,
  maritalStatusLists,
  skilledWorker,
  visaTypes,
} from "../../shared/constants/arrayConfig";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Select from "../../shared/components/form/Select";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import InputText from "../../shared/components/form/InputText";
import { validEmail, validatePwd } from "../../shared/utils/validation";
import { createFreeAssessments } from "../redux/action/freeAssessments-action";
import { ThreeDots } from "react-loader-spinner";

export default function AssessmentsForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      language: isCreateMode ? "English" : "", // Set default value for language if in create mode
    },
  });
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activeItem, setActiveItem] = useState({ currentStep: 0 });
  const [stepState, setStepState] = useState([]);
  const params = useLocation();
  const { userDetails } = useSelector((state) => state || {});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setActiveItem(activeItem);
  }, [activeItem]);

  const submitData = async (formData) => {
    setIsSubmitting(true);
    const response = await dispatch(createFreeAssessments(formData));
    if (response?.payload?.succeeded) {
      handleClose();
      setIsCreateMode(false);
      reset({
        id: "",
        country: "",
        visaType: "",
        age: "",
        userEducation: "",
        maritalStatus: "",
        spouse: "",
        workExperience: "",
        language: "",
        speak: "",
        read: "",
        write: "",
        listen: "",
        email: "",
        userName: "",
        password: "",
        phone: "",
      });
    }
    navigator("/thank-you");
    setIsSubmitting(false);
  };

  const onHandleNextStep = (step) => {
    if (step?.currentStep >= 0 && step?.currentStep <= 5) {
      setActiveItem({
        ...activeItem,
        currentStep: step?.currentStep,
      });
    }
  };

  const assessmentSteps = assessmentFormStepsArrayConfig({
    onHandleNextStep,
    userData: userDetails?.userData,
    pathname: params?.pathname.replace("/", ""),
  });

  useEffect(() => {
    let createSteps = assessmentSteps
      .filter((assessmentStep) => skilledWorker?.includes(assessmentStep?.key))
      .map((step, idx) => ({
        key: step?.key,
        completed: idx < activeItem?.currentStep, // past are completed
        selected: idx <= activeItem?.currentStep, // past & present are colored
        highlighted: idx === activeItem?.currentStep - 1, // only present is highlighted
        ...step,
      }));

    setStepState(createSteps);
  }, [activeItem]);

  const { t } = useTranslation();
  const {
    heading1,
    p1,
    p2,
    p3,
    subhead1,
    mainhead6,
    p9,
    btn3,
    p10,
    heading4,
    subhead2,
    subhead3,
    subhead4,
    subhead5,
    heading5,
    heading6,
    btn1,
  } = t("AssessmentsForm");

  const onSubmit = (data) => {
    setShow(true); // Show modal after form validation
  };

  return (
    <>
      <PageTitle pageTitle="Free Assessment" />
      <div className="bg-stone-100">
        <div className="relative  px-6 pt-14 pb-14 lg:px-8">
          <div className="mx-auto max-w-[100%] py-20 sm:py-12 lg:py-12">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-sky-500 sm:text-6xl font-p mb-2">
                {heading1}
              </h1>
              <p className="text-lg leading-8 text-zinc-600 font-p">
                {p1}
                <br className="br"></br> {p2}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky-100">
        <div className="relative container px-6 pt-5 pb-5 lg:px-5">
          <div className="mx-auto max-w-[100%] ">
            <div className="text-left d-flex text-lg leading-8 text-zinc-600 font-p  align-items-center">
              <img className="img-fluid mr-2" src={Im8} alt=""></img>
              {p3}
            </div>
          </div>
        </div>
      </div>
      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className="sub-title text-sky-900 font-p">{subhead1}</span>
            </div>
            <div className="col-12 mt-2">
              <div className="row">
                <div className="col-lg-4 col-6 mb-4">
                  <Select
                    name="country"
                    label="Select your preferred destination Country?"
                    options={countryCurrencyLists}
                    register={register}
                    validationSchema={{
                      required: "Country is required",
                    }}
                    required
                  />
                </div>

                <div className="col-lg-4 col-6 mb-4">
                  <Select
                    name="visaType"
                    label="Select the Visa Type"
                    options={visaTypes}
                    register={register}
                    validationSchema={{
                      required: "Visa type is required",
                    }}
                    required
                  />
                </div>
                <div className="col-lg-4 col-12 mb-4">
                  <Select
                    name="age"
                    label="Select Age group"
                    options={ageGroupOptions}
                    register={register}
                    validationSchema={{
                      required: "Age is required",
                    }}
                    required
                  />
                </div>
                <div className="col-lg-3 col-12 mb-4">
                  <div className="form-control-input">
                    <InputText
                      type="text"
                      name="userEducation"
                      label="Education"
                      errors={errors}
                      register={register}
                      validationSchema={{
                        required: "Education is required",
                      }}
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <Select
                    name="maritalStatus"
                    label="Marital Status"
                    options={maritalStatusLists}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Marital status is required",
                    }}
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <InputText
                    type="number"
                    name="workExperience"
                    label=" Work experience (Year)"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Work experience is required",
                    }}
                    required
                  />
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                  <InputText
                    type="text"
                    name="spouse"
                    label="Spouse Name"
                    errors={errors}
                    register={register}
                  />
                </div>

                <div className="col-lg-12 col-12 mb-4">
                  <div className="form-control-input">
                    <label
                      className="block mb-2 text-lg font-medium text-gray-900"
                      for="education"
                    >
                      Language proficiency
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <Select
                    name="read"
                    label="Read"
                    options={englishLevelLists}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Read is required",
                    }}
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <Select
                    name="write"
                    label="Write"
                    options={englishLevelLists}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "write is required",
                    }}
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <Select
                    name="speak"
                    label="Speak"
                    options={englishLevelLists}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Speak is required",
                    }}
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <Select
                    name="listen"
                    label="Listen"
                    options={englishLevelLists}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Listen is required",
                    }}
                    required
                  />
                </div>

                <div className="col-lg-12 col-12 mb-4">
                  <div className="form-control-input">
                    <label
                      className="block mb-2 text-lg font-medium text-gray-900"
                      for="education"
                    >
                      Personal information
                    </label>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                  <InputText
                    type="text"
                    name="userName"
                    label="Name"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Name is required",
                    }}
                    required
                  />
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                  <InputText
                    type="email"
                    name="email"
                    label="Email"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Email is required",
                      validate: (value) => validEmail(value),
                    }}
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <InputText
                    type="number"
                    name="phone"
                    label="Phone"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Mobile is required",
                      validate: (value) => {
                        if (!/^[0-9]+$/.test(value)) {
                          return "Only numeric values are allowed";
                        }
                        if (value.length < 10) {
                          return "Mobile number must be at least 10 digits";
                        }
                        return true;
                      },
                    }}
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                  <InputText
                    type="password"
                    name="password"
                    label="Password"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Password is required",
                      validate: (value) => {
                        return validatePwd(value);
                      },
                    }}
                    required
                  />
                </div>

                <div className="col-12 text-right">
                  <button
                      onClick={handleSubmit(submitData)}
                    className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600" 
                  >
                    Submit
                  </button>

                  {/* <Link
                    to={"/thank-you"}
                    className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
                    onClick={handleSubmit(submitData)}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <ThreeDots
                        visible={true}
                        height="30"
                        width="30"
                        color="white"
                        radius="5"
                        ariaLabel="three-dots-loading"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section bg-[#f0f9ff]">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h2 className="main-h2 text-sky-500 font-p">{mainhead6}</h2>
              <p className="mt-3 text-lg leading-8 text-zinc-600 font-p">
                {p9}
              </p>
              <Link to="/contact" className="mt-4 inline-block rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600">
                {btn3}
              </Link>
            </div>
            <div className="col-lg-6 align-self-center">
              <img className="img-fluid w-100" src={ss8} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">{p10}</span>
              <h2 className="main-h2 text-sky-500 font-p">{heading4}</h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}

              <div className="col-12">
                <div className="row mt-5 md:w-[96%]">
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim1} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead2}
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        204, 205, Titanium One, Pakwan Cross Road
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim2} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead3}
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        Phone:<br></br>
                        +91 9023 620 998
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim3} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead4}
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Phone:<br></br>
                        info@videshapna.com
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim4} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead5}
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Mon - Friday:<br></br>
                        09.00 am to 07.00 pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">{heading5}</span>
              <h2 className="main-h2 text-sky-500 font-p">{heading6}</h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}
              <div className="col-12 mt-5">
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Name</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Email</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Phone</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Subject</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <p className="text-lg font-p mb-2">Your Message</p>
                      <textarea
                        rows={7}
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button
                        className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
                        onClick={handleSubmit(onSubmit)}
                      >
                        {btn1}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
