import { createSlice } from "@reduxjs/toolkit";
import { getConsultantDocuments } from "../action/consultantDocuments";

const ConsultantDocumentsSlice = createSlice({
    name: 'Documents',
    initialState: {
        isLoading: false,
        documents: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConsultantDocuments.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.documents = payload
        })
    },
});

export default ConsultantDocumentsSlice.reducer