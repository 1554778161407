import { createSlice } from "@reduxjs/toolkit";
import { assessmentBusinessInformation, assessmentFinancialOther, assessmentsAbilityEnglish, assessmentsEducationProfile, assessmentsEmployHistory, assessmentsPersonalUser, assessmentsUploadCSV } from "../action/assessments-personal-actions";

const assessmentsPersonalSlice = createSlice({
    name: 'assessments',
    initialState: {
        isLoading: false,
        data: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(assessmentsPersonalUser.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state = payload
        })
        builder.addCase(assessmentsAbilityEnglish.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state = payload
        })
        builder.addCase(assessmentsEducationProfile.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state = payload
        })
        builder.addCase(assessmentsEmployHistory.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state = payload
        })
        builder.addCase(assessmentsUploadCSV.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state = payload
        })
        builder.addCase(assessmentBusinessInformation.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state = payload
        })
        builder.addCase(assessmentFinancialOther.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state = payload
        })
        // // assessments user
        // [assessmentsPersonalUser.pending]: (state) => {
        //     state.isLoading = true
        //     state.isError = false
        // },
        // [assessmentsPersonalUser.fulfilled]: (state, { payload }) => {
        //     console.log('payload', payload)
        //     console.log('state', state)
        //     state.isLoading = false
        //     state = payload
        // },
        // [assessmentsPersonalUser.rejected]: (state, { payload }) => {
        //     console.log('payload', payload)
        //     state.isLoading = false
        //     state.isError = payload
        // },
    },
});

export default assessmentsPersonalSlice.reducer