import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    category: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setSubmitted(true);
      setFormData({
        name: "",
        email: "",
        category: "",
        message: "",
      });
    }, 1000);
  };

  return (
    <Container className="pt-5 pb-5">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center mb-4">Leave Feedback</h2>
          {submitted && (
            <Alert color="success">Thank you for your feedback!</Alert>
          )}
          {!submitted && (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name" className="text-white">Your Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="email" className="text-white">Your Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="category" className="text-white">Feedback Category</Label>
                <Input
                  type="select"
                  name="category"
                  id="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Category</option>
                  <option value="suggestion">Suggestion</option>
                  <option value="comment">Comment</option>
                  <option value="complaint">Complaint</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="message" className="text-white">Your Feedback</Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <Button color="primary">Submit Feedback</Button>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FeedbackForm;
