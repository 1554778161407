export const validEmail = (value) => {
    let errors;
    const validEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

    if (!validEmail.test(value)) {
        return "Please enter a valid email";
    }

    return errors;
};

export const validatePwd = (password) => {
    let errors;
    const validPassword =
        /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{7,7}/;
    if (validPassword.test(password) === false) {
        return "Password must contain Uppercase, Lowercase, Number and special symbol(@/$/&)";
    } else if (password.length < 8) {
        return "Password must have 8 characters";
    }
    return errors;
};


export const validatePhone = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
        return "Phone number must contain 10 digits";
    }
    return;
};
