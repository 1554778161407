import React from "react";
import MainCrosel1 from "../compomemts/HomeCarosel/MainCrosel1";
import About from "../compomemts/About";
import Cta from "../compomemts/Cta";
import Why from "../compomemts/Why";
// import How from '../compomemts/How'
import Relocated from "../compomemts/Relocated";
// import { Testimonials } from '../compomemts/Testimonials'
import Faq from "../compomemts/Faq";
// import Blog from '../compomemts/Blog'
import Application from "../compomemts/Application";
import Steps from "../compomemts/Steps";
import Pricing from "../compomemts/Pricing";
import TopBar from "../compomemts/layout/Navigation/TopBar";
import Navigation from "../compomemts/layout/Navigation/Navigation";
import Footer from "../compomemts/layout/Footer";
import ScrollToTop from "../compomemts/ScrollToTop";
// import Pricing from '../compomemts/Pricing'

function Home() {
  return (
    <>
      <MainCrosel1 />
      <About />
      <Cta />
      <Why />
      {/* <How /> */}
      <Application />
      <Steps />
      <Relocated />
      {/* <Testimonials /> */}
      {/* <Pricing /> */}
      {/* <Pricing /> */}
      <Faq />
      {/* <Blog /> */}
    </>
  );
}

export default Home;
