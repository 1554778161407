import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../Images/logo.svg";
import { useForm } from "react-hook-form";
import InputText from "../../shared/components/form/InputText";
import Select from "../../shared/components/form/Select";
import { validEmail, validatePwd } from "../../shared/utils/validation";
import { roleOption } from "../../user/config/array-config";
import { useDispatch } from "react-redux";
import { createUSer } from "../../user/redux/slice/auth-slice";
import { ThreeDots } from "react-loader-spinner";


export default function Login() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onsubmit = async (value) => {
    setLoading(true);
    await dispatch(createUSer(value));
    setLoading(false);
    navigate("/login");
  };
  return (
    <>
      <Helmet>
        <title>Sign up - Videsh Apna</title>
      </Helmet>
      <div className="relative isolate flex min-h-full container flex-1 flex-col justify-center px-6 py-12 lg:px-8 h-100vh">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <div className="box-log sm:mx-auto shadow-md col-lg-8 bg-sky-50">
          <div className="">
            <img
              className="mx-auto h-10 w-auto"
              src={Logo}
              alt="Your Company"
            />
            <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mb-4">
              Sign Up to your account
            </h2>
          </div>
          <div className="row ">
            <div className="col-lg-6 col-md-12 mb-4">
              <InputText
                type="text"
                name="name"
                label="Name"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Name is required",
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
              <InputText
                type="text"
                name="userName"
                label="User Name"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "User name is required",
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
              <InputText
                type="email"
                name="email"
                label="Email"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Email is required",
                  validate: (value) => validEmail(value),
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
              <InputText
                type="mobile"
                name="phone"
                label="Mobile"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Mobile is required",
                  validate: (value) => {
                    if (!/^[0-9]+$/.test(value)) {
                      return "Only numeric values are allowed";
                    }
                    if (value.length < 10) {
                      return "Mobile number must be at least 10 digits";
                    }
                    return true;
                  },
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
              <InputText
                type="password"
                name="password"
                label="Password"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Password is required",
                  validate: (value) => {
                    return validatePwd(value);
                  },
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12 mb-4">
              <Select
                label="Role"
                name="type"
                options={roleOption}
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Role is required",
                }}
              />
            </div>
            <div className="col-lg-12 col-md-12 text-end mt-2">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-sky-400 px-3 py-3 text-md font-semibold leading-6 text-white hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                onClick={handleSubmit(onsubmit)}
              >{loading ? (
                      <ThreeDots
                        visible={true}
                        height="30"
                        width="30"
                        color="white"
                        radius="5"
                        ariaLabel="three-dots-loading"
                      />
                    ) : (
                      "Submit"
                    )}
             
              </button>
              
            </div>
            <p className="mt-4 text-center text-md text-gray-500">
              Back to{" "}
              <Link
                to="/login"
                className="font-semibold leading-6 text-sky-600 hover:text-sky-500"
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>

        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-45rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </>
  );
}
