import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../baseUrl';


const initialUserData = {
  limit: '120',
  offset: '0',
  filter: null,
  sorting: {
    fieldName: 'created_at',
    direction: 'asc',
  },
};

export const userCreate = createAsyncThunk(
  'users/create',
  async (requestData, { rejectWithValue }) => {   
     try {
      const response = await axios.post(`${baseURL}Users/Create`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const usersList = createAsyncThunk('users/list', async () => {
  try {
    const response = await axios.post(`${baseURL}Users/List`, initialUserData, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const userUpdate = createAsyncThunk(
  'users/update',
  async (requestData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${baseURL}Users/Update`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      // if(response?.data?.succeeded){
      //   dispatch
      // }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userDelete = createAsyncThunk(
  'users/delete',
  async ({ id, dispatch }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${baseURL}Users?id=${id}`, {
        withCredentials: true,
      });
      if (response?.data?.succeeded) {
        dispatch(usersList());
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userGetUserInfo = createAsyncThunk(
  'users/GetUserInfo',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseURL}Users/GetUserInfo`,
        { userId: userId},
        {
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  'users/userId',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseURL}Users/${userId}`, {
        withCredentials: true,
      });
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    singleUser: {},
    loginUser: {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => [
    builder
      .addCase(userCreate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userCreate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(userCreate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(usersList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(usersList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(usersList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(userUpdate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(userUpdate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(userGetUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userGetUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.singleUser = action.payload;
      })
      .addCase(userGetUserInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loginUser = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(userDelete.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userDelete.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // state.users = action.payload;
      })
      .addCase(userDelete.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      }),
  ],
});
export default userSlice.reducer;
