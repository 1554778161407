import { createSlice } from "@reduxjs/toolkit";
import { getConsultantDocumentLists, getConsultantLists } from "../action/consultant-action";


const consultantSlice = createSlice({
    name: 'consultants',
    initialState: {
        isLoading: false,
        consultants: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConsultantLists.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.consultants = payload
        })

        builder.addCase(getConsultantDocumentLists.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.consultants = payload
        })
    },
});

export default consultantSlice.reducer