import { City, Country, State } from "country-state-city";
import { countryCurrencyLists } from "../constants/arrayConfig";

export const countryLists = (countries) => mapItems(countries);

const getCountryCode = (countryName) => getCountry(countryName)?.isoCode;

const getStateCodeAndCountryCode = (state) => {
  const { isoCode: stateCode, countryCode } = getState(state) || {};
  return { stateCode, countryCode };
};

export const stateLists = (country) => {
  const countryCode = getCountryCode(country);
  return mapItems(State.getStatesOfCountry(countryCode));
};

export const cityLists = (state) => {
  const { stateCode, countryCode } = getStateCodeAndCountryCode(state);
  return mapItems(City.getCitiesOfState(countryCode, stateCode));
};

// Helper function to map items
const mapItems = (items) =>
  items.map(({ name, ...rest }) => ({
    label: name,
    value: name,
    ...rest,
  }));

// Helper function to map items
export const countryCurrency = (items) =>
  items.map(({ currency, ...rest }) => ({
    label: currency,
    value: currency,
    ...rest,
  }));

// Helper function to get a country by name
const getCountry = (countryName) =>
  Country.getAllCountries().find(({ name }) => name === countryName);

// Helper function to get a state by name
const getState = (stateName) =>
  State.getAllStates().find(({ name }) => name === stateName);

export const generateDropdownOptions = (minValue, maxValue, generateLabel) => {
  const options = [];

  for (let i = minValue; i <= maxValue; i++) {
    options.push(generateLabel(i));
  }

  return options;
};

// Usage for Year Dropdown
export const yearDropdown = () => {
  const minYear = 1970; // Set your minimum year
  const maxYear = new Date().getFullYear();
  const generateLabel = (year) => ({ value: Number(year), label: year });

  return generateDropdownOptions(minYear, maxYear, generateLabel);
};

// Usage for Month Dropdown
export const monthDropdown = () => {
  const minMonth = 1; // Assuming 1-indexed months
  const maxMonth = 12; // December is 12
  const generateLabel = (monthIndex) => ({
    value: Number(monthIndex),
    label: `${monthIndex}`,
  });

  return generateDropdownOptions(minMonth, maxMonth, generateLabel);
};

//Convert Boolean Value
export const convertBooleanValue = (booleanFields, watch) => {
  return booleanFields?.reduce((acc, field) => {
    acc[field] = watch(field) === "Yes";
    return acc;
  }, {});
};

//Filter Country Currency
export const filterCountryCurrency = ({ country, type }) => {
  const { currency, currencyName } =
    countryCurrencyLists.find(
      (countryList) => countryList?.label === country
    ) || {};

  switch (type) {
    case "currency":
      return currency || null;
    case "currencyName":
      return currencyName || null;
    default:
      return null;
  }
};

//Filter Null Felids
export const filterFields = ({ felids }) => {
  return felids?.visaType === null && felids?.preferredCountry === null;
};
