import React, { useEffect } from "react";
import {
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { userGetUserInfo } from "../../customer/redux/slice/userSlice";
import { useLocation } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Delete from "../../Images/delete.png";
import Edit from "../../Images/edit.png";
import {
  documentsUpdate,
  documentsDelete,
} from "../../customer/redux/action/uploadDoc-actions";

const UserGetDocuments = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  console.log('documents', documents)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  console.log('isUpdate', isUpdate)
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false); // New state for confirmation modal
  const [documentToDelete, setDocumentToDelete] = useState(null); // New state to store the document to delete

  const users = useSelector((state) => state?.users?.singleUser?.data);
  console.log('users', users)

  const params = useLocation();
  let userId = params?.search.replace("?id=", "");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(userGetUserInfo(userId));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, userId]);

  useEffect(() => {
    if (users?.documents) {
      const filteredDocuments = users.documents
        .filter((doc) => !doc.deleted)
        .map((doc) => {
          const documentType = doc.documentType;
          let mimeType = "application/pdf";
          if (documentType === "doc" || documentType === "docx") {
            mimeType =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          }
          return {
            uri: `data:${mimeType};base64,${doc.file}`,
            fileName: doc.name,
            documentType: doc.documentType,
            id: doc.id,
          };
        });
      setDocuments(filteredDocuments);
    }
  }, []);

  useEffect(() => {
    if (isUpdate) {
      dispatch(userGetUserInfo(userId));
    }
  }, [isUpdate]);

  const handleEdit = async (e, id) => {
    e.preventDefault();
    setSelectedDocumentId(id);
    setIsModalOpen(true);
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    setDocumentToDelete(id); // Set the document to delete
    setConfirmationModalOpen(true); // Open the confirmation modal
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDocumentUpdate = async (id, newFile) => {
    // Read the contents of the new file
    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onloadend = async () => {
      const fileData = reader.result.replace(/^data:(.*;base64,)?/, "");

      // Construct the request payload
      const payload = {
        id: id,
        userId: users?.user?.id,
        name: newFile.name,
        description: "",
        file: fileData,
        documentType: newFile.documentType,
        fileType: newFile.type,
      };
      const response = await dispatch(documentsUpdate(payload));
      setIsUpdate(response?.payload?.data?.succeeded);
      toggleModal();
    };
  };

  const handleConfirmDelete = async () => {
    if (documentToDelete) {
      await dispatch(documentsDelete(documentToDelete));
      setDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc.id !== documentToDelete)
      );
      setConfirmationModalOpen(false); // Close the confirmation modal after deletion
    }
  };

  return (
    <>
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col className="order-xl-1 mb-5" xl="10">
            <CardBody>
              <div className="row">
                {documents.map((doc, index) => (
                  <div key={index} className="col-lg-4 upload-ddd mt-4">
                    <div className="text-bold text-lg mb-3 form-labale" style={{ color: "#fff" }}>
                      {doc.documentType}
                    </div>
                    <DocViewer
                      documents={[doc]}
                      pluginRenderers={DocViewerRenderers}
                      style={{ width: "100%", height: "500px" }}
                      theme={{
                        primary: "#5296d8",
                        secondary: "#ffffff",
                        tertiary: "#5296d899",
                        textPrimary: "#ffffff",
                        textSecondary: "#5296d8",
                        textTertiary: "#00000099",
                        disableThemeScrollbar: false,
                      }}
                    />
                    <div className="button-group">
                      <button
                        className="bg-transparent border-0"
                        onClick={(e) => handleDelete(e, doc.id)}
                      >
                        <img src={Delete} style={{ width: 30 }} alt="delete" />
                      </button>
                      <button
                        className="bg-transparent border-0"
                        onClick={(e) => handleEdit(e, doc.id)}
                      >
                        <img src={Edit} style={{ width: 30 }} alt="edit" />
                      </button>
                    </div>
                  </div>
                ))}
                {/* {documents.length === 0 && <div className="text-white">No documents uploaded</div>} */}
              </div>
            </CardBody>
          </Col>
        </Row>
      </Container>
      <DocumentEditModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        documentId={selectedDocumentId}
        onUpdate={handleDocumentUpdate}
      />
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        toggle={() => setConfirmationModalOpen(!confirmationModalOpen)}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

const DocumentEditModal = ({ isOpen, toggle, documentId, onUpdate }) => {
  // State to hold the selected file
  const [selectedFile, setSelectedFile] = useState(null);

  // Function to handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Check if the file type is PDF
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      // Show a message or perform any other action to inform the user
      alert("Please select a PDF file.");
      setSelectedFile(null);
    }
  };
  

  // Function to handle document update
  const handleUpdate = () => {
    if (selectedFile) {
      onUpdate(documentId, selectedFile);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Document</ModalHeader>
      <ModalBody>
        <input type="file" onChange={handleFileChange} />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="text-blue-600 border-1 text-white bg-blue-500 border-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none font-medium  inline-flex         justify-center align-items-center text-center"
          onClick={handleUpdate}
        >
          Update
        </Button>{" "}
        <Button
          color="secondary"
          className="text-gray-600 border-1 text-white bg-gray-500 border-gray-600 hover:bg-gray-700 hover:text-white focus:outline-none font-medium  inline-flex justify-center align-items-center text-center"
          onClick={toggle}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmationModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody>Are you sure you want to delete this document?</ModalBody>
      <ModalFooter>
        <button
          className="text-white btn btn-danger"
          onClick={() => {
            onConfirm();
            toggle();
          }}
        >
          Yes, Delete
        </button>{" "}
        <button onClick={toggle} className="btn btn-secondary">
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default UserGetDocuments;

