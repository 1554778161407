import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EligibilityCanada from './EligibilityCanada';
import EligibilityAustralia from './EligibilityAustralia';
import EligibilityUsa from './EligibilityUsa';
import EligibilityUk from './EligibilityUk';

function Tabbs() {
  return (
    <Tabs
      defaultActiveKey="canada"
      id="uncontrolled-tab-example"
      className="mb-3 new-tabs"
    >
      <Tab eventKey="canada" title="Canada" defaultActiveKey>
        <EligibilityCanada />
      </Tab>
      <Tab eventKey="australia" title="Australia">
        <EligibilityAustralia />
      </Tab>
      <Tab eventKey="usa" title="Usa">
        <EligibilityUsa />
      </Tab>
       <Tab eventKey="uk" title="UK">
        <EligibilityUk />
      </Tab>
    </Tabs>
  );
}

export default Tabbs;