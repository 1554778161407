import { createSlice } from '@reduxjs/toolkit';
import { fetchCountryEligibility } from '../action/chartActions';

const initialState = {
    countryEligibility: [],
    status: 'idle',
    error: null,
  };
  
  const chartSlice = createSlice({
    name: 'chart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCountryEligibility.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchCountryEligibility.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.countryEligibility = action.payload;
        })
        .addCase(fetchCountryEligibility.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        });
    },
  });

export default chartSlice.reducer;
