import { createSlice } from "@reduxjs/toolkit";
import { createContactUs } from "../action/contactUs-actions";

const contactUsSlice = createSlice({
  name: "contactUsSlice",
  initialState: {
    isLoading: false,
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createContactUs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
  },
});

export default contactUsSlice.reducer;
