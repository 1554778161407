import ScrollToTop from "../ScrollToTop";
import Footer from "./Footer";
import Navigation from "./Navigation/Navigation";
import TopBar from "./Navigation/TopBar";

const CustomerLayout = ({ children, showHeaderAndFooter }) => {
  return (
    <>
      {showHeaderAndFooter && (
        <>
          <TopBar />
          <Navigation />
        </>
      )}
      <main>{children}</main>
      {showHeaderAndFooter && (
        <>
          <Footer />
          <ScrollToTop />
        </>
      )}
    </>
  );
};

export default CustomerLayout;
