import { createAsyncThunk } from '@reduxjs/toolkit';
import apiConfig from '../../config/api-config';

const initialUserData = {
    limit: '20',
    offset: '0',
    filter: null,
    sorting: {
        fieldName: 'created_at',
        direction: 'asc',
    },
};

export const getConnectedUser = createAsyncThunk(
    "ConnectedUser",
    async (userID) => {
        try {
            const response = await apiConfig.get(`ConsultantConnectedUserList/GetByUser/${userID}`, initialUserData);
            if (response?.data?.succeeded) {
                return response.data.data;
            }
            throw new Error('Failed to fetch connected users');
        } catch (error) {
            console.error('Error fetching connected users:', error);
            throw error; // Re-throw the error to let the caller handle it
        }
    }
);
