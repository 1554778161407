import React, { useRef } from "react";
import { useState } from "react";
import "react-step-progress/dist/index.css";
import { Link, useLocation } from "react-router-dom";
import ss8 from "../../Images/ss8.gif";
import Iim1 from "../../Images/meeting-point.png";
import Iim2 from "../../Images/operator.png";
import Iim3 from "../../Images/email.png";
import Iim4 from "../../Images/hourglass.png";
import { useNavigate } from "react-router-dom";
import { businessCandidate, countryCurrencyLists, skilledWorker, startUpVisa, studyVisa, visaTypes } from "../../shared/constants/arrayConfig";
import { useForm } from "react-hook-form";
import Select from "../../shared/components/form/Select";
import PageTitle from "../../shared/components/seo/PageTitle";
import { useEffect } from "react";
import { assessmentFormStepsArrayConfig } from "../../shared/config/assessmentFormStepsArrayConfig";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function CheckEligibility() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    register,
    watch,
  } = useForm({
    mode: "onChange"
  });
  const { userDetails } = useSelector(state => state || {});
  const [activeItem, setActiveItem] = useState({ currentStep: 0 });
  const [stepState, setStepState] = useState([]);
  const [validated, setValidated] = useState(false);
  const [tempState, setTempState] = useState([]);
  const params = useLocation();
  const navigate = useNavigate();
  const preferredCountryRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    if (validated) {
      navigate("/scoring");
    }
  };

  useEffect(() => {
    setActiveItem(activeItem);
  }, [activeItem])

  const onHandleNextStep = (step) => {
    window.scrollTo({
      top: preferredCountryRef.current.offsetTop,
      behavior: "smooth"
    })
    if (step?.currentStep >= 0 && step?.currentStep <= 5) {
      setActiveItem({
        ...activeItem,
        currentStep: step?.currentStep
      });
    }
  };

  const assessmentSteps = assessmentFormStepsArrayConfig({ onHandleNextStep, userData: userDetails?.userData, preferredCountry: watch("preferredCountry"), country: watch("preferredCountry"), visaType: watch("visaType"), pathname: params?.pathname.replace('/', '') })

  useEffect(() => {
    if (watch("visaType") === "Skilled Worker") {
      setStepState(assessmentSteps.filter((assessmentStep) => skilledWorker?.includes(assessmentStep?.key)))
    } else if (watch("visaType") === "Start-Up Visa") {
      setStepState(assessmentSteps.filter((assessmentStep) => startUpVisa?.includes(assessmentStep?.key)))
    } else if (watch("visaType") === "Business Candidate") {
      setStepState(assessmentSteps.filter((assessmentStep) => businessCandidate?.includes(assessmentStep?.key)))
    } else if (watch("visaType") === "Study Visa") {
      setStepState(assessmentSteps.filter((assessmentStep) => studyVisa?.includes(assessmentStep?.key)))
    } else {
      setStepState([])
    }
  }, [watch("visaType"), userDetails?.userData])

  useEffect(() => {
    let createSteps = stepState?.map((step, idx) => ({
      key: step?.key,
      completed: step?.isCompleted ? true : idx < activeItem?.currentStep, // past are completed
      selected: idx <= activeItem?.currentStep, // past & present are colored
      highlighted: idx === activeItem?.currentStep - 1, // only present is highlighted
      ...step
    }));
    setTempState([...createSteps]);
  }, [stepState, activeItem]);

  const { t } = useTranslation()
  const { mainhead1,
    heading1,
    heading2,
    p1,
    p2,
    mainhead6,
    p9,
    btn3,
    p10,
    heading4,
    subhead2,
    subhead3,
    subhead4,
    subhead5,
    heading5,
    heading6,
    btn1 } = t("CheckEligibility")

  return (
    <>
      <PageTitle pageTitle="Check Eligibility" />
      <div className="bg-stone-100">
        <div className="relative  px-6 pt-14 pb-14 lg:px-8">
          <div className="mx-auto max-w-[100%] py-20 sm:py-12 lg:py-12">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-sky-500 sm:text-6xl font-p mb-2">
                {mainhead1}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <section className="main-section" ref={preferredCountryRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-2">
              <form>
                <div className="row">
                  <div className="col-12 mb-4">
                    <h3 className="font-p text-3xl font-semibold">
                      {heading1}
                    </h3>
                    <hr className="mt-3"></hr>
                  </div>
                  <div className="col-lg-6 col-12 mb-4">
                    <Select
                      name="preferredCountry"
                      label="Select your preferred destination Country?"
                      options={countryCurrencyLists}
                      register={register}
                    />
                  </div>
                  {watch("preferredCountry") && (
                    <div className="col-lg-6 col-12 mb-4">
                      <Select
                        name="visaType"
                        label="Select the Visa Type"
                        options={visaTypes}
                        register={register}
                      />
                    </div>
                  )}
                </div>
                {watch("visaType") && (
                  <div className="col-lg-12 mt-4">
                    <h2 className="text-sky-500 text-3xl mb-3 font-p font-semibold">{watch("visaType")}</h2>
                    <div className="row">
                      <div className="col-12">
                        <ul className="mt-5 steps-bar">
                          {tempState.map((assessmentStep, index) => {
                            return (
                              <li key={index}>
                                <div className={`${assessmentStep?.completed && assessmentStep?.selected ? "bg-success-600" : assessmentStep?.selected ? "bg-sky-200" : "bg-gray-100"} flex items-center justify-center rounded-full shrink-0 step-box mx-auto mb-1`}>
                                  {assessmentStep?.completed ? <h6 className="text-white fs-2">✔</h6> : <img
                                    className="img-fluid steps-icon" src={assessmentStep?.icon} alt={assessmentStep?.key} />}
                                </div>
                                <h6 className={`${assessmentStep?.completed && assessmentStep?.selected ? "text-success" : assessmentStep?.selected || assessmentStep?.highlighted ? "text-sky-500" : "text-gray-900"} text-base font-semibold leading-7 d-sm-block d-none`}>{assessmentStep?.key}</h6>
                              </li>
                            )
                          })}
                        </ul>
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-12">
                              {tempState.map((assessmentStep, index) => {
                                return (
                                  activeItem?.currentStep === index &&
                                  <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-4" key={index}>
                                    {assessmentStep?.component}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        {tempState?.length !== 0 && tempState.every((step) => step?.completed === true) &&
                          <div class="text-center mt-5">
                            <h2 class="font-semibold text-sky-500 fs-2">{heading2}</h2>
                            <p class="mt-3 text-gray-700">{p1}<br />{p2}</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section bg-[#f0f9ff]">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h2 className="main-h2 text-sky-500 font-p">
                {mainhead6}
              </h2>
              <p className="mt-3 text-lg leading-8 text-zinc-600 font-p">
                {p9}
              </p>
              <Link to="/contact" className="mt-4 inline-block rounded-md border border-transparent bg-sky-400 px-8 py-3 text-center font-medium text-white hover:bg-sky-600">
                {btn3}
              </Link>
            </div>
            <div className="col-lg-6 align-self-center">
              <img className="img-fluid w-100" src={ss8} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                {p10}
              </span>
              <h2 className="main-h2 text-sky-500 font-p">{heading4}</h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}

              <div className="col-12">
                <div className="row mt-5 md:w-[96%]">
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim1} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead2}
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        204, 205, Titanium One, Pakwan Cross Road
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim2} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead3}
                      </h3>
                      <p className="text-lg  text-slate-400 font-p">
                        Phone:<br></br>
                        +91 9023 620 998
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim3} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead4}
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Phone:<br></br>
                        info@videshapna.com
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="contect-box">
                      <img className="img-fluid mb-3" src={Iim4} alt="" />
                      <h3 className="text-2xl mb-2 font-semibold text-sky-600 font-p">
                        {subhead5}
                      </h3>
                      <p className="text-lg text-slate-400 font-p">
                        Mon - Friday:<br></br>
                        09.00 am to 07.00 pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <span className="sub-title text-sky-900 font-p">
                {heading5}
              </span>
              <h2 className="main-h2 text-sky-500 font-p">{heading6}</h2>
              {/* <p className="mt-3 text-lg text-gray-500 md:w-[75%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p> */}
              <div className="col-12 mt-5">
                <form>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Name</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Email</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Phone</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <p className="text-lg font-p mb-2">Subject</p>
                      <input
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <p className="text-lg font-p mb-2">Your Message</p>
                      <textarea
                        rows={7}
                        className="form-control custom-form-control2"
                        placeholder="Enter here..."
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button
                        className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                      >
                        {btn1}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
