import { createSlice } from "@reduxjs/toolkit";
import apiConfig from "../../config/apiConfig";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    profileData: [],
    isError: false,
  },
  reducers: {
    setProfileData: (state, action) => {
      state.authData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setProfileData, setLoading } = profileSlice.actions;

export default profileSlice.reducer;

export const getuserProfile = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    //   const res = await api.funds.fundDeposit(apiData);
    const res = await apiConfig.get(`UserDetails/${payload}`);
    dispatch(setProfileData(res.data));
  } catch (error) {
    dispatch(setLoading(false));
  }
};
