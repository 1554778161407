import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonialLists } from "../redux/action/testimonial-actions";
import { useEffect } from "react";
// import im from "../../Images/ai1.png"

export const Testimonial = () => {
  const dispatch = useDispatch();
  const { testimonials } = useSelector((state) => state.testimonials || []);
  // console.log("testimonials", testimonials);

  useEffect(() => {
    dispatch(getTestimonialLists());
  }, [dispatch]);

  const filteredtestimonials = testimonials?.filter(
    (testimonial) => testimonial?.deleted !== true
  );
  // console.log("filteredtestimonials", filteredtestimonials);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    loop: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div>
      <section className="relative isolate overflow-hidden bg-white mb-5">
        <OwlCarousel
          style={{ background: "#25293c" }}
          {...options}
          className="owl-theme"
        >


{filteredtestimonials?.map((testi, index) => (
          <div>
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
              <figure className="mt-10">
             
                <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  <p>
                    {testi.description}
                  </p>
                </blockquote>
                <figcaption className="mt-10">

                <img
                            className="mx-auto testi-img"
                            src={`data:image/jpeg;base64,${testi?.photo}`}
                            alt={testi?.title}
                          />
                  
                  <div className="mt-4 text-center items-center justify-center space-x-3 text-base">
                    <div className="font-semibold text-gray-900 w-100">
                      {testi.name}
                    </div>
                    
                    <div className="text-gray-600 w-100">{testi.designation}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
         ))}
        </OwlCarousel>
      </section>
    </div>
  );
};
