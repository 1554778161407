import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputText from "../../../../shared/components/form/InputText";
import { assessmentsEducationProfile } from "../../../redux/action/assessments-personal-actions";
import { useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

function EducationalProfileBusinessCandidate({ handleNext, visaType, userData }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const handleEducationProfile = async (formData) => {
    setIsSubmitting(true)
    await dispatch(assessmentsEducationProfile({
      userData: {
        userId: userData?.id,
        otherTraining: "",
        university: "",
        ...formData,
      }, handleNext, visaType
    }))
    setIsSubmitting(false)
  }

  return (
    <form>
      <div className="row">
        <div className="col-12 mb-4">
          <div className="row">
            <div className="col-8">
              <div className="row align-items-center">
                <div className="col-lg-7 col-12">
                  <p className="block mb-2 text-lg font-medium text-gray-900">What is the highest level of education you compted:</p>
                </div>
                <div className="col-lg-5 col-12">
                  <InputText
                    type="text"
                    name="educationName"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "Education name is required",
                    }}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <InputText
            type="text"
            name="otherEducation"
            label="Please give relevant details (name of institution, degree obtained, filed of study, year of study):"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Relevant detail is required",
            }}
            required
          />
        </div>

        <div className="col-12 mb-4">
          <p>
            Post-secondary education is known as tertiary education, or the
            level that follows the successful completion of secondary education,
            which is also referred to as high school. Post-secondary education
            includes universities, colleges, as well as trade and vocational
            schools.
          </p>
        </div>
        <div className="col-12 text-end pb-2">
          <button
            className="inline-block rounded-md border border-transparent bg-sky-400 px-12 py-3 text-center font-medium text-white hover:bg-sky-600"
            type="submit"
            onClick={handleSubmit(handleEducationProfile)}
            disabled={isSubmitting} 
          >
           {isSubmitting ? <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="white"
                radius="5"
                ariaLabel="three-dots-loading"
              /> : "Save & Next"}
          </button>
        </div>
      </div>
    </form>
  );
}

export default EducationalProfileBusinessCandidate;
